import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import uploadPhoto from '@/assets/icon/ico-upload-image.svg'
import { Grid, InputBaseProps } from '@mui/material'
import FileInput from '../FileInputSignUp'
import { UploadFile, UploadedFile } from '@/common/types'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'

export type FileFieldProps = InputBaseProps & {
    label: string;
    profile: string;
    file: UploadFile | undefined;
    url: UploadedFile | undefined;
    profileType?: boolean;
    errors: string | undefined;
    fileType: string;
    handleSelectFile: (_file: React.ChangeEvent<HTMLInputElement>, _fileType: string) => Promise<void>;
};

const FileInputProfile: React.FC<FileFieldProps> = (props) => {
    const { classes } = useStyles()
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })

    const { label, profile, file, url, profileType=false, fileType, errors, handleSelectFile } = props

    return (
        <Grid item xs={isSmallScreen?12:6}>
            <div
                className={
                    profile
                        ? clsx(profileType ? classes.selfiePhoto: classes.documentPhoto, classes.photo)
                        : profileType ? classes.selfiePhoto: classes.documentPhoto
                }
            >
                {profile ? (
                    <img
                        className={profileType ? classes.profile: classes.document}
                        src={ file ? file.preview: url?.url }
                        alt={label}
                    />
                ) : (
                    <img className={classes.uploadPhoto} src={uploadPhoto} />
                )}
            </div>
            <FileInput
                id={fileType}
                label={label}
                inputProps={{ accept: '.png,jpeg,.jpg' }}
                isLoading={file?.loading}
                errors={errors}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectFile(e, fileType)}
            />
        </Grid>
    )
}

const useStyles = makeStyles()((theme) => ({
    documentPhoto: {
        width: '100%',
        height: '100%',
        maxHeight: '160px',
        minHeight: '160px',
        margin: '12px 0',
        background: '#edf0f2',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        borderRadius: '10px',
    },
    selfiePhoto: {
        maxHeight: '160px',
        maxWidth: '160px',
        minWidth: '160px',
        minHeight: '160px',
        margin: '12px 0',
        background: '#edf0f2',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        borderRadius: '100%',
    },
    photo: {
        backgroundColor: theme.palette.common.white,
        border: '1px solid $border',
    },
    uploadPhoto: {
        alignItems: 'center',
        display: 'flex-flow-column',
    },
    profile: {
        maxHeight: '160px',
        maxWidth: '292px',
        borderRadius: '100%',
    },
    document: {
        maxHeight: '160px',
        maxWidth: '292px',
        borderRadius: '10px',
    },
}))

export default FileInputProfile
