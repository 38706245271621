import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'

interface LoaderProps {
    open: boolean;
    size?: number;
    fixed?: boolean;
}

const useStyles = makeStyles()((theme) => ({
    root: {
        zIndex: theme.zIndex.tooltip + 50,
        color: theme.palette.primary.main,
        position: 'absolute',
        height: '100vh',
    },
}))

const Loader = ({ open, size }: LoaderProps) => {
    const { classes: styles } = useStyles()

    return (
        <Backdrop className={clsx(styles.root)} open={open}>
            <CircularProgress size={size} color="inherit" />
        </Backdrop>
    )
}

export default Loader