import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'

export function useQueryUrl() {
    const { search } = useLocation()

    const { query, searchParams } = useMemo(() => {
        const searchParams = new URLSearchParams(search)
        const query = Object.fromEntries(searchParams.entries())

        return { query, searchParams }
    }, [search])

    return { query, searchParams }
}
