import { useCallback } from 'react'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
//import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '@mui/material/Checkbox'
import Chip from '@mui/material/Chip'

import Typography from '../../Typography'
//import IconSearch from '@/components/svg/Icons/IconSearch'

import useAutocomplete from '@/hooks/useAutocomplete'
import { AutocompleteProps } from './types'
import { ItemValue } from '@/common/types'

const MultipleCustomAutocomplete = ({
    options = [],
    label = '',
    error,
    labelKey,
    apiEndpoint = '',
    placeholder,
    fullWidth,
    disableCloseOnSelect = true,
    value,
    className,
    customLoading,
    onChange,
}: AutocompleteProps) => {
    const { classes } = useStyles()

    const { items, loading, handleInputChange } = useAutocomplete({
        labelKey,
        apiEndpoint,
    })

    const handleChange = useCallback((_event: React.SyntheticEvent<Element, Event>, value: ItemValue[]) => {
        try {
            if (typeof onChange === 'function' && !loading) {
                return onChange(value)
            }
        } catch (error) {
            return
        }
    }, [labelKey, onChange])

    return (
        <>
            {label && (
                <Typography noWrap={true} weight="500" color="placeholder" gutter="label" variant="body2">
                    {label}
                </Typography>
            )}
            <Autocomplete
                value={value}
                id={`autocomplete-${labelKey}`}
                fullWidth={fullWidth}
                options={options?.length ? options : items}
                multiple
                disableCloseOnSelect={disableCloseOnSelect}
                classes={{
                    option: classes.option,
                }}
                loading={customLoading || loading}
                color='secondary'
                getOptionLabel={(option) => option.name}
                renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                        <Chip
                            label={option.name}
                            {...getTagProps({ index })}
                            color='secondary'
                            key={option.id}
                        />
                    ))
                }
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                        <Checkbox
                            color='secondary'
                            checked={selected}
                        />
                        {option.name}
                    </li>
                )}
                renderInput={
                    (params) =>
                        <TextField
                            {...params}
                            error={!!error}
                            helperText={error}
                            placeholder={placeholder}
                            className={clsx(classes.root, className)}
                            color='secondary'
                        />
                }
                onChange={handleChange}
                onInputChange={handleInputChange}
            />
        </>
    )
}

const useStyles = makeStyles()(() => ({
    root: {
        '& .MuiOutlinedInput-root': {
            fontSize: 14,
            '&.Mui-disabled fieldset': {
                background: '#f7f8fa',
            },
        },
    },
    option: {
        fontSize: 14,
        '&[aria-selected="true"]': {
            backgroundColor: 'rgba(0, 108, 240, 0.4) !important',
            color: '#fff !important',
        },
    },
}))

export default MultipleCustomAutocomplete