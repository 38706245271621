import Grid from '@mui/material/Grid'
import Paper from '@/components/generics/Paper'
import Typography from '@/components/common/Typography'
import Box from '@mui/material/Box'
import clsx from 'clsx'
import { Button } from '@/components/common/Buttons'
import { useStyles } from './styles'
import { useBooleanHandlers } from '@/hooks/useBooleanHandlers'
import { useAuth } from '@/hooks/useAuth'
import { useForm } from '@/hooks'
import { getInitialValues, useScheme } from './validations'
import { ProfileValues } from './types'
import Profile from './Components/Profile'
import Vehicle from './Components/Vehicle'
import Schedule from './Components/Schedule'
import { useEffect, useState } from 'react'
import { KEY_UPLOAD_FILES } from '@/constants'
import { API_COURIER, API_SIGNED_FILE_URL } from '@/constants/routes'
import useCrud from '@/hooks/useCrud'
import { UploadCourierFiles } from '@/common/types'
import { FileType } from '@/common/enums'
import { uploadFilesToS3 } from '@/utils'
import FileInputProfile from '@/components/generics/FileInputProfile'
import useToast from '@/hooks/useToast'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'

export const ProfileSetings = () => {
    const { classes } = useStyles()
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })
    const { user, setUser } = useAuth()
    const [availableWeekdays, setAvailableWeekdays] = useBooleanHandlers()
    const [availableSaturday, setAvailableSaturday] = useBooleanHandlers()
    const [loading, setLoading] = useState(false)
    const schema = useScheme()
    const [toastSuccess] = useToast()
    const [apiRequestPicture] = useCrud('POST')
    const [apiRequest] = useCrud('PUT')
    const [files, setFiles] = useState<UploadCourierFiles>({})

    useEffect(() => {
        if (user && user?.workingHours.find(item => item.day === 'thursday')){
            setAvailableSaturday()
        }
        if (user && user?.workingHours.find(item => item.day === 'monday')){
            setAvailableWeekdays()
        }
    }, [user])

    const onSubmit = async (values: ProfileValues) => {
        try {
            setLoading(true)
            await uploadFilesToS3(Object.values(files))

            const { carModel, carYear, make, front, back, leftSide, rightSide, address, apartment, deliveryRadius, ...rest } = values
            const payload = {
                ...(rest.name != user?.name ? { name: rest.name } : {}),
                ...(rest.lastName != user?.lastName ? { lastName: rest.lastName } : {}),
                ...(rest.birthdate != user?.birthDate?.toString() ? { birthdate: rest.birthdate } : {}),
                ...(rest.gender != user?.gender ? { gender: rest.gender } : {}),
                ...(rest.tShirt != user?.tShirtSize ? { tShirt: rest.tShirt } : {}),
                ...(deliveryRadius != user?.deliveryRadius?.value ? { deliveryRadius: deliveryRadius } : {}),
                ...(files.profilePicture ? { profilePicture: rest.profilePicture } : {}),
                ...(files.licensePicture ? { licensePicture: rest.licensePicture } : {}),
                ...(files.insurancePicture ? { insurancePicture: rest.insurancePicture } : {}),
                vehicle: {
                    id: user?.vehicle?.id,
                    ...(make != user?.vehicle?.make ? { make } : {}),
                    ...(carModel != user?.vehicle?.model ? { model: carModel } : {}),
                    ...(parseInt(carYear) != user?.vehicle?.year ? { year: parseInt(carYear) } : {}),
                    ...(files.front ? { frontPicture: front } : {}),
                    ...(files.back ? { backPicture: back } : {}),
                    ...(files.leftSide ? { leftPicture: leftSide } : {}),
                    ...(files.rightSide ? { rightPicture: rightSide } : {}),
                },
                address: {
                    googleId: address.googleId,
                    number: address.number,
                    latitude: address.latitude,
                    longitude: address.longitude,
                    street: address.street,
                    city: address.city,
                    state: address.state,
                    country: address.country,
                    zipcode: address.zipcode,
                    address2: apartment || 'N/A',
                },
            }
            const response = await apiRequest(API_COURIER.UPDATE_COURIER, payload)
            setUser({ ...user, ...response })
            setFiles({})
            toastSuccess('Profile Updated')
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const { getFieldProps, values, errors, setFieldValue, handleSubmit } = useForm(
        schema,
        getInitialValues({
            name: user?.name,
            lastName: user?.lastName,
            email: user?.email,
            phone: user?.phone,
            address: {
                number: user?.address?.number,
                street: user?.address?.street,
                city: user?.address?.city,
                state: user?.address?.state,
                zip: user?.address?.zipcode,
                country: user?.address?.country,
                formattedAddress: user?.address?.fullAddress,
                googleId: user?.address.googleId,
            },
            apartment: user?.address?.address2,
            birthdate: user?.birthDate,
            profilePicture: user?.profilePicture?.fileUri,
            licensePicture: user?.license?.fileUri,
            insurancePicture: user?.insurancePicture?.fileUri,
            gender: user?.gender,
            tShirt: user?.tShirtSize,
            make: user?.vehicle?.make,
            carModel: user?.vehicle?.model,
            carYear: user?.vehicle?.year,
            back: user?.vehicle?.backPicture?.fileUri,
            front: user?.vehicle?.frontPicture?.fileUri,
            leftSide: user?.vehicle?.leftPicture?.fileUri,
            rightSide: user?.vehicle?.rightPicture?.fileUri,
            workingHours: [],
            availableWeekdays: availableWeekdays,
            availableSaturday: availableSaturday,
            deliveryRadius: user?.deliveryRadius?.value,
        }),
        onSubmit,
    )

    const handleSelectFile = async (file: React.ChangeEvent<HTMLInputElement>, fileType: string) => {
        if (!file.target.files?.length) return
        const selectedFile = file.target.files[0]

        const keyFile = KEY_UPLOAD_FILES[fileType]
        setFiles((files) => ({ ...files, [keyFile]: {
            loading: true,
        } }))

        const { uploadUri, uri } = await apiRequestPicture(API_SIGNED_FILE_URL, {
            extension: selectedFile.type.split('/')[1],
            type: fileType,
        })

        if (!uploadUri || !uri) {
            setFiles((files) => ({ ...files, [keyFile]: {
                error: true,
                loading: false,
            } }))
            return
        }

        setFiles((files) => ({ ...files, [keyFile]: {
            error: false,
            uri,
            uploadUri,
            file: selectedFile,
            preview: URL.createObjectURL(selectedFile),
            loading: false,
        } }))

        setFieldValue(keyFile, uri)
    }

    return (
        <div className='page-content'>
            <Paper px={isSmallScreen?2:10} py={1}>
                <Box sx={{ backgroundColor: 'white', marginTop: '15px' }}>
                    <Typography className='text-left' gutter='bottomSpace' variant='h4' >
                        Profile Settings
                    </Typography>
                    <form action="#" onSubmit={handleSubmit} className={clsx('d-flex', 'align-rigth')}>
                        <div className={classes.mainContent}>
                            <div className={isSmallScreen?classes.mobleInputWrapper:classes.inputWrapper}>
                                <FileInputProfile label='Profile *' errors={errors.profilePicture} profile={values.profilePicture} file={files.profilePicture} url={user?.profilePicture} profileType={true} fileType={FileType.COURIER_PROFILE_PICTURE} handleSelectFile={handleSelectFile}/>
                                <Grid item xs={8}>
                                    <Grid item marginBottom={3} marginTop={isSmallScreen?4:0}>
                                        <Profile getFieldProps={getFieldProps} values={values} errors={errors} setFieldValue={setFieldValue}/>
                                    </Grid>
                                    <Grid item marginBottom={5}>
                                        <Schedule values={values} errors={errors} setFieldValue={setFieldValue}/>
                                    </Grid>
                                    <Grid item >
                                        <Vehicle getFieldProps={getFieldProps} errors={errors} values={values} files={files} handleSelectFile={handleSelectFile} user={user}/>
                                    </Grid>
                                    <div className='flex-center'>
                                        <Button
                                            className={clsx('rounded-button', classes.continueButton)}
                                            variant="contained"
                                            pending={loading}
                                            color="secondary"
                                            type="submit"
                                        >
                                            Update Information
                                        </Button>
                                    </div>
                                </Grid>
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
        </div>
    )
}