export const API_DASHBOARD = '/dashboard'
export const API_GET_AGREEMENT_URL = '/get-agreement-url/:agreementType'
export const API_VALIDATE_SIGN_IN = '/internal/validate-sign-in'
export const API_VALIDATE_RESET_PASSWORD = '/internal/validate-reset-password'

export const API_SETTINGS = {
    GET_SETTINGS: '/settings',
    UPDATE_SETTINGS: '/settings',
    GET_TERMS: '/terms',
}

export const API_SIGNED_FILE_URL = '/upload-picture'

export const API_SIGN_UP = {
    SET_PREFERRED_WORKING_HOURS: '/sign-up/preferred-working-hours',
    SET_GENERAL_INFORMATION: '/sign-up/general-information',
    SET_ID_CARD_PICTURE: '/sign-up/id-card-picture',
    SET_LICENSE_INSURANCE_INFORMATION: '/sign-up/license-insurance',
    SET_FW9_AND_TAX_INFORMATION: '/sign-up/fw9-and-tax-information',
    CONTINUE_SKIP_SIGNATURES: '/sign-up/continue-skip-signature',
    SKIP_ID_CARD: '/sign-up/skip-id-card',
    SET_TEAMS: '/sign-up/team-information',
    GET_DEFAUL_PICKUP_TIMES: '/pickup-times',
    SET_SELFIE_PICTURE: '/sign-up/set-selfie-picture',
}

export const API_COURIERS_MANAGEMENT = {
    GET_COURIERS: '/couriers',
}

export const API_TEAMS = {
    GET_TEAMS: '/settings/teams',
    UPDATE_TEAMS: '/settings/teams',
}

export const API_DELIVERIES = {
    GET_DELIVERIES: '/deliveries',
    GET_ORDERS: '/orders',
    FIND_ONE: '/deliveries/:id',
}

export const API_TRANSACTIONS = {
    GET_TRANSACTIONS: '/transactions',
}

export const API_COURIER = {
    CREATE_USER: '/courier/users',
    UPDATE_COURIER: '/me',
    UPDATE_QUIZ: '/quiz/:typeFormId',
    CREATE_AGREEMENTS: '/set-agreements',
    SET_FW9_AND_TAX_INFORMATION: '/set-fw9-and-tax-information',
}

export const API_BANK_ACCOUNT = {
    BANK: '/bank',
    CREATE_WITHDRAW: '/bank/withdraw',
}
