import { useEffect, useState } from 'react'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import AppLayout from './components/layouts/AppLayout'
import InitialLoader from './components/common/Loader/InitialLoader'

import SignIn from './pages/Auth/Login'
import SignUp from './pages/Auth/SignUp'

import { useAuth } from '@/hooks/useAuth'
import { useAppContext } from '@/contexts/AppContext'
import { useHttp } from '@/services/app'

import { API_SETTINGS, ROUTE_DECLINED, ROUTE_ROOT, ROUTE_SIGN_UP } from '@/constants/routes'
import { AppContextType } from '@/common/types/types'
import ResetPassword from './pages/Auth/ResetPassword'
import SetPassword from './pages/Auth/SetPassword'
import Earning from './pages/Dashboard/Earning'
import Payment from './pages/Payment'
import ProfileSetings from './pages/Profile'
import ChangePassword from './pages/Dashboard/ChangePassword'
import DeclinedRegistration from './pages/Auth/SignUp/components/DeclinedRegistration'
import Teams from './pages/Dashboard/Teams'
import Transaction from './pages/Dashboard/Transaction'
import TermsConditions from './pages/Dashboard/TermsConditions'
import Delivery from './pages/Dashboard/Delivery'
import Policy from './pages/Dashboard/Policy'
import { CourierRegistrationSteps, CourierStatus } from './common/enums'
import { getInitialRoute } from './utils'
import { Settings } from './common/types'
import { useSettingsStore } from './store'
import PageError from './pages/Error'
import ProtectedRoute from './components/common/ProtectedRoute'

import * as Sentry from '@sentry/react'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

function MainRouter() {
    const { setSettings } = useSettingsStore(state => state)
    const navigate = useNavigate()
    const application = useAppContext() as AppContextType
    const http = useHttp()
    const { user, isLogged } = useAuth()
    const [pending, setPending] = useState(true)

    useEffect(() => {
        http.get<Settings[]>(API_SETTINGS.GET_SETTINGS, 'json', false).then(data => setSettings(data))

        http.getMe().then(user => {
            if (user) {
                application.setUser(user)
                Sentry.setUser({ email: user.email })
                const registrationStep = user?.registrationStep as CourierRegistrationSteps
                const path = getInitialRoute(registrationStep, user.status as CourierStatus)
                if (!user?.registrationStep || Object.values(CourierRegistrationSteps).includes(registrationStep as CourierRegistrationSteps)) {
                    navigate(path)
                }
            }
        }).finally(() => {
            setTimeout(() => setPending(false), 1000)
        })
    }, [])

    return pending ? (
        <InitialLoader open />
    ) : (
        <SentryRoutes>
            <Route path="/" element={isLogged ? <Navigate to={getInitialRoute(user?.registrationStep as CourierRegistrationSteps, user?.status as CourierStatus)} /> : <SignIn />} />

            <Route path="/login" element={<SignIn />} />
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="/policy" element={<Policy />} />
            <Route path={ROUTE_SIGN_UP.path} element={<SignUp />} />
            <Route path={ROUTE_DECLINED.path} element={<DeclinedRegistration />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/set-password" element={<SetPassword />} />

            <Route path="/" element={isLogged ? <AppLayout /> : <Navigate to={ROUTE_ROOT.path} />}>
                <Route path="dashboard" element={<Earning />} />
                <Route path="earning" element={<Earning />} />
                <Route path="dashboard/terms" element={<TermsConditions />} />
                <Route path="order" element={<Delivery />} />
                <Route path="transaction" element={
                    <ProtectedRoute>
                        <Transaction />
                    </ProtectedRoute>
                }
                />
                <Route path="payment" element={
                    <ProtectedRoute>
                        <Payment />
                    </ProtectedRoute>
                }
                />
                <Route path="profile" element={<ProfileSetings />} />
                <Route path="change-password" element={<ChangePassword />} />
                <Route path="teams" element={<Teams />} />
                <Route path="not-found" element={<PageError />} />
            </Route>
        </SentryRoutes>
    )
}

export default MainRouter