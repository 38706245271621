import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import Box from '@mui/material/Box'
import { formatToTitleCase } from '@/utils'
import Typography from '@/components/common/Typography'

const useStyles = makeStyles()(() => ({
    circle: {
        width: 10,
        height: 10,
        borderRadius: '50%',
    },
}))

const greenColor = '#4caf50'
const redColor = '#f44336'
const blueColor = '#2196f3'
const grayColor = '#9e9e9e'
const orangeColor = '#ff9800'
const yellowColor = '#ffeb3b'
const purpleColor = '#7B61E4'

const hashStatus: {[key: string]: string} = {
    created: blueColor,
    active: greenColor,
    completed: greenColor,
    success: greenColor,
    confirmed: greenColor,
    passed: greenColor,
    approved: greenColor,
    registered: greenColor,
    rejected: redColor,
    failed: redColor,
    canceled: grayColor,
    declined: redColor,
    expired: redColor,
    unpaid: redColor,
    unverified: redColor,
    inactive: orangeColor,
    pending: blueColor,
    in_progress: blueColor,
    unregistered: redColor,
    started: yellowColor,
    on_hold: blueColor,
    verified: greenColor,
    closed: redColor,
    incomplete: grayColor,
    ready: orangeColor,
    en_route: blueColor,
    pending_pickup: purpleColor,
    clear: redColor,
    deleted: redColor,
    sent: greenColor,
    no_orders: grayColor,
    processing: orangeColor,
}

interface Props {
    status: string
    justify?: 'flex-start' | 'center' | 'flex-end' | 'space-between' | 'space-around' | 'space-evenly' | undefined
    size?: 'sm' | 'md' | 'lg'
    label?: string
    className?: string
}

export const StatusIcon = ({ status, className, label, justify = 'flex-start', size = 'sm' }: Props) => {
    // do a lilte circle with the color
    const color = status ? hashStatus[status.toLowerCase()] : grayColor

    const { classes: styles } = useStyles()

    return (
        <Box className={className} display="flex" alignItems="center" justifyContent={justify} gap={1}>
            { size === 'sm' &&
            <div
                className={clsx(styles.circle)}
                style={{ backgroundColor: color }}
            />
            }
            { size === 'md'  &&
            <div
                className={clsx(styles.circle)}
                style={{ width: 30, height: 30, borderRadius: '50%', border: `1px solid ${color}`, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <div
                    className={clsx(styles.circle)}
                    style={{ backgroundColor: color }}
                />
            </div>
            }
            { label 
                ? 
                // direction column
                <Box display='flex' flexDirection='column' alignItems='flex-start'>
                    <p style={{ margin: 0, color: grayColor, fontSize: 14 }}>{label}</p>
                    <p style={{ margin: 0, fontSize: 14 }}>{formatToTitleCase(status.replaceAll('_', ' '))}</p>
                </Box>
                : <Typography variant='body2'>{status ? formatToTitleCase(status.replaceAll('_', ' ')) : 'Unknown'}</Typography>
            }
        </Box>
    )
}