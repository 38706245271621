import { FC } from 'react'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import { Box, Grid } from '@mui/material'
import { Steps } from '@/common/types'
import appStore from '@/assets/img/app-store.png'
import gogglePlayStore from '@/assets/img/google-play-store.png'
import { useStyles } from '../../styles'

const AppStep: FC<{data: Steps}> = ({
    data,
}) => {
    const { classes } = useStyles()

    return (
        <Box sx={{ backgroundColor: 'white' }}>
            <div className={classes.modal}>
                <div className={classes.body}>
                    <div className={clsx('text-center', 'w-100')}>
                        <Typography className={classes.title}>
                            {data.title}
                        </Typography>
                        <Typography className={classes.desc}>
                            {data.desc}
                        </Typography>
                        <Grid container xs={12} spacing={2} justifyContent="center">
                            <Grid item xs={6}>
                                <a role='link' href='https://play.google.com/store/apps/details?id=com.revstar.medzoomer' target='_blank' rel='noregerrer noreferrer'>
                                    <img className={classes.appImg} src={gogglePlayStore} alt="Goggle Play Store" />
                                </a>
                            </Grid>
                            <Grid item xs={6}>
                                <a href='https://apps.apple.com/us/app/medzoomer-courier-app/id1593714498' target='_blank' rel='noregerrer noreferrer'>
                                    <img className={classes.appImg} src={appStore} alt="App Store" />
                                </a>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>

        </Box>
    )
}

export default AppStep
