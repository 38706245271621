import { Navigate } from 'react-router-dom'
import { useAuth } from '@/hooks/useAuth'
import { CourierType } from '@/common/enums'

interface Props {
    children: React.ReactNode;
}

const ProtectedRoute = ({ children }: Props) => {
    const { user } = useAuth()

    if (!user || (user && user.courierType !== CourierType.MZ_1099)) {
        return <Navigate to="/not-found" replace />
    }

    return <>{children}</>
}

export default ProtectedRoute