import { useState } from 'react'
import clsx from 'clsx'

import Typography from '@/components/common/Typography'
import { TextField } from '@/components/common/Inputs'
import { Button } from '@/components/common/Buttons'
import EyePassword from '@/components/generics/EyePassword'
import ErrorAuthMessage from '../../../components/Error'

import { getInitialValues, useScheme } from './validations'
import { useKeyLock, useForm } from '@/hooks'

import styles from '../styles.module.css'

const ChangePasswordForm = ({ pending = false, error = '', onSubmit }: { pending: boolean, error: string, onSubmit: (_values: { password: string }) => Promise<void>}) => {

    const schema = useScheme()
    const { getFieldProps, handleSubmit } = useForm(
        schema,
        getInitialValues(),
        onSubmit,
    )

    const capsLock = useKeyLock('CapsLock')
    const [inputType, setInputType] = useState('password')

    return (
        <div className={styles.mainContent}>
            <div className={clsx('flex-center', 'flex-column', 'w-100', 'height-100')}>
                <Typography variant="h3" className={styles.title} align='center'>Change Password</Typography>
                <Typography variant="h5" className={styles.subtitle} align='center'>Please enter new password</Typography>

                <form action="#" onSubmit={handleSubmit} className={clsx('w-100', 'd-flex', 'flex-column', 'align-center')}>
                    <div className={styles.inputWrapper}>
                        <TextField
                            className={styles.textField}
                            placeholder="Password"
                            type={inputType}
                            textHelper={capsLock ? 'Caps lock is on' : ''}
                            InputProps={{
                                endAdornment: (
                                    <EyePassword type={inputType} onSetIcon={setInputType} />
                                ),
                            }}
                            {...getFieldProps('password')}
                        />
                        { error && <ErrorAuthMessage error={error} className={styles.errorText} /> }
                    </div>
                    <Button
                        disabled={pending}
                        variant="contained"
                        pending={pending}
                        type="submit"
                    >
                        Change Password
                    </Button>
                </form>
            </div>
        </div>
    )
}

export default ChangePasswordForm