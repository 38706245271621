import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
    paper: {
        background: 'white',
        margin: '16px',
        padding: '25px 76px',
        borderRadius: '10px',
    },
    noTransactions: {
        height: '100%',
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex-flow-column',
    },
    title: {
        paddingTop: '20px',
        fontSize: '37px',
        lineHeight: '1.08',
        color: 'gray',
    },
    subtitle: {
        fontSize: '16px',
        paddingTop: '10px',
        color: 'gray',
    },
}))
