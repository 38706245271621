import { useState, useEffect } from 'react'
import { http } from '@/services/app'
import { QueryParams } from '@/common/types'
import useToast from '@/hooks/useToast'
import { convertFiltersToQueryString } from '@/utils'

export default function useFetch<T>(url: string, params: QueryParams = {}) {
    const [data, setData] = useState<T>()
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<Error | null>(null)
    const [fetchTrigger, setFetchTrigger] = useState<string>('')
    const [, toastError] = useToast()

    const fetchNow = () => {
        setFetchTrigger(prevQueryKey => prevQueryKey + '1')
    }

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            setError(null)
            try {
                const { filters, ...rest } = params
                const queryParams = convertFiltersToQueryString({ ...rest, ...filters })
                if (queryParams) url = `${url}?${queryParams}`
                const result = await http.get<T>(url)
                setData(result)
            } catch (e) {
                setError(e)
                toastError(e.data && e.data.message ? e.data.message : e.message)
            } finally {
                setTimeout(() => {
                    setLoading(false)
                }, 250)
            }
        }
        fetchData()
    }, [url, params.page, params.perPage, params.search, params.orderBy, params.sort, params.filters, fetchTrigger])

    return [data, loading, error, setData, fetchNow] as [T, boolean, Error | null, (_data: T) => void, () => void]
}