import { useEffect } from 'react'
import { useStyles } from './styles'
import Typography from '@mui/material/Typography'
import { Box, Grid } from '@mui/material'
import { Settings } from '@/common/types'
import appStore from '@/assets/img/app-store.png'
import gogglePlayStore from '@/assets/img/google-play-store.png'
import clsx from 'clsx'
import { API_SETTINGS } from '@/constants/routes'
import useFetch from '@/hooks/useFetch'
import { useBooleanHandlers } from '@/hooks/useBooleanHandlers'
import { StepType } from '@/common/enums'
import Paper from '@/components/generics/Paper'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'

const AppStep = () => {
    const { classes } = useStyles()
    const [settings] = useFetch<Settings[]>(API_SETTINGS.GET_SETTINGS)
    const [isOpen, setOpen] = useBooleanHandlers()
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })

    useEffect(() => {
        if (settings && settings.find(item => item.key === StepType.SHOW_ONFLEET_VIDEO)?.value!=='1'){
            setOpen()
        } 
    }, [settings])

    return (<>
        {!isOpen ? (
            <></>
        ) :
            <Grid item xs={12} >
                <Paper px={10} py={1} className={ isSmallScreen ? classes.paper: '' }>
                    <Box sx={{ backgroundColor: 'white', marginTop: '15px' }}>
                        <div className={clsx('w-100')}>
                            <Grid container xs={12} spacing={3} flexDirection={isSmallScreen ? 'column': 'row'} alignItems={'center'} gap={2} justifyContent="center">
                                <Grid item xs={isSmallScreen ? 12: 7}>
                                    <Typography className={classes.title}>
                                        Please download the Medzoomer Courier app for your device
                                    </Typography>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12: 2}>
                                    <a role='link' href='https://play.google.com/store/apps/details?id=com.revstar.medzoomer' target='_blank' rel='noregerrer noreferrer'>
                                        <img className={classes.appImg} src={gogglePlayStore} alt="Goggle Play Store" />
                                    </a>
                                </Grid>
                                <Grid item xs={isSmallScreen ? 12: 2}>
                                    <a href='https://apps.apple.com/us/app/medzoomer-courier-app/id1593714498' target='_blank' rel='noregerrer noreferrer'>
                                        <img className={classes.appImg} src={appStore} alt="App Store" />
                                    </a>
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                </Paper>
            </Grid>
        }
    </>
    )
}

export default AppStep
