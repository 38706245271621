import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
    modal: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    body: {
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        maxWidth: '900px',
    },
    title: {
        fontSize: '24px',
        fontWeight: '600',
        color: 'black',
        marginBottom: '4px',
    },
    desc: {
        color: 'gray',
        fontSize: '16px',
        marginBottom: '30px',
    },
    video: {
        height: '360px',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mobileActions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    actionSpace: {
        flex: '1',
    },
    prevBtn: {
        color: 'gray',
        fontSize: '14px',
        textTransform: 'uppercase',
        fontWeight: '600',
        background: 'none',
    },
    nextBtn: {
        margin: '0 auto',
        marginTop: '24px',
        borderRadius: '4px',
        color: 'white',
        padding: '16px 62px',
        fontSize: '16px',
        maxWidth: '240px',
    },
    startBtn: {
        backgroundColor: 'red',
        padding: '16px 100px',
    },
}))