import * as Yup from 'yup'

export const initialValues = {
    password: '',
}

export const getInitialValues = () => {
    return Object.assign(
        {},
        initialValues,
    )
}

export const useScheme = () => {
    return Yup.object().shape({
        password: Yup.string().required('Password is required'),
    })
}