import { KeyValue } from '@/common/types'
import { TransactionStatus } from '@/common/enums'

export const TRANSACTION_STATUS_TYPES: KeyValue[] = [
    { key: TransactionStatus.PROCESSING, value: 'Processing' },
    { key: TransactionStatus.FAILED, value: 'Failed' },
    { key: TransactionStatus.CANCELED, value: 'Canceled' },
    { key: TransactionStatus.SUCCESS, value: 'Success' },
]


export const TRANSACTIONS_FILTER_BY_DATES: KeyValue[] = [
    { key: 'createdAt', value: 'Creation Date' },
    { key: 'updatedAt', value: 'Update Date' },
]