import { CourierStatus } from '@/common/enums'

export const getTexts = (status?: string, checkrStatus?: string) => {
    if (status === CourierStatus.PENDING) {
        return {
            title: 'Your application is pending',
            text: 'We will alert you to',
            text2: 'once we have reviewed your application',
        }
    } else if (status === CourierStatus.DECLINED ) {
        return {
            title: 'Your application is declined',
            text: 'We sent you the details to',
            text2: 'Please correct your profile and apply once again.',
        }
    } else if (status === CourierStatus.INCOMPLETE && !checkrStatus) {
        return {
            title: 'Your application is pending',
            text: 'We will alert you to',
            text2: 'once we have reviewed your application',
        }
    } else if (status === CourierStatus.INCOMPLETE && checkrStatus) {
        return {
            title: 'Your application is pending',
            text: 'We send email to',
            text2: 'Please check your email and verify your identity',
        }
    } else {
        return {
            title: 'Your application is pending',
            text: 'We will alert you to',
            text2: 'once we have reviewed your application',
        }
    }
}
