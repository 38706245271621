import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
    updateButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    updateButton: {
        padding: '15px 40px',
        marginBlock: 20,
    },
}))
