import { useState } from 'react'

import { Button } from '@/components/common/Buttons'

import { http } from '@/services/app'
import { ButtonColors } from '@/common/types'
import { ResponseTypesHttp } from '@/common/enums'

import { downloadFile } from '@/utils'

interface Props {
    endpointExport: string;
    fileName?: string;
    buttonColor?: ButtonColors;
    mimeType: 'text/csv' | 'application/pdf';
}

const ExportFile = ({ endpointExport, fileName, mimeType, buttonColor = 'secondary' }: Props) => {

    const [pending, setPending] = useState(false)
    const [, extension] = mimeType.split('/') 

    const handleExport = async () => {
        setPending(true)
        try {
            if (!endpointExport) return
            const response = await http.get<string>(endpointExport, ResponseTypesHttp.TEXT)
            if (response) {
                downloadFile(response, mimeType, fileName || `data.${extension}`)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setPending(false)
        }
    }

    return (
        <Button
            disabled={!endpointExport || pending}
            pending={pending}
            size='small'
            variant='outlined'
            color={buttonColor}
            onClick={handleExport}
            id='exportFileButton'
        >
            Export
        </Button>
    )
}

export default ExportFile