import { routingNumberRegExp } from '@/utils'
import * as Yup from 'yup'

export const initialValues = {
    routingNumber: '',
    accountNumber: '',
    bankAccountType: '',
    name: '',
}

export const getInitialValues = (item = {}) => {
    return Object.assign(
        initialValues,
        item,
    )
}

export const useScheme = () => {
    return Yup.object().shape({
        name: Yup.string().required('Name is not allowed to be empty'),
        routingNumber: Yup.string()
            .required('Routing Number is not allowed to be empty')
            .min(9, 'Routing Number should be minimum 9 characters')
            .matches(routingNumberRegExp, 'The first two digits must fall within the range 01 through 12, or 21 through 32, and the string must consist of nine digits'),
        accountNumber: Yup.string()
            .required('Account Number is not allowed to be empty')
            .min(4, 'Account Number should be minimum 4 characters')
            .max(17, 'Account Number should be maximum 17 characters'),
        bankAccountType: Yup.string().required('Bank Account Type is not allowed to be empty'),
    })
}