import { TableHeader, TransactionFilters } from '@/common/types'
import * as Yup from 'yup'

export const TABLE_HEADERS: TableHeader = [
    {
        label: 'Date',
        orderBy: 'created_at',
        sortable: true,
    },
    {
        label: 'Transaction Type',
    },
    {
        label: 'Status',
    },
    {
        label: 'Amount',
        align: 'right',
    },
    {
        label: 'Remaining Balance',
        align: 'right',
    },
]


export const initialFilterValues = {
    statuses: [],
    dates: {
        field: '',
        startDate: '',
        endDate: '',
    },
}

export const getInitialFilterValues = (filters: TransactionFilters) => {
    return Object.assign(
        initialFilterValues,
        { ...filters },
    )
}

export const useScheme = () => {
    return Yup.object().shape({
        statuses: Yup.array().optional(),
        dates: Yup.object({
            field: Yup.string().optional(),
            startDate: Yup.date().when('dates.field', {
                is: (field: string) => field && field !== '',
                then: Yup.date().required(),
                otherwise: Yup.date().optional(),
            }),
            endDate: Yup.date().when('dates.field', {
                is: (field: string) => field && field !== '',
                then: Yup.date().required().min(
                    Yup.ref('dates.startDate'),
                    'End date must be greater than start date',
                ),
                otherwise: Yup.date().optional(),
            }),
        }),
    })
}