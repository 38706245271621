import IconButtonMUI from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'

interface Props {
    color?: string
    children: React.ReactNode
    tooltip?: string
    disabled?: boolean
    placementTooltip?: 'top' | 'bottom' | 'left' | 'right'
    loading?: boolean
    onClick?: () => void;
    id?: string
}

const IconButton = ({ id, children, loading = false, placementTooltip = 'top', tooltip, disabled, onClick }: Props) => {

    return (
        <Tooltip arrow title={tooltip} placement={placementTooltip}>
            <span>
                {loading ? <CircularProgress size={24} />
                    :                    <IconButtonMUI color='secondary' id={id} disabled={disabled} onClick={onClick}>
                        {children}
                    </IconButtonMUI>
                }
            </span>
        </Tooltip>
    )
}

export default IconButton