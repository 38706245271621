import SvgIcon from '@/components/common/SvgIcon'

interface PropsSvg {
    width?: number
    height?: number
}

export const IconSettings = ({ width = 18, height = 18 }: PropsSvg) => {
    return (
        <SvgIcon width={width} height={height} viewBox="0 0 18 18">
            <path fill="#FFF" fillRule="nonzero" d="M9 6C7.35 6 6 7.35 6 9s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3zm6.94 3c0 .296-.027.591-.066.874l1.956 1.492a.446.446 0 0 1 .106.578l-1.85 3.112a.474.474 0 0 1-.57.193l-2.3-.9a7.138 7.138 0 0 1-1.559.887l-.344 2.378a.476.476 0 0 1-.462.386H7.149a.478.478 0 0 1-.462-.373l-.344-2.378a6.844 6.844 0 0 1-1.56-.888l-2.3.9a.474.474 0 0 1-.568-.192l-1.85-3.112a.446.446 0 0 1 .105-.578l1.956-1.492A6.678 6.678 0 0 1 2.06 9c0-.296.027-.591.066-.874L.17 6.634a.446.446 0 0 1-.106-.578l1.85-3.112c.12-.193.358-.27.57-.193l2.3.9a7.138 7.138 0 0 1 1.559-.887L6.687.386A.476.476 0 0 1 7.149 0h3.702c.224 0 .423.167.462.373l.344 2.378a6.844 6.844 0 0 1 1.56.888l2.3-.9c.211-.078.45 0 .568.192l1.85 3.112a.446.446 0 0 1-.105.578l-1.956 1.492c.04.296.066.591.066.887z"/>
        </SvgIcon>
    )
}
