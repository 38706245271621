import IconButton from '@mui/material/IconButton'
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined'

import { ButtonColors } from '@/common/types'

interface Props {
    color?: ButtonColors
}

const BackButton = ({ color = 'secondary' }: Props) => {
    return (
        <>
            <IconButton onClick={() => history.back()} size='small' color={color}>
                <ArrowBackOutlinedIcon color={color} />
            </IconButton>
        </>
    )
}

export default BackButton
