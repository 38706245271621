import * as Yup from 'yup'

export const initialValues = {
    birthdate: null,
    gender: '',
    tShirt: '',
    hatQuestion: '',
    hatQuestionItem: '',
    heardFrom: '',
    hasWorkedInOtherServices: null,
    primaryLanguage: '',
    secondaryLanguage: '',
    otherService: '',
    device: '',
}

export const getInitialValues = () => {
    return Object.assign(
        {},
        initialValues,
    )
}

export const useScheme = () => {
    return Yup.object().shape({
        birthdate: Yup.date().max(new Date(), 'The date of birth cannot be in the future')
            .required('Date of birth is required').nullable()
            .test('legal-age', 'You must be of legal age', function (value) {
                const birthDate = new Date(value || 'z')
                const today = new Date()
                const minAge = 18
                const diffYears = today.getFullYear() - birthDate.getFullYear()
                if (diffYears < minAge) {
                    return false
                }
                return true
            }),
        gender: Yup.string().required('Please, select gender'),
        tShirt: Yup.string().required('Please, select size'),
        hatQuestionItem: Yup.string().required('This field is required'),
        heardFrom: Yup.string().required('This field is required'),
        hasWorkedInOtherServices: Yup.boolean().required('Please, select an option').nullable(),
        primaryLanguage: Yup.string().required('This field is required'),
        device: Yup.string().required('Please, select your device'),
    })
}
