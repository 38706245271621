import React, { FC } from 'react';
// import MuiCheckbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import MuiCheckbox, { CheckboxProps } from '@mui/material/Checkbox'

import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
// import { createStyles, withStyles } from '@material-ui/core';

const icon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <rect width="21" height="21" x=".5" y=".5" fill="#FFF" fillRule="evenodd" stroke="#CFCFE1" rx="3" />
  </svg>
);

const checkedIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <g fill="none" fillRule="evenodd">
      <rect width="22" height="22" fill="#006cf0" rx="3" />
      <path stroke="#FFF" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 8l-6.875 6L6 11.273" />
    </g>
  </svg>
);

const CheckboxBase: FC<CheckboxProps & {
  label?: string;
  className?: string;
  labelClassName?: string;
  labelPlacement?: 'bottom' | 'top' | 'end' | 'start' | undefined;
  inputProps?: any;
  indeterminate?: boolean;
  checked: boolean;
  onChange: any;
}> = (props) => {
  const { label, className, labelPlacement = 'end', inputProps, indeterminate, labelClassName, checked, onChange } = props;
  return (
    <FormControlLabel
      className={className}
      control={
        <MuiCheckbox
          checked={checked}
          color="primary"
          icon={icon}
          checkedIcon={checkedIcon}
          indeterminateIcon={icon}
          inputProps={inputProps}
          indeterminate={indeterminate}
          onChange={onChange}
        />
      }
      label={<Typography className={labelClassName}>{label}</Typography>}
      labelPlacement={labelPlacement}
    />
  );
};

// const Checkbox = withStyles(() =>
//   createStyles({
//     root: {
//       padding: '9px'
//     }
//   })
// )(CheckboxBase);

export default CheckboxBase;
