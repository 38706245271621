import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
    modal: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
    },
    body: {
        flex: '1',
        display: 'flex',
        justifyContent: 'center',
        marginTop: '6px',
        alignItems: 'center',
        maxWidth: '900px',
    },
    header: {
        color: '#006cf0',
        fontSize: '16px',
        textAlign: 'center',
        textTransform: 'uppercase',
        marginBottom: '6px',
    },
    checkboxWrapper: {
        margin: '10px 0',
        flex: '1',
        textAlign: 'right',
    },
    title: {
        fontSize: '24px',
        fontWeight: '600',
        color: 'black',
        marginBottom: '4px',
    },
    desc: {
        color: 'gray',
        fontSize: '16px',
        marginBottom: '30px',
    },
    video: {
        height: '100%',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    stepImg: {
        maxHeight: '260px',
        maxWidth: '100%',
    },
    appImg: {
        maxHeight: '200px',
        maxWidth: '200px',
    },
    label: {
        fontSize: '16px',
        display: 'block',
        textAlign: 'left',
        color: 'gray',
    },
    downloadVideo: {
        margin: '10px 10px',
    },
    pdfButton: {
        fontSize: '16px',
        marginTop: '30px',
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    mobileActions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
    },
    actionSpace: {
        flex: '1',
    },
    prevBtn: {
        color: 'gray',
        fontSize: '14px',
        textTransform: 'uppercase',
        fontWeight: '600',
        background: 'none',
    },
    nextBtn: {
        margin: '0 auto',
        marginTop: '24px',
        borderRadius: '4px',
        color: 'white',
        padding: '16px 62px',
        fontSize: '16px',
        maxWidth: '240px',
    },
    startBtn: {
        backgroundColor: 'red',
        padding: '16px 100px',
    },
    closeBtn: {
        position: 'absolute',
        right: '15px',
        top: '15px',
    },
    testBtn: {
        cursor: 'pointer',
        marginTop: '100px',
        backgroundColor: '#FF495D',
        width: '100%',
        height: '170px',
        borderRadius: '20px',
        color: 'white',
        border: 'none',
    },
}))