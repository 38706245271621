import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
    mainContent: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'inherit',
        padding: 20,
    },
    inputWrapper: {
        display: 'flex',
        maxWidth: 700,
        gridGap: 50,
        padding: '35px 0',
        flexWrap: 'nowrap',
    },
    mobleInputWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        flexWrap: 'nowrap',
    },
    textField: {
        maxWidth: '350px',
        '& input': {
            padding: '16.5px 14px',
        },
        backgroundColor: '#fff',
    },
    mobileTextField: {
        maxWidth: '100%',
        '& input': {
            padding: '16.5px 14px',
        },
        backgroundColor: '#fff',
    },
    hoursBlock: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.14)',
        paddingTop: 30,
        paddingBottom: 25,
        minWidth: '100%',
    },
    continueButton: {
        padding: '15px 40px',
        marginBlock: 20,
    },
}))
