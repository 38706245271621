import TextField from '@mui/material/TextField'
import { DesktopTimePicker } from '@mui/x-date-pickers/DesktopTimePicker'
import useStyles from '../TextField/styles'
import clsx from 'clsx'
import Typography from '../../Typography'
import { TimeType } from '@/common/types/types'

interface Props {
    value: TimeType
    disabled?: boolean
    label?: string
    onChange: (_newValue: TimeType) => void
}

const TimePicker = ({ value = null, label = '', disabled = false, onChange }: Props) => {
    const { classes } = useStyles()

    return (
        <>
            {label && (
                <Typography weight="500" color="placeholder" gutter="label" variant="body2">
                    {label}
                </Typography>
            )}
            <DesktopTimePicker
                value={value}
                inputFormat="hh:mm A"
                ampm={true}
                mask="__:__:"
                disabled={disabled}
                onChange={(newValue) => onChange(newValue)}
                renderInput={
                    (params) => <TextField {...params} className={clsx(classes.root)} />
                }
            />
        </>
    )
}

export default TimePicker