import { SelectChangeEvent } from '@mui/material/Select'
import Grid from '@mui/material/Grid'

import { DayPicker, SelectField, TextField } from '@/components/common/Inputs'
import GooglePlacesAutocomplete from '@/components/generics/GooglePlacesAutocomplete'
import { GENDER, T_SHIRTS } from '../types'
import { useStyles } from '../styles'
import { ProfileValues } from '../types'
import { FormikErrors } from 'formik'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'

interface Props {
    getFieldProps: any
    values: ProfileValues
    errors: FormikErrors<ProfileValues>
    setFieldValue: (_field: string, _value: any, _shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<ProfileValues>>
}

const Profile = ({ getFieldProps, values, errors, setFieldValue }: Props) => {
    const { classes } = useStyles()
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })

    return (
        <Grid container spacing={2} rowSpacing={4}>
            <Grid container spacing={2} rowSpacing={4} marginBottom={3} columnSpacing={4}>
                <Grid item xs={isSmallScreen?12:6}>
                    <TextField
                        className={isSmallScreen?classes.mobileTextField:classes.textField}
                        label='First Name *'
                        placeholder='First Name *'
                        {...getFieldProps('name')}
                    />
                </Grid>
                <Grid item xs={isSmallScreen?12:6}>
                    <TextField
                        className={isSmallScreen?classes.mobileTextField:classes.textField}
                        label='Last Name *'
                        placeholder='Last Name *'
                        {...getFieldProps('lastName')}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <DayPicker
                        name='birthdate'
                        variant='outlined'
                        label='Date of Birth'
                        value={values.birthdate}
                        onChange={(e) => setFieldValue('birthdate', e)}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} rowSpacing={4} marginBottom={3} columnSpacing={4}>
                <Grid item xs={isSmallScreen?12:6}>
                    <TextField
                        disabled={true}
                        variant='filled'
                        className={isSmallScreen?classes.mobileTextField:classes.textField}
                        label='Contact Email *'
                        {...getFieldProps('email')}
                    />
                </Grid>
                <Grid item xs={isSmallScreen?12:6}>
                    <TextField
                        disabled={true}
                        variant='filled'
                        className={isSmallScreen?classes.mobileTextField:classes.textField}
                        label='Contact Phone Number *'
                        {...getFieldProps('phone')}
                    />
                </Grid>
                <Grid item xs={12}>
                    <GooglePlacesAutocomplete
                        label='Address *'
                        error={errors.address?.number}
                        value={values.address}
                        inputVariant='outlined'
                        handleSelectAddress={(address) => setFieldValue('address', address)}
                    />
                </Grid>
                <Grid item xs={isSmallScreen?12:6}>
                    <TextField
                        className={isSmallScreen?classes.mobileTextField:classes.textField}
                        label='Apartment, suite, etc.'
                        {...getFieldProps('apartment')}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} rowSpacing={4} columnSpacing={4}>
                <Grid item lg={6} xs={12}>
                    <SelectField
                        value={values.gender}
                        label='Gender *'
                        variant='outlined'
                        error={errors?.gender || null}
                        options={GENDER}
                        onChange={(evt: SelectChangeEvent) => setFieldValue('gender', evt.target.value)}
                    />
                </Grid>
                <Grid item lg={6} xs={12}>
                    <SelectField
                        value={values.tShirt}
                        options={T_SHIRTS}
                        variant='outlined'
                        label='T-shirt size *'
                        error={errors?.tShirt || null}
                        onChange={(evt: SelectChangeEvent) => setFieldValue('tShirt', evt.target.value)}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default Profile
