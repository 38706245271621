import clsx from 'clsx'
import PI, { PhoneInputProps } from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

import Typography from '../../Typography'

import styles from './styles.module.css'

interface Props {
    label?: string;
    value?: string;
    InputProps?: any;
    disableCountryCode?: boolean;
    error?: string | null;
    onChange?: (_e: string) => void
}

const ReactPhoneInput: React.FC<PhoneInputProps> = (PI as any).default || PI

const PhoneInput = ({ label = '', value, error, InputProps, onChange }: Props) => {
    return (
        <>
            {label && (
                <Typography weight="500" color="placeholder" gutter="label" variant="body2">
                    {label}
                </Typography>
            )}
            <ReactPhoneInput
                placeholder={'(000)  000  0000'}
                disableDropdown
                disableSearchIcon
                masks={{ us: '(...)  ...  ....' }}
                country={'us'}
                onlyCountries={['us']}
                countryCodeEditable={false}
                inputClass={clsx(styles.input, error && styles.error)}
                buttonClass={clsx(styles.button, error && styles.error)}
                value={value}
                containerClass={InputProps}
                onChange={onChange}
            />
            { !!error &&
            <Typography noWrap={true} color="error" variant="caption">
                {typeof error === 'string' ? error : label || 'Error' }
            </Typography>
            }
        </>
    )
}

export default PhoneInput
