import { Link } from 'react-router-dom'
import Typography from '@mui/material/Typography'

const Footer = () => {
    const now = new Date()
    return (
        <>
            <Typography variant="body1" sx={{ color: 'white' }}>
                © Copyright {now.getFullYear()} MedZoomer
            </Typography>
            <Link to={'/policy'} style={{ color: 'white' }}>
                Privacy Policy
            </Link>
            <Link to={'/terms'} style={{ color: 'white' }}>
                Terms and conditions
            </Link>
        </>
    )
}

export default Footer
