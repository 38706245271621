import clsx from 'clsx'
import Grid from '@mui/material/Grid'

import Typography from '@/components/common/Typography'
import { Button } from '@/components/common/Buttons'
import FileInput from '@/components/generics/FileInputSignUp'

import useCrud from '@/hooks/useCrud'
import { useForm } from '@/hooks'
import { SignUpComponentsProps } from '../../types'
import { useStyles } from './styles'
import { getInitialValues, useScheme } from './validations'
import { uploadFilesToS3 } from '@/utils'
import { API_SIGNED_FILE_URL, API_SIGN_UP } from '@/constants/routes'
import { FileType } from '@/common/enums'
import { UploadCourierFiles } from '@/common/types'
import { KEY_UPLOAD_FILES } from '@/constants'

import badPicture from '@/assets/img/badPicture.png'
import goodPicture from '@/assets/img/goodPicture.png'

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import CancelIcon from '@mui/icons-material/Cancel'


import { useState } from 'react'
import { Box } from '@mui/material'
import { CardValues } from './types'

const CardStep = (props: SignUpComponentsProps) => {
    const { classes } = useStyles()
    const schema = useScheme()
    const [apiRequest] = useCrud('POST')

    const [files, setFiles] = useState<UploadCourierFiles>({})
    const [loading, setLoading] = useState(false)

    const onSubmit = async (values: CardValues) => {
        try {
            setLoading(true)
            await uploadFilesToS3(Object.values(files))

            const response = await apiRequest(API_SIGN_UP.SET_ID_CARD_PICTURE, { idCardPictureFile: files.idCardPictureFile?.uri })
            if (response) {
                props.nextStep(response.registrationStep)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const { errors, setFieldValue, handleSubmit } = useForm(
        schema,
        getInitialValues(),
        onSubmit,
    )

    const handleSelectFile = async (file: React.ChangeEvent<HTMLInputElement>, fileType: string) => {
        if (!file.target.files?.length) return
        const selectedFile = file.target.files[0]

        const keyFile = KEY_UPLOAD_FILES[fileType]
        setFiles((files) => ({
            ...files, [keyFile]: {
                loading: true,
            },
        }))

        const { uploadUri, uri } = await apiRequest(API_SIGNED_FILE_URL, {
            extension: selectedFile.type.split('/')[1],
            type: fileType,
        })

        if (!uploadUri || !uri) {
            setFiles((files) => ({
                ...files, [keyFile]: {
                    error: true,
                    loading: false,
                },
            }))
            return
        }

        setFiles((files) => ({
            ...files, [keyFile]: {
                error: false,
                uri,
                uploadUri,
                file: selectedFile,
                preview: URL.createObjectURL(selectedFile),
                loading: false,
            },
        }))

        setFieldValue(keyFile, uri)
    }

    return (
        <div className={classes.mainContent}>
            <Typography variant="h4" gutter='label' align='center'>ID Card</Typography>
            <Typography variant='body1' align='center' >
                This photo will be used for your Driver ID Card and shown to pharmacies, please upload your best headshot... dont be afraid to smile!
            </Typography>


            <Box className={classes.exampleImageContainer}>
                <Box className={clsx(classes.exampleImageWrapper, classes.goodExampleBorder)}>
                    <img src={goodPicture} alt="Good example" className={classes.exampleImage} />
                    <CheckCircleOutlineIcon className={`${classes.icon} ${classes.checkIcon}`} />
                </Box>
                <Box className={clsx(classes.exampleImageWrapper, classes.badExampleBorder)}>
                    <img src={badPicture} alt="Bad example" className={classes.exampleImage} />
                    <CancelIcon className={`${classes.icon} ${classes.crossIcon}`} />
                </Box>

            </Box>
            <form action="#" onSubmit={handleSubmit} className={clsx('d-flex', 'flex-column', 'align-center')}>
                <div className={classes.inputWrapper}>
                    <Grid width={'60vh'}>
                        <Grid item xs={12}>
                            <FileInput
                                label="Upload photo"
                                required
                                inputProps={{ accept: '.png,.jpeg,.jpg' }}
                                isTooltip
                                id='idCardPictureFile'
                                isLoading={files.idCardPictureFile?.loading}
                                value={files.idCardPictureFile?.preview}
                                errors={errors.idCardPictureFile}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectFile(e, FileType.COURIER_ID_CARD)}
                            />
                        </Grid>
                    </Grid>
                    <div className='flex-center'>
                        <Button
                            className={clsx('rounded-button', classes.continueButton)}
                            disabled={loading}
                            variant="contained"
                            color="secondary"
                            pending={loading}
                            type="submit"
                            id='continue-id-card-button-step'
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default CardStep
