import * as Yup from 'yup'

export const initialValues = {
    email: '',
}

export const getInitialValues = () => {
    return Object.assign(
        {},
        initialValues,
    )
}

export const useScheme = () => {
    return Yup.object().shape({
        email: Yup.string().email().required('Email is required'),
    })
}