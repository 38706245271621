const palette = {
    common: {
        white: '#FFF',
        black: '#272727',
    },
    primary: { main: '#e21c40', light: '#52cbff', dark: 'rgb(158, 19, 44)' },
    secondary: { main: '#006cf0' },
    success: { main: '#17b471', dark: '#11ca7b' },
    error: { main: '#ff5225' },
    warning: { main: '#faa900', light: '#ffac00' },
    info: { main: '#006cf0' },
    grey: {
        A700: '#536270',
    },
    text: {
        primary: '#000',
        secondary: '#cac9cf',
        disabled: '#6b6a6f',
        hint: '#9a99a1',
        success: '#17b471',
        placeholder: '#b2b8cf',
    },
    background: {
        paper: '#fff',
        paperSecondary: '#f1f3f9',
        default: '#f3f4f7',
        input: '#f1f3f9',
    },
    /*
    action: {
        light: 'rgba(255,255,255,0.8)',
    },
    */
    divider: '#b2b8cf',
}

export default palette
