import { useRef } from 'react'
import MUILink from '@mui/material/Link'
import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'

/*generate interface for props*/
interface RouterLinkProps {
  className?: string;
  children: React.ReactNode;
  href?: string;
  id?: string;
  target?: string;
  to: string;
  handleDrawerClose? :() => void
  underline?: 'none' | 'hover' | 'always';
  color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
}

const useStyles = makeStyles()(() => ({
    root: {
        color: '#006cf0',
    },
}))

export default function RouterLink(props: RouterLinkProps) {
    const ref = useRef<HTMLAnchorElement>(null)

    const { id, to, href, target, underline = 'none', color, handleDrawerClose, ...rest } = props
    const { classes } = useStyles()

    return (
        <MUILink
            ref={ref}
            className={clsx(classes.root, props.className)}
            color={color}
            underline={underline}
            component={Link}
            to={to}
            id={id}
            onClick={handleDrawerClose}
            href={href}
            {...rest}
            target={href ? '_blank' : target}
        >
            {props.children}
        </MUILink>
    )
}
