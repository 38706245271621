import { useState, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import {
    AUTH_NEW_PASSWORD_REQUIRED, USER_NOT_CONFIRMED,
} from '@/constants'

import AuthLayout from '@/components/layouts/AuthLayout'
import SignInForm from './components/SignInForm'
import ChangePasswordForm from './components/ChangePasswordForm'

import { signIn, completeNewPasswordChallenge, validateSignIn } from '@/services/amplify'
import { useHttp } from '@/services/app'
import { useAppContext } from '@/contexts/AppContext'

import { SignInValues } from './types'
import { ROUTE_SIGN_UP } from '@/constants/routes'
import { AppContextType } from '@/common/types'
import { useSignUpStore } from '@/store'
import { getInitialRoute } from '@/utils'
import { CourierStatus } from '@/common/enums'

import * as Sentry from '@sentry/react'

const SignIn = () => {
    const navigate = useNavigate()
    const http = useHttp()
    const application = useAppContext() as AppContextType

    const [error, setError] = useState<string>('')
    const [challenge, setChallenge] = useState<string>('')
    const [pending, setPending] = useState(false)
    const [, setAuthUser] = useState(null)
    const { setEmail, setTmp } = useSignUpStore((state) => state)

    const onSubmit = useCallback(async ({ email, password }: SignInValues) => {
        try {
            setError('')
            setPending(true)
            const { success, user, type } = await signIn(email.toLowerCase(), password)
            if (!success) {
                if (type === AUTH_NEW_PASSWORD_REQUIRED) {
                    setAuthUser(user)
                    return setChallenge(type)
                } else if (type === USER_NOT_CONFIRMED) {
                    setEmail(email)
                    setTmp(password)
                    return navigate(ROUTE_SIGN_UP.path + '?step=Email')
                }
                setError(type || '')
            }
            return await loginToApplication()
        } catch (e) {
            try {
                const validate = await validateSignIn(email, password)
                if (validate) {
                    if (validate.success) {
                        await signIn(email.toLowerCase(), password)
                        return await loginToApplication()
                    }
                    setError(validate.code)
                    return
                }
            } catch {
                setError(e.code || e.message || '')
                return
            }
            setError(e.code || e.message || '')
        } finally {
            setPending(false)
        }
    }, [])

    const loginToApplication = async () => {
        const user = await http.getMe()
        if (user) {
            application.setUser(user)
            Sentry.setUser({ email: user.email })
            navigate(getInitialRoute(user?.registrationStep, user.status as CourierStatus))
        }
    }

    const onNewPasswordSubmit = useCallback(async ({ password }: { password: string }) => {
        setError('')
        setPending(true)
        return setAuthUser((authUser) => {
            if (authUser) {
                completeNewPasswordChallenge(authUser, password)
                    .then(() => loginToApplication)
                    .catch(e => setError(e.code || e.message || ''))
                    .finally(() => setPending(false))
            }
            return authUser
        })
    }, [])

    return (
        <AuthLayout>
            {challenge === AUTH_NEW_PASSWORD_REQUIRED ? (
                <ChangePasswordForm
                    pending={pending}
                    error={error}
                    onSubmit={onNewPasswordSubmit}
                />
            ) : (
                <SignInForm
                    pending={pending}
                    error={error}
                    onSubmit={onSubmit}
                />
            )}
        </AuthLayout>
    )
}

export default SignIn
