import { KeyValue, TableHeader, FilterValues } from '@/common/types'
import * as Yup from 'yup'

export const ORDER_TABLE_HEADERS: TableHeader = [
    { label: 'Date', align: 'center' },
    { label: 'Time', align: 'center' },
    { label: 'Order Id', align: 'center' },
    { label: 'Status', align: 'center' },
    { label: 'Distance', align: 'center' },
    { label: 'Earned', align: 'center' },
]

export const TRANSACTION_TABLE_HEADERS: TableHeader = [
    { label: 'Date', align: 'center' },
    { label: 'Transaction Type', align: 'center' },
    { label: 'Status', align: 'center' },
    { label: 'Amount', align: 'center' },
    { label: 'Remaining Balance', align: 'center' },
]

export const DATERANGE_FILTERS: KeyValue[] = [
    {
        key: 'today',
        value: 'Today',
    },
    {
        key: 'last_seven_days',
        value: 'Last 7 days',
    },
    {
        key: 'last_thirty_days',
        value: 'Last 30 days',
    },
    {
        key: 'all',
        value: 'All',
    },
]

export const initialFilterValues = {
    dates: {
        field: 'createdAt',
        startDate: '',
        endDate: '',
    },
}

export const getInitialFilterValues = (filters: FiltersType) => {
    return Object.assign(
        initialFilterValues,
        { ...filters },
    )
}

export const useScheme = () => {
    return Yup.object().shape({
        dates: Yup.object({
            field: Yup.string().optional(),
            startDate: Yup.date().when('dates.field', {
                is: (field: string) => field && field !== '',
                then: Yup.date().required(),
                otherwise: Yup.date().optional(),
            }),
            endDate: Yup.date().when('dates.field', {
                is: (field: string) => field && field !== '',
                then: Yup.date().required().min(
                    Yup.ref('dates.startDate'),
                    'End date must be greater than start date',
                ),
                otherwise: Yup.date().optional(),
            }),
        }),
    })
}

export type FiltersType = Pick<FilterValues, 'startDate' | 'endDate' >