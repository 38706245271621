import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
    root: {
        textTransform: 'none',
        fontSize: 15,
    },
    contained: {
        boxShadow: 'none',
        '&:hover': {
            boxShadow: 'none',
        },
        '&$error': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.dark,
            },
        },
        '&$success': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.success.main,
            '&:hover': {
                backgroundColor: theme.palette.success.dark,
            },
        },
        '&$warning': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.warning.main,
            '&:hover': {
                backgroundColor: theme.palette.warning.dark,
            },
        },
    },
    outlined: {
        '&$error': {
            color: theme.palette.error.main,
            borderColor: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.main,
            },
        },
        '&$success': {
            color: theme.palette.success.main,
            borderColor: theme.palette.success.main,
            '&:hover': {
                backgroundColor: theme.palette.success.main,
            },
        },
        '&$warning': {
            color: theme.palette.warning.main,
            borderColor: theme.palette.warning.main,
            '&:hover': {
                backgroundColor: theme.palette.warning.main,
            },
        },
    },
    text: {
        '&$error': {
            color: theme.palette.error.main,
            '&:hover': {
                backgroundColor: theme.palette.error.main,
            },
        },
        '&$success': {
            color: theme.palette.success.main,
            '&:hover': {
                backgroundColor: theme.palette.success.main,
            },
        },
        '&$warning': {
            color: theme.palette.warning.main,
            '&:hover': {
                backgroundColor: theme.palette.warning.main,
            },
        },
    },
    loader: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        visibility: 'visible',
    },
    isIconRoot: {
        padding: 15,
        width: 40,
        '&$sizeSmall': {
            padding: 2,
            borderRadius: 2,
            width: 28,
        },
        '&$sizeLarge': {
            padding: 22,
            borderRadius: 8,
            width: 54,
        },
    },
    isIconSmall: {
        minWidth: 20,
        padding: '5px 10px !important',
    },
    disabledStyles: {
        color: theme.palette.text.disabled,
        backgroundColor: theme.palette.action.disabledBackground,
    },
    pendingStyles: {
        '& > $label': {
            visibility: 'hidden',
        },
    },
    view: {
        overflow: 'hidden',
        cursor: 'default',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'currentColor',
            opacity: 0.1,
        },
    },
    label: {},
    sizeSmall: {},
    sizeLarge: {},
    error: {},
    success: {},
    warning: {},
    primary: {},

    // Gutter
    'label-gt': { marginBottom: theme.spacing(1) },
    'title-gt': { marginBottom: theme.spacing(1.3) },
    'bottom2-gt': { marginBottom: theme.spacing(2.5) },
    'bottomSpace-gt': { marginBottom: theme.spacing(3) },
    'topSpace-gt': { marginTop: theme.spacing(3) },
    'verticalSpace-gt': { marginTop: theme.spacing(1.25), marginBottom: theme.spacing(1.25) },
    'verticalSpaceBig-gt': { marginTop: theme.spacing(3), marginBottom: theme.spacing(3) },
    'bottomSpaceBig-gt': { marginBottom: theme.spacing(5) },
    'horizontalSpace-gt': { marginLeft: theme.spacing(1.3), marginRight: theme.spacing(1.3) },
}))

export default useStyles
