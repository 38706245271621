import { TextField } from '@/components/common/Inputs'
import Modal from '@/components/common/Modal'
import { Button } from '@/components/common/Buttons'
import Grid from '@mui/material/Grid'
import { getInitialValues, useScheme } from './validations'
import { RequestState, useForm } from '@/hooks'
import { WithdrawValues } from './types'
import Typography from '@/components/common/Typography'
import clsx from 'clsx'
import { useStyles } from '../../styles'

interface Props {
    open: boolean
    apiState: RequestState
    onClose: () => void
    onSubmit: (_values: WithdrawValues) => Promise<void>
}

const CreateWithdrawDialog = ({ open, apiState, onClose, onSubmit }: Props) => {
    const schema = useScheme()
    const { classes } = useStyles()

    const { getFieldProps, handleSubmit } = useForm(
        schema,
        getInitialValues(),
        onSubmit,
    )
    
    return (
        <Modal
            open={open}
            onClose={onClose}
        >

            <div className={clsx('text-center', 'w-100')}>
                <Typography gutter='bottomSpace' variant='h5' >
                    Withdraw Funds
                </Typography>
            </div>
            <div className={clsx('flex-center', 'flex-column', 'w-100', 'height-100')}>
                <form action="#" onSubmit={handleSubmit}>
                    <Grid container spacing={1} rowSpacing={1} justifyContent={'center'}>
                        <Grid item lg={6} md={6} xs={12}>
                            <TextField
                                className={classes.textField}
                                label='Withdrawal Amount'
                                {...getFieldProps('amount')}
                            />
                        </Grid>

                        {/* <Grid item >
                            <Typography variant='caption' align='center'>
                                System charges additional 1% fee for transaction
                            </Typography>
                        </Grid> */}
                        <Grid item xs={12} className='flex-center' marginTop={4}>
                            <Button
                                className={clsx('rounded-button')}
                                color='primary'
                                type='submit'
                                size='large'
                                pending={apiState.loading}
                                disabled={apiState.loading}
                            >
                                Withdrawal
                            </Button>
                        </Grid>
                        <Grid item xs={12} className='flex-center'>
                            <Button
                                color='info'
                                variant='text'
                                size='small'
                                onClick={onClose}
                                disabled={apiState.loading}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Modal>
    )
}

export default CreateWithdrawDialog