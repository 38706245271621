import { FC } from 'react'
import { useStyles } from '../../styles'
import download from '@/assets/icon/ico-download.svg'
import Typography from '@mui/material/Typography'
import { Box, Button } from '@mui/material'
import { Steps } from '@/common/types'
import clsx from 'clsx'

const GuideStep: FC<{data: Steps}> = ({
    data,
}) => {
    const { classes } = useStyles()

    return (
        <Box sx={{ backgroundColor: 'white' }}>
            <div className={classes.modal}>
                <div className={classes.body}>
                    <div className={clsx('text-center', 'w-100')}>
                        <Typography className={classes.title}>
                            {data.title}
                        </Typography>
                        <Typography className={classes.desc}>
                            {data.desc}
                        </Typography>
                        <img className={classes.stepImg} src={data.img} alt="instruction" />
                        <Button
                            className={classes.pdfButton}
                            color="secondary"
                            variant="text"
                            href="/pdf/Driver-Quick-Start.pdf"
                            download
                        >
                            <img className={classes.downloadVideo} src={download} alt="Guide " />Start Guide.pdf
                        </Button>
                    </div>
                </div>
            </div>

        </Box>
    )
}

export default GuideStep
