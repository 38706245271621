import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
    mainContent: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        gap: 20,
    },
    inputWrapper: {
        maxWidth: 450,
        marginTop: theme.spacing(2),
    },
    backArrowContainer: {
        marginLeft: 0,
    },
    continueButton: {
        padding: '15px 40px',
        marginBlock: 20,
    },
    errorText: {
        marginTop: '25px !important',
    },
}))
