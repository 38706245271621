import React, { Fragment } from 'react'
import clsx from 'clsx'
import Radio from '@mui/material/Radio'
import MUITRadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import Typography from '../../Typography'
import useStyles from './styles'
import { testProp } from '@/constants/testIds'

export type Items = Array<{
  value: number | string | boolean;
  label: number | string;
}>;

const yesNoRadioItemsArr = [
    {
        label: 'Yes',
        value: true,
    },
    {
        label: 'No',
        value: false,
    },
]


interface Props {
  items: Items;
  id?: string;
  name?: string;
  value?: any;
  error?: string | null;
  color?: 'primary' | 'secondary';
  label?: string;
  flexWrapper?: string;
  onChange?: (_e: React.ChangeEvent<HTMLInputElement>) => void;
}

const RadioGroup = ({ id='', name='customized-radios', items, value, label, error, color = 'secondary', onChange, flexWrapper = 'row' }: Props) => {
    const { classes: styles } = useStyles()
    const elements = items.length > 0 ? items : yesNoRadioItemsArr
    return (
        <>
            {label && (
                <Typography weight="500" color="placeholder" gutter="verticalSpace" variant="body1">
                    {label}
                </Typography>
            )}
            <FormControl component="fieldset">
                <MUITRadioGroup
                    value={value}
                    aria-label="radioGroupCustom"
                    name={name}
                    className={clsx(flexWrapper === 'column' ? styles.wrapperFlexColumn : styles.wrapper)}
                    onChange={onChange}
                >
                    {elements.map((item: any) => (
                        <Fragment key={item.value}>
                            <FormControlLabel {...testProp(id+item.value)} value={item.value} control={<Radio color={color} />} label={item.label} />
                        </Fragment>
                    ))}
                </MUITRadioGroup>
                { !!error &&
                <Typography noWrap={true} color="error" variant="caption">
                    {typeof error === 'string' ? error : label || 'Error' }
                </Typography>
                }
            </FormControl>
        </>
    )
}

export default RadioGroup
