import { useState, useEffect } from 'react'
import { GoogleMap, Polygon, useJsApiLoader } from '@react-google-maps/api'

const containerStyle = {
    width: '100%',
    height: '450px',
}

const center = { lat: 27.503230262977006, lng: -80.80129333483394 }

const polygonOptions = {
    fillColor: 'lightblue',
    fillOpacity: 1,
    strokeColor: 'red',
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
}

interface Props {
    geoJson: {
        type: string,
        features: any
    }
}

type PolygonValues = {
    lat: number,
    lng: number
}

const Map = (props: Props) => {
    const { geoJson } = props

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: import.meta.env.VITE_GOOGLE_KEY,
    })

    const [polygons, setPolygons] = useState<Array<PolygonValues[]>>([])

    useEffect(() => {
        try {
            const mapPolygons: Array<PolygonValues[]> = []
            if (geoJson.features.length > 0) {
                for (const i in geoJson.features) {
                    const feature = geoJson.features[i]
                    if (feature.geometry && feature.geometry.type === 'Polygon') {
                        let coordinateNewPolygon: Array<PolygonValues> = []
                        for (const g in feature.geometry.coordinates[0]) {
                            coordinateNewPolygon = [...coordinateNewPolygon, { lat: feature.geometry.coordinates[0][g][1], lng: feature.geometry.coordinates[0][g][0] }]
                        }
                        mapPolygons.push(coordinateNewPolygon)
                    }
                }
                setPolygons(mapPolygons)
            }
        } catch (e) {
            console.log(e)
        }
    }, [geoJson])

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={7}
        >
            <Polygon
                paths={polygons}
                options={polygonOptions}
            />
        </GoogleMap>
    ) : <></>
}

export default Map
