import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import Grid from '@mui/material/Grid'
import Paper from '@/components/generics/Paper'
import Typography from '@/components/common/Typography'
import { IconButton } from '@/components/common/Buttons'

interface PaginationProps {
    page: number
    perPage?: number
    count: number
    pending?: boolean
    onNextPage: () => void
    onPrevPage: () => void
}

const Pagination = ({ page, count, perPage = 25, pending, onNextPage, onPrevPage }: PaginationProps) => {
    
    const countCurrentRecords = count <= perPage ? count : (page + 1) * perPage
    const totalPages = Math.ceil((count || 0) / perPage)
    
    return (
        <Paper px={1} py={1}>
            <Grid container justifyContent="space-between" alignItems="center" spacing={1}>
                <Grid item className='d-flex' sx={{ p: 0 }}>
                    <Typography variant="body2" color="placeholder">
                        Showing {countCurrentRecords} of {count}
                    </Typography>
                </Grid>
                <Grid item sx={{ p: 0 }}>
                    <IconButton
                        id='prevPageButton'
                        tooltip='Previous page'
                        disabled={pending || page === 0}
                        onClick={onPrevPage}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                    <IconButton
                        tooltip='Next page'
                        disabled={pending || page + 1 >= totalPages}
                        onClick={onNextPage}
                        id='nextPageButton'
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default Pagination