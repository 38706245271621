import * as Yup from 'yup'

export const initialValues = {
    workingHours: [],
    availableWeekdays: null,
    availableSaturday: null,
    deliveryRadius: '',
}

export const getInitialValues = () => {
    return Object.assign(
        {},
        initialValues,
    )
}

export const useScheme = () => {
    return Yup.object().shape({
        workingHours: Yup.array().min(1).required('Please enter available working hours'),
        availableWeekdays: Yup.boolean().required('Select available weekdays').nullable(),
        availableSaturday: Yup.boolean().required('Select available saturdays').nullable(),
        deliveryRadius: Yup.string().required('Select delivery distance'),
    })
}
