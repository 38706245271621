import { useState } from 'react'
import clsx from 'clsx'

import Typography from '@/components/common/Typography'
import { TextField } from '@/components/common/Inputs'
import { Button, RouterLink } from '@/components/common/Buttons'

import styles from '../styles.module.css'

import { getInitialValues, useScheme } from './validations'
import { useForm, useKeyLock } from '@/hooks'

import { SignInFormProps } from '../../types'
import EyePassword from '@/components/generics/EyePassword'
import ErrorAuthMessage from '../../../components/Error'
import { ROUTE_RESET_PASSWORD, ROUTE_SIGN_UP } from '@/constants/routes'
import InputAdornment from '@mui/material/InputAdornment'
import avatar from '@/assets/icon/ico-login.svg'
import password from '@/assets/icon/ico-pass.svg'
import { LOGIN_BUTTON, SIGNUP_BUTTON, testProp } from '@/constants/testIds'

const SignInForm = (props: SignInFormProps) => {
    const { pending = false, error = '' } = props

    const schema = useScheme()
    const capsLock = useKeyLock('CapsLock')

    const { getFieldProps, handleSubmit } = useForm(
        schema,
        getInitialValues(),
        props.onSubmit,
    )

    const [inputType, setInputType] = useState('password')

    return (
        <div className={styles.mainContent}>
            <div className={clsx('flex-center', 'flex-column', 'w-100', 'height-100')}>
                <Typography variant="h4" className={styles.title} align='center'>Sign In</Typography>

                <form action="#" onSubmit={handleSubmit} className={clsx('w-100', 'd-flex', 'flex-column', 'align-center')}>
                    <div className={styles.inputWrapper}>
                        <TextField
                            variant='standard'
                            className={styles.textField}
                            placeholder='Login'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={avatar} />
                                    </InputAdornment>
                                ),
                            }}
                            {...getFieldProps('email')}
                        />
                        <TextField
                            variant='standard'
                            className={styles.textField}
                            placeholder='Password'
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <img src={password} />
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <EyePassword type={inputType} onSetIcon={setInputType} />
                                ),
                            }}
                            type={inputType}
                            textHelper={capsLock ? 'Caps lock is on' : ''}
                            {...getFieldProps('password')}
                        />
                        {error && <ErrorAuthMessage error={error} className={styles.errorText} />}
                    </div>

                    <div className={styles.reset}>
                        <Typography variant='body1' color='gray'>Forgot your password?</Typography>
                        <RouterLink to={ROUTE_RESET_PASSWORD.path}>
                            Reset
                        </RouterLink>
                    </div>
                    <Button
                        disabled={pending}
                        variant="contained"
                        pending={pending}
                        {...testProp(LOGIN_BUTTON)}
                        type="submit"
                        size='large'
                        className='rounded-button'
                    >
                        Sign in
                    </Button>
                </form>
            </div>
            <div className='authFooter'>
                <Typography variant='body1'>Want to be a courier?</Typography>
                <RouterLink to={ROUTE_SIGN_UP.path} id={SIGNUP_BUTTON}>
                    Sign Up
                </RouterLink>
            </div>
        </div>
    )
}

export default SignInForm
