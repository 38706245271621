import HelloSign from 'hellosign-embedded'

interface SignEventPayload {
    signatureId: string
}

class HelloSignService {
    private client: HelloSign

    constructor() {
        this.client = new HelloSign()
    }

    public getHelloSignClient() {
        return this.client
    }

    openTemplate(signUrl: string) {
        return this.client.open(signUrl, {
            clientId: import.meta.env.VITE_DROPBOX_SIGN_COURIER_CLIENT_ID,
            skipDomainVerification: import.meta.env.VITE_ENV !== 'production',
            testMode: import.meta.env.VITE_ENV !== 'production',
            allowCancel: false,
        })
    }

    onSuccess() {
        return new Promise((resolve) => {
            this.client.on('sign', (payload: SignEventPayload) => {
                resolve(payload.signatureId)
            })
        })
    }

    offEvent() {
        this.client.off('sign')
    }

}

export default new HelloSignService()
