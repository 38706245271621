import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()((theme) => ({
    root: {
        input: {
            height: '0.5em',
            '&::placeholder': {
                fontSize: 15,
                color: 'rgba(0, 0, 0, .80)',
            },
            padding: '16.5px 14px',
            fontSize: 15,
        },
        '& .MuiInputBase-root:before': {
            borderBottom: '1px solid rgba(0, 0, 0, .14)',
        },
        '& .MuiInputBase-root:hover:not(.Mui-disabled):before': {
            borderBottom: '1px solid rgba(0, 0, 0, .14)',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            fieldset: {
                border: '1px solid rgba(0, 0, 0, 0.14)',
            },
            '&:hover fieldset': {
                border: '1px solid rgba(0, 0, 0, 0.14)',
            },
            '&.Mui-focused fieldset': {
                border: '1px solid rgba(64, 110, 206, 0.8)',
            },
            '&.Mui-disabled fieldset': {
                background: '#f7f8fa',
            },
        },
    },
    mediumInput: {
        '& input': {
            width: 400,
            height: 32,
            fontSize: 17,
            padding: '1px 2px',
            textAlign: 'center',
        },
        backgroundColor: '#fff',
    },
    bigInput: {
        '& input': {
            width: 400,
            fontSize: 24,
            padding: '18px 16px',
        },
        backgroundColor: '#fff',
    },
    errorText: {
        color: theme.palette.error.main,
        fontSize: 12,
    },
}))

export default useStyles
