import MUIButton from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import useStyles from './styles'
import { ButtonColors } from '@/common/types'
import { SxProps } from '@mui/material'

interface ButtonProps {
    isIcon?: boolean;
    className?: string;
    variant?: 'contained' | 'outlined' | 'text';
    pending?: boolean;
    disabled?: boolean;
    children?: React.ReactNode | string;
    color?: ButtonColors;
    view?: 'default' | 'primary' | 'secondary' | 'success' | 'warning' | 'error' | 'transparent';
    component?: React.ElementType;
    href?: string;
    to?: string;
    size?: 'small' | 'medium' | 'large';
    type?: 'button' | 'submit' | 'reset';
    onClick?: () => void;
    id?: string;
    sx?: SxProps
}

function Button(props: ButtonProps) {

    const {
        isIcon,
        className,
        variant = 'contained',
        pending = false,
        disabled = false,
        children,
        color = 'primary',
        view,
        component = 'button',
        size,
        type = 'button',
        id,
        ...rest
    } = props

    const { classes: styles } = useStyles()
    const classNames = {
        [styles.isIconRoot]: isIcon && variant !== 'outlined',
        [styles.isIconSmall]: isIcon && size === 'small',
        [styles.disabledStyles]: disabled,
        [styles.pendingStyles]: pending,
        [styles.view]: view,
    }

    const classes = {
        root: styles.root,
        contained: styles.contained,
        outlined: styles.outlined,
        text: styles.text,
        label: styles.label,
        sizeSmall: styles.sizeSmall,
        sizeLarge: styles.sizeLarge,
    }
    return (
        <MUIButton
            color={color}
            variant={variant}
            className={clsx(className, classNames)}
            classes={classes}
            disabled={pending || disabled}
            component={props.href ? 'a' : props.to ? Link : component}
            target={props.href ? '_blank' : undefined}
            size={size}
            type={type}
            id={id}
            {...rest}
            onClick={props.onClick}
        >
            {children}
            {pending && (
                <span className={styles.loader}>
                    <CircularProgress color="inherit" size="1.29em" />
                </span>
            )}
        </MUIButton>
    )
}
export default Button
