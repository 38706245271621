import Typography from '@/components/common/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import { useNavigate } from 'react-router-dom'
import { AuthUser } from '@/common/types'
import TableHead from '@/components/generics/Table/TableHead'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import Paper from '@/components/generics/Paper'
import Item from './Item'
import { VIEW_ALL_ORDERS_BUTTON } from '@/constants/testIds'
import { CourierType } from '@/common/enums'
import { filterOrdersTableHeadersByCourierType } from '@/utils'

interface Props {
    courier?: AuthUser | null
}

const OrderInformation = ({ courier }: Props) => {
    const navigate = useNavigate()
    const handleViewAll = () => navigate('/order', { state: { courier } })
    return (
        <Paper px={5} py={5} radius={20}>
            <Box display='flex' justifyContent='space-between' ml={2} mb={2}>
                <Typography variant='h4'>Latest Orders</Typography>
                <Button onClick={handleViewAll} id={VIEW_ALL_ORDERS_BUTTON} variant='text' color='info' size='small' style={{ alignItems: 'left' }} >View All</Button>
            </Box>
            <TableContainer>
                <Table sx={{ mb: 0, borderSpacing: 20, borderCollapse: 'inherit' }} aria-label="simple table">
                    <TableHead headers={filterOrdersTableHeadersByCourierType(courier?.courierType as CourierType)} />
                    <TableBody>
                        {courier?.latestDeliveries && courier?.latestDeliveries.map((item, index) =>
                            <Item key={index} item={item} />,
                        )}
                    </TableBody>
                </Table>
                {!courier?.latestDeliveries?.length && (
                    <Typography className='text-center' variant='h6'>There is no order history yet</Typography>
                )}
            </TableContainer>
        </Paper>
    )
}

export default OrderInformation