import SvgIcon from '@/components/common/SvgIcon'
import { PropsSvg } from '../types'

export const IconEmail = (props: PropsSvg) => {
    const { width = 80, height = 80 } = props

    return (
        <SvgIcon width={width} height={height} viewBox="0 0 80 80">
            <path fill="#E21C40" fillRule="evenodd" d="M57 13.511l12.31 7.718c.46.228.69.685.69 1.142 0 .218-.052.436-.157.629H70v42c0 1.105-.895 2-2 2H2c-1.105 0-2-.895-2-2V23h.157C.052 22.807 0 22.59 0 22.371c0-.457.23-.914.69-1.142L13 13.51V10c0-1.105.895-2 2-2h7.657L33.897.715c.67-.434 1.533-.429 2.197.014L47.01 8H55c1.105 0 2 .895 2 2v3.511zm0 3.155v11.45l9.086-5.745L57 16.666zm-2.779 13.208V11.733c0-.553-.448-1-1-1h-6.433l-.156.267-.403-.267H23.647l-.05.033-.023-.033h-6.795c-.552 0-1 .447-1 1v18.141L35 42.029l19.221-12.155zM13 28.117V16.666L3.914 22.37 13 28.117zM42.114 8L35 3.277 27.808 8h14.306zM2.754 24.81v38.44c0 .552.448 1 1 1h62.492c.552 0 1-.448 1-1V24.819L35.69 44.77c0 .229-.23.229-.46.229-.231 0-.461 0-.692-.229L2.754 24.811zM36.5 18h8c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5h-8c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5zm-11 0h3c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5h-3c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5zm17 6h2c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5h-2c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5zm-17 0h10c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5h-10c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5zm0 7h19c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5h-19c-.828 0-1.5-.672-1.5-1.5s.672-1.5 1.5-1.5z"/>
        </SvgIcon>
    )
}
