import { useEffect, useState } from 'react'
import clsx from 'clsx'
import Grid from '@mui/material/Grid'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import Typography from '@/components/common/Typography'
import Notifications from '@/components/generics/Notifications'
import { Button } from '@/components/common/Buttons'
import Paper from '@/components/generics/Paper'
import { IconAgreement } from '@/components/svg/Icons'

import useCrud from '@/hooks/useCrud'
import { SignUpComponentsProps } from '../../types'
import { useStyles } from './styles'
import { useAuth } from '@/hooks/useAuth'
import { API_GET_AGREEMENT_URL, API_SIGN_UP } from '@/constants/routes'
import helloSignService from '@/services/helloSign'
import { AgreementType, CourierRegistrationStatus, CourierRegistrationSteps, CourierType } from '@/common/enums'
import LoaderSmall from '@/components/common/Loader/LoaderSmall'
import { FLEET_AGREEMENT, FW9_FORM, testProp } from '@/constants/testIds'

const DocumentsStep = ({ executeRequest = true, nextStep }: SignUpComponentsProps) => {
    const { classes } = useStyles()
    const { user, setUser } = useAuth()
    const [isAgreement, setIsAgreement] = useState(false)
    const [isFW9, setIsFW9] = useState(false)
    const [apiRequest, apiState] = useCrud('POST')
    const [loadingType, setLoadingType] = useState<'fleet' | 'fw9' | ''>('')

    const setFleetAgreement = () => {
        const fleetAgreement = user?.fleetAgreement
        if (!fleetAgreement?.signatureDate && !loadingType) {
            setLoadingType('fleet')
            apiRequest(API_GET_AGREEMENT_URL.replace(':agreementType', AgreementType.FLEET_AGREEMENT)).then((response) => {
                helloSignService.openTemplate(response.signatureUrl as string)
                setIsAgreement(true)
            }).finally(() => setLoadingType(''))
        }
    }

    const setFW9Agreement = () => {
        const fw9Agreement = user?.fw9Agreement
        if (!fw9Agreement?.signatureDate && !loadingType) {
            setLoadingType('fw9')
            apiRequest(API_GET_AGREEMENT_URL.replace(':agreementType', AgreementType.FW9_AGREEMENT)).then((response) => {
                helloSignService.openTemplate(response.signatureUrl as string)
                setIsFW9(true)
            }).finally(() => setLoadingType(''))
        }
    }

    const submitAgreements = async () => {
        if (!executeRequest && user) {
            setUser({ ...user, registrationStatus: CourierRegistrationStatus.SIGNATURE_READY })
            nextStep(CourierRegistrationSteps.ONBOARDING)
            return
        }
        try {
            const response = await apiRequest(API_SIGN_UP.CONTINUE_SKIP_SIGNATURES)
            if (response && user) {
                setUser({ ...user, registrationStep: response.registrationStep || '' })
                nextStep(CourierRegistrationSteps.ONBOARDING)
            }
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        if (user) {
            const fleetAgreement = user?.fleetAgreement
            const fw9Agreement = user?.fw9Agreement

            if (fleetAgreement?.signatureDate) setIsAgreement(true)
            if (fw9Agreement?.signatureDate) setIsFW9(true)
        }
    }, [user])

    useEffect(() => {
        helloSignService.onSuccess().then((payload) => {
            const fleetAgreement = user?.fleetAgreement
            const fw9Agreement = user?.fw9Agreement
            if (payload === fleetAgreement?.signatureId) {
                setIsAgreement(true)
            }
            if (payload === fw9Agreement?.signatureId) {
                setIsFW9(true)
            }
        })

        // return () => {
        //     helloSignService.offEvent()
        // }
    }, [])

    return (
        <div className={classes.mainContent}>
            <div className={clsx('flex-center', 'flex-column', 'w-100', 'height-100')}>
                <Typography variant="h5" weight='500' gutter='label' align='center'>Sign the Documents</Typography>
                <Typography variant='body1' className='text-center'>
                    Please sign the documents to complete registration
                </Typography>
                <div className={clsx('w-100', 'd-flex', 'flex-column', 'align-center')}>
                    <div className={classes.inputWrapper}>
                        <Grid container spacing={3}>
                            <Grid item md={6} lg={12} xs={12}>
                                <Paper radius={15} className={clsx(classes.itemAgreement, isAgreement ? classes.agreementSuccess : classes.agreementPending)} px={isAgreement ? 3 : 2} py={1}>
                                    <div {...testProp(FLEET_AGREEMENT)} onClick={setFleetAgreement}>
                                        {isAgreement &&
                                            <div className={classes.iconSuccess}>
                                                <CheckCircleIcon color='success' fontSize='large' />
                                            </div>
                                        }
                                        <IconAgreement color={isAgreement ? 'success' : 'pending'} />
                                        <Typography variant='body2' color={isAgreement ? 'inherit' : 'secondary'}>Fleet Agreement</Typography>
                                    </div>
                                    {loadingType === 'fleet' &&
                                        <div className={classes.loading}>
                                            <LoaderSmall size='2em' />
                                        </div>
                                    }
                                </Paper>
                            </Grid>
                            {user?.courierType !== CourierType.W2 &&
                                <Grid item md={6} lg={6} xs={12}>
                                    <Paper radius={15} className={clsx(classes.itemAgreement, isFW9 ? classes.agreementSuccess : classes.agreementPending)} px={isFW9 ? 3 : 2} py={1}>
                                        <div {...testProp(FW9_FORM)} onClick={setFW9Agreement}>
                                            {isFW9 &&
                                                <div className={classes.iconSuccess}>
                                                    <CheckCircleIcon color='success' fontSize='large' />
                                                </div>
                                            }
                                            <IconAgreement color={isFW9 ? 'success' : 'pending'} />
                                            <Typography variant='body1' weight='500' color={isFW9 ? 'inherit' : 'secondary'}>FW9 Form</Typography>
                                        </div>
                                        {loadingType === 'fw9' &&
                                            <div className={classes.loading}>
                                                <LoaderSmall size='2em' />
                                            </div>
                                        }
                                    </Paper>
                                </Grid>
                            }
                        </Grid>
                        <Notifications isAgreement />
                        <div className={clsx('d-flex', 'flex-column', 'gap-10')}>
                            <Button
                                className={clsx('rounded-button', classes.continueButton)}
                                disabled={apiState.loading || !isAgreement || (!isFW9 && user?.courierType !== CourierType.W2)}
                                pending={apiState.loading}
                                variant="contained"
                                color="secondary"
                                onClick={submitAgreements}
                            >
                                Continue
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DocumentsStep
