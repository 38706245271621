import { useFormik, FormikHelpers } from 'formik'
import { AnySchema } from 'yup'

export const REINIT_VALUES = { enableReinitialize: false }

type FormOptions = { enableReinitialize?: boolean, validateOnMount?: false, validateOnChange?: boolean, validateOnBlur?: boolean }

export function useForm<Values extends Record<string, unknown>>(
    schema: AnySchema,
    initialValues: Values,
    onSubmit: (_values: Values, _formikHelpers: FormikHelpers<Values>) => void | Promise<void>,
    formOptions: FormOptions = { validateOnMount: false, validateOnChange: false, validateOnBlur: false },
) {

    const formik = useFormik({
        validationSchema: schema,
        initialValues,
        ...formOptions,
        onSubmit,
    })

    const getFieldProps = (key: string) => ({
        ...formik.getFieldProps(key),
        error: formik.errors && formik.errors[key],
    })
    return { ...formik, getFieldProps }
}
