import { Outlet } from 'react-router-dom'
import Box from '@mui/material/Box'

import PendingStatus from '@/components/common/PendingBlock/PendingStatus'
import SignAgreements from '@/components/generics/SignAgreements'
import AddressModal from '@/pages/Auth/SignUp/components/AddressModal'
import DashboardSteps from '@/components/common/Steps'
import Sidebar from './Sidebar'
import { useStyles } from './styles'
import AppBarMenu from './AppBar/AppBarMenu'

import { useAuth } from '@/hooks/useAuth'
import { useBooleanHandlers } from '@/hooks/useBooleanHandlers'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'

const AppLayout = () => {
    const { classes } = useStyles()
    const { user } = useAuth()
    const [isOpen, setIsOpen, setIsClose] = useBooleanHandlers(false)
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })

    return (
        <div className={classes.root}>
            {isSmallScreen ?
                <AppBarMenu />
                :
                <Sidebar />
            }

            <Box
                component="section"
                sx={{ bgcolor: 'background.default' }}
                className={isSmallScreen ? classes.mobileContent : classes.content}
            >
                <PendingStatus courier={user} />
                <Outlet />
            </Box>
            <DashboardSteps isOpen={isOpen} setIsOpen={setIsOpen} setIsClose={setIsClose} />
            {!user?.address && <AddressModal />}
            {user && user.address && <SignAgreements />}
        </div>
    )
}

export default AppLayout