import { create } from 'zustand'
import { FilterValues, SortDirectionTypes } from '@/common/types'

interface DeliveriesStore {
    search: string,
    page: number,
    sort: SortDirectionTypes,
    orderBy: string,
    filters: Pick<FilterValues, 'startDate' | 'endDate' | 'courierIds'>
    setFilters: (_values: Partial<FilterValues>) => void
}

export const useDeliveriesStore = create<DeliveriesStore>((set) => ({
    filters: {},
    search: '',
    page: 0,
    sort: 'DESC',
    orderBy: 'createdAt',
    setPage: (page: number) => set({ page }),
    setSearch: (search: string) => set({ search }),
    setFilters: (values: Partial<FilterValues>) => {
        const { page, search, sort, orderBy, ...rest } = values
        set((state) => ({
            ...state,
            filters: {
                ...rest,
            },
            page: page,
            search: search,
            sort: sort || state.sort,
            orderBy: orderBy || state.orderBy,
        }))
    },
}))