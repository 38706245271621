import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Typography from '@mui/material/Typography'
import Paper from '@/components/generics/Paper'
import TableHead from '@/components/generics/Table/TableHead'
import noDeliveries from '@/assets/icon/ico-delivery.svg'
import { OrderList } from '@/common/types'

import Item from '../../Earning/Components/OrderList/Item'
import { useStyles } from '../styles'
import { filterOrdersTableHeadersByCourierType } from '@/utils'
import { useAuth } from '@/hooks/useAuth'
import { CourierType } from '@/common/enums'

interface Props extends OrderList {
    orderBy: string,
}

const List = ({ records, orderBy }: Props) => {
    const { classes } = useStyles()
    const { user } = useAuth()

    return (
        <>
            {records && Array.isArray(records) ? (
                <TableContainer component={Paper} radius={10} px={2} py={1}>
                    <Table sx={{ mb: 0, borderSpacing: 20, borderCollapse: 'inherit' }} aria-label="simple table">
                        <TableHead
                            headers={filterOrdersTableHeadersByCourierType(user?.courierType as CourierType)}
                            orderBy={orderBy}
                        />
                        <TableBody>
                            {records.map((item) => (
                                <Item key={item.id} item={item} />
                            ))}
                        </TableBody>
                    </Table>
                    {!records.length && (
                        <div
                            className={classes.paper}
                            style={{ height: 'calc(100% - 99px - 32px - 127px)' }}
                        >
                            <div className={classes.noDeliveries}>
                                <img src={noDeliveries} />
                                <Typography className={classes.title}>You have not made orders yet</Typography>
                                <Typography className={classes.subtitle}>
                                    All the information on completed orders will appear here
                                </Typography>
                            </div>
                        </div>
                    )}
                </TableContainer>
            ) : (
                <div>No data</div>
            )}
        </>
    )
}

export default List