import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
    pendingHeader: {
        borderRadius: '10px',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'flex-start',
        wordBreak: 'break-word',
    },
    textBlock: {
        justifyContent: 'space-around',
        paddingLeft: '20px',
    },
    titlePending: {
        fontSize: '25px',
        paddingBottom: '3px',
    },
    subtitlePending: {
        fontSize: '14px',
    },
    email: {
        color: 'red',
    },
    continueButton: {
        padding: '15px 40px',
    },
}))
