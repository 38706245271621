import { FC, useEffect, useState } from 'react'
import { useStyles } from '../../styles'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { AuthUser, Steps } from '@/common/types'
import clsx from 'clsx'
import Typeform from '@/components/common/Typeform'
import LoaderSmall from '@/components/common/Loader/LoaderSmall'
import useCrud from '@/hooks/useCrud'
import { API_COURIER } from '@/constants/routes'
import { QuizStatus } from '@/common/enums'

const InfoStep: FC<{ data: Steps, courier: AuthUser | null, setUser: (_user: AuthUser | null) => void, }> = ({
    data, courier, setUser,
}) => {
    const [showTest, setShowTest] = useState(false)
    const [isQuizApproved, setIsQuizApproved] = useState(false)
    const [testSubmitted, setTestSubmitted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [score, setScore] = useState(0)
    const { classes } = useStyles()
    const [apiRequest] = useCrud('POST')
    const updateCourierQuiz = async (typeFormId: string) => {
        return await apiRequest(API_COURIER.UPDATE_QUIZ.replace(':typeFormId', typeFormId))
    }

    useEffect(() => {
        if (courier?.quizStatus === QuizStatus.PASSED) {
            setIsQuizApproved(true)
            setTestSubmitted(true)
        }
    }, [])

    const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

    const handleSubmit = async (event: any) => {
        setTestSubmitted(true)
        setLoading(true)
        await delay(2000)
        try {
            const resp = await updateCourierQuiz(event.responseId)
            setScore(resp.score)
            if (resp.approved && courier) {
                setIsQuizApproved(true)
                setShowTest(!showTest)
                const newCourier = { ...courier, quizStatus: QuizStatus.PASSED }
                setUser(newCourier)
            } else {
                //setShowTest(!showTest)
                setIsQuizApproved(false)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }
    const handleShowTest = () => {
        setShowTest(!showTest)
        setTestSubmitted(false)
    }

    return (
        <Box sx={{ backgroundColor: 'white', height: '500px' }}>
            <div className={classes.modal}>
                <div className={classes.body}>
                    <div className={clsx('text-center')}>
                        <Typography className={classes.title}>
                            {data.title}
                        </Typography>
                        <Typography className={classes.desc}>
                            {data.desc}
                        </Typography>
                        {showTest && !testSubmitted ? (
                            <Typeform onSubmit={handleSubmit} />
                        ) : (
                            loading ? <LoaderSmall /> :
                                !testSubmitted ?
                                    <button className={classes.testBtn} onClick={handleShowTest}>
                                        <Typography variant="h6">Click here to</Typography>
                                        <Typography variant="h4">Take the Quiz</Typography>
                                    </button>
                                    :
                                    isQuizApproved ?
                                        <Typography variant="h4">You have approved the quiz</Typography>
                                        :
                                        <>
                                            <Typography variant="h4">You should retake a test</Typography>
                                            <Typography variant="h5">Your Score: {score}/12</Typography>
                                            <button className={classes.testBtn} onClick={handleShowTest}>
                                                <Typography variant="h6">Click here to</Typography>
                                                <Typography variant="h4">Retake the Quiz</Typography>
                                            </button>
                                        </>
                        )}
                    </div>
                </div>
            </div>

        </Box>
    )
}

export default InfoStep
