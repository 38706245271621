import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
    backArrowContainer: {
        width: '100%',
    },
    error: {
        margin: '-20px 0 15px',
    },
    subtitle: {
        fontSize: '16px',
        paddingBottom: '6px',
        paddingTop: '10px',
        margin: 'none',
    },
    list: {
        boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.5)',
        borderRadius: '10px',
        padding: '20px 26px 16px 45px',
        boxSizing: 'border-box',
    },
    continueButton: {
        padding: '15px 40px',
        marginBlock: 20,
    },
    buttonText: {
        textAlign: 'center',
        fontSize: '17px',
        fontWeight: '500',
        color: '#fff',
    },
}))
