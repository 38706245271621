import AppBar from '@/components/layouts/AppLayout/AppBar'
import AppBarContent from '@/components/generics/AppBarContent'
import Loader from '@/components/common/Loader'
import Pagination from '@/components/generics/Pagination'
import useListInit from '@/hooks/useListInit'
import { TransactionList } from '@/common/types'
import { API_TRANSACTIONS } from '@/constants/routes'
import { getPaginationCount } from '@/utils'
import List from './Components/List'
import FilterDialog from './Components/FilterDialog'
import { FiltersType } from '../helpers'
import { useBooleanHandlers } from '@/hooks/useBooleanHandlers'
import { TransactionType } from '@/common/enums'

const SORT_BY_ACTIVE = 'created_at'

export const Transaction = () => {
    const [openFilters, setOpenFilters, setCloseFilters] = useBooleanHandlers(false)
    const { 
        data,
        isLoading,
        page,
        orderBy,
        sort,
        filters,
        prevPage,
        nextPage,
        setSortBy,
        setSortDirection,
        setFilter,
    } = useListInit<TransactionList>({
        endpoint: API_TRANSACTIONS.GET_TRANSACTIONS,
        sortActive: SORT_BY_ACTIVE,
        defaultFilters: { transactionTypes: Object.values(TransactionType).filter(type => type !== TransactionType.CLAWBACK && type !== TransactionType.ORDER_PAYOUT_ADJUSTMENT) },
    })

    return (
        <div>
            <AppBar>
                <AppBarContent
                    showExport={false}
                    showAdd={false}
                    title='Transactions'
                    pagination={
                        <Pagination
                            page={page}
                            count={getPaginationCount(data)}
                            pending={isLoading}
                            onNextPage={nextPage}
                            onPrevPage={prevPage}
                        />
                    }
                    showFilters={true}
                    openFilterDialog={setOpenFilters}
                    showSearch={false}
                    filters={filters}
                />
            </AppBar>

            <FilterDialog
                filters={filters}
                open={openFilters}
                onClose={setCloseFilters}
                handleApplyFilters={(values: FiltersType) => setFilter(values)}
            />

            <div className='page-content'>
                <Loader open={isLoading} />
                {data && data.records &&
                <List
                    {...data}
                    orderBy={orderBy}
                    sortDirection={sort}
                    setSortBy={setSortBy}
                    setSortDirection={setSortDirection}
                />
                }
            </div>
        </div>
    )
}