import clsx from 'clsx'
import Typography from '@/components/common/Typography'
import { Button, RouterLink } from '@/components/common/Buttons'
import { useStyles } from './styles'
import { SignUpComponentsProps } from '../../types'
import { ROUTE_LOGIN } from '@/constants/routes'
import Notifications from '@/components/generics/Notifications'
import { GET_STARTED } from '@/constants/testIds'
import { CourierRegistrationSteps } from '@/common/enums'
import { Box } from '@mui/material'

const StartStep = (props: SignUpComponentsProps) => {
    const { classes } = useStyles()

    const onSubmit = async () => props.nextStep(CourierRegistrationSteps.START)

    return (
        <div className={classes.startStep}>
            <div className={clsx('flex-center', 'flex-column', 'w-100', 'height-100')}>
                <Box mt={3}>
                    <Typography variant='h4' weight='500' gutter='bottom2'>Hello Applicant</Typography>
                </Box>
                <Typography className='text-center' variant='body1' gutter='bottom2'>
                    Before beginning your application, please have <br />
                    pictures of the following items ready.
                </Typography>

                <ul className={classes.list}>
                    <li className={classes.listItem}>
                        <Typography variant='body1'>Your driver&apos;s license</Typography>
                    </li>
                    <li className={classes.listItem}>
                        <Typography variant='body1'>Your vehicle insurance card</Typography>
                    </li>
                    <li className={classes.listItem}>
                        <Typography variant='body1'>Vehicle photos (Left, Right, Front, Back)</Typography>
                    </li>
                </ul>

                <Notifications isPersonalInformation />

                <Typography gutter='verticalSpace' variant='body1' color='placeholder'>
                    Thank you and good luck! <br />
                    from the Medzoomer team
                </Typography>

                <div className='flex-center'>
                    <Button
                        className={clsx('rounded-button', classes.continueButton)}
                        variant="contained"
                        color="secondary"
                        id={GET_STARTED}
                        onClick={onSubmit}
                    >
                        <Typography variant='body1' color='light'>Get started</Typography>
                    </Button>
                </div>
            </div>
            <div className='authFooter'>
                <Typography variant='body1'>Already have an account? </Typography>
                <RouterLink to={ROUTE_LOGIN.path} >
                    Sign In
                </RouterLink>
            </div>
        </div>
    )
}

export default StartStep
