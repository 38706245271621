import { CourierRegistrationSteps, CourierStatus, CourierType, OrderStatus } from '@/common/enums'
import { QueryParams, FilterValues, ItemValue, AddressObject, UploadFile } from '@/common/types'
import { COMPONENT_TYPE_MAP } from '@/constants'
import { ROUTE_DASHBOARD, ROUTE_DECLINED, ROUTE_SIGN_UP } from '@/constants/routes'
import { ORDER_TABLE_HEADERS } from '@/pages/Dashboard/helpers'
import dayjs from 'dayjs'
export const decimalOnly = /^\d+(\.\d{2})?$/
export const phoneRegExp = /^(?:\+1)?[ -]?\(?([2-9][0-8][0-9])\)?[ -]?([2-9][0-9]{2})[ -]?([0-9]{4})$/
export const routingNumberRegExp = /^((0[1-9])|(1[0-2])|(2[1-9])|(3[0-2]))[0-9]{7}$/
import { toast } from 'react-toastify'

export const formatEmail = (email: string) => {
    return email.toLowerCase()
}

export const getInitialRoute = (registrationStep: CourierRegistrationSteps, status: CourierStatus) => {
    let route = ROUTE_DASHBOARD.path
    const currentPath = window.location.pathname
    const rootPaths = ['/', '/login', '/sign-up'].includes(currentPath)
    const stepIsOnboarding = [CourierRegistrationSteps.ONBOARDING, CourierRegistrationSteps.ONBOARDING_PENDING].includes(registrationStep)

    if (status === CourierStatus.SIGN_UP_FLOW_PENDING) {
        route = `${ROUTE_SIGN_UP.path}?step=${CourierRegistrationSteps.ONBOARDING_PENDING}`
    }
    else if (status === CourierStatus.DECLINED || status === CourierStatus.TERMINATED) {
        route = ROUTE_DECLINED.path
    } else if (status === CourierStatus.ACTIVE && stepIsOnboarding && rootPaths) {
        route = ROUTE_DASHBOARD.path
    } else {
        if (Object.values(CourierRegistrationSteps).includes(registrationStep as CourierRegistrationSteps)) {
            if (status === CourierStatus.PENDING && registrationStep === CourierRegistrationSteps.ONBOARDING) {
                route = ROUTE_DASHBOARD.path
            } else if ([CourierStatus.PENDING, CourierStatus.CHECKR_ONBOARD_PENDING].includes(status) && stepIsOnboarding) {
                route = `${ROUTE_SIGN_UP.path}?step=${registrationStep}`
            } else {
                route = stepIsOnboarding ? rootPaths ? route : currentPath : `${ROUTE_SIGN_UP.path}?step=${registrationStep}`
            }
        }
    }

    return route
}

export const objectToQueryString = (obj: QueryParams): string => {
    return Object.keys(obj)
        .map(i => i + '=' + obj[i as keyof QueryParams])
        .join('&')
}

export const getStoreApiPath = (id: string | null | undefined, objectPaths: { CREATE: string, UPDATE: string }) => {
    return id ? objectPaths.UPDATE.replace(':id', id) : objectPaths.CREATE
}

export function extractPropertiesFromObject<T, K extends keyof T>(objectBase: T, objectFull: Pick<T, K>): Partial<T> {
    return Object.keys(objectFull).reduce((obj, key) => {
        if (Object.prototype.hasOwnProperty.call(objectBase, key)) {
            obj[key as keyof T] = objectBase[key as keyof T]
        }
        return obj
    }, {} as Partial<T>)
}

export function formatToTitleCase(str: string) {
    if (!str) return str
    return str.replace(/\w\S*/g, (txt: string) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    })
}

export function formatDistance(distance: number) {
    return distance ? `${distance.toFixed(2)} mi` : '0 mi'
}

export function formatBoolean(bool: boolean) {
    return bool ? 'Yes' : 'No'
}

// 1-(999)999-9999
export function formatPhoneNumber(phone: string) {
    return phone.replace(/(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4')
}

export function formatDateTime(date: string | Date, format = 'YYYY-MM-DD') {
    return dayjs(date).format(format)
}

export const downloadFile = (data: string, mimeType: string, fileName: string) => {
    const blob = new Blob([data], { type: mimeType })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = fileName ? `${fileName}` : 'data.csv'
    link.click()
}

export const formatCurrency = (value = 0) => {
    return value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 2,
    })
}

export const parameterizeArray = (key: string, arr: Array<string>) => {
    return arr.map((item) => `${key}=${item}`).join('&')
}

export const getPaginationCount = (data: { total: number, filteredTotal: number }) => {
    if (!data) return 0

    const { filteredTotal } = data

    return filteredTotal ? filteredTotal : 0
}

export const convertFiltersToQueryString = (filters: QueryParams): string => {
    let queryString = ''

    for (const [key, value] of Object.entries(filters)) {
        if (Array.isArray(value) && value.length > 0) {
            value.forEach((item: ItemValue) => {
                queryString += `${key}=${item?.id || item}&`
            })
        }

        if (key === 'dates') {
            const { field, startDate, endDate } = value as FilterValues['dates']
            if (field && startDate && endDate) {
                queryString += `dateFilter[field]=${field}&`
                queryString += `dateFilter[startDate]=${startDate}&`
                queryString += `dateFilter[endDate]=${endDate}&`
            }
        }

        if (!['object', 'number'].includes(typeof value) && value) {
            queryString += `${key}=${value}&`
        }

        if (typeof value === 'number') {
            queryString += `${key}=${key !== 'page' && [0, 1].includes(value) ? Boolean(value) : value}&`
        }
    }
    return queryString.slice(0, -1)
}

export const flattenAddressComponents = (addressComponents: google.maps.GeocoderAddressComponent[]): AddressObject => {
    const addressObject: AddressObject = {}
    for (const component of addressComponents) {
        const componentType = component.types[0]
        const componentTypeSecondary = component.types[1]
        const componentKey = COMPONENT_TYPE_MAP[componentType] || COMPONENT_TYPE_MAP[componentTypeSecondary] || componentType
        addressObject[componentKey as keyof AddressObject] = component.long_name
    }
    return addressObject
}

export const getFormattedAddress = (value: AddressObject) => {
    const { number, street, city, zip, state, country } = value
    return [number, street, city, zip, state, country].filter(Boolean).join(' ')
}

export const stringToBoolean = (value: string) => value === 'true'

export const uploadFilesToS3 = async (files: UploadFile[]) => {
    try {
        return await Promise.all(files.map((file) => {
            const requestOptions = {
                method: 'PUT',
                headers: { 'Content-Type': 'multipart/form-data' },
                body: file.file,
            }
            if (file.uploadUri) {
                return fetch(file.uploadUri, requestOptions)
            }

            return Promise.resolve(false)
        }))
    } catch (e) {
        toast.error('Error uploading files')
        console.warn(e)
        throw e
    }
}

export const getOrderStatus = (status: OrderStatus) => {
    const isCanceledWithFee = [OrderStatus.CANCELED_WITH_FEE, OrderStatus.CANCELED_WITH_CHARGE_AND_FEE].includes(status)
    return isCanceledWithFee ? OrderStatus.CANCELED : status
}

export const getInputError = (touched: boolean | undefined, error: string | undefined, submitted = false) => {
    return (touched && !!error) || (submitted && !!error) ? error : ''
}

export const filterOrdersTableHeadersByCourierType = (courierType: CourierType) => {
    if (courierType === CourierType.MZ_1099) {
        return ORDER_TABLE_HEADERS
    }
    return ORDER_TABLE_HEADERS.filter((header) => header.label !== 'Earned')
}