import clsx from 'clsx'
import InputAdornment from '@mui/material/InputAdornment'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import { SelectChangeEvent } from '@mui/material/Select'
import Grid from '@mui/material/Grid'

import Typography from '@/components/common/Typography'
import { DayPicker, SelectField, TextField } from '@/components/common/Inputs'
import { Button } from '@/components/common/Buttons'
import RadioGroup from '@/components/common/Inputs/RadioGroup'
import Notifications from '@/components/generics/Notifications'

import useCrud from '@/hooks/useCrud'
import { useForm } from '@/hooks'
import { DEVICES, GENDER, HAT, HEARD_FROM_OPTIONS, LANGUAGES, OTHER_DELIVERY_SERVICES, SignUpValues, T_SHIRTS } from './types'
import { SignUpComponentsProps } from '../../types'
import { useStyles } from './styles'
import { getInitialValues, useScheme } from './validations'
import { stringToBoolean } from '@/utils'
import { API_SIGN_UP } from '@/constants/routes'

import tShirt from '@/assets/icon/ico-t-shirt.svg'
import { useState } from 'react'
import { DEVICE_PLATFORM, GENDER_BOX, HAT_BOX, HEAR_ABOUT_BOX, PRIMARY_LANGUAGE, T_SHIRT_BOX, WORK_BEFORE_RADIO } from '@/constants/testIds'
import { Box } from '@mui/material'

const DetailStep = (props: SignUpComponentsProps) => {
    const { classes } = useStyles()
    const schema = useScheme()
    const [apiRequest] = useCrud('POST')

    const [loading, setLoading] = useState(false)
    const [showOtherField, setShowOtherField] = useState(false)
    const [otherHeardFromValue, setOtherHeardFromValue] = useState('')

    const onSubmit = async (values: SignUpValues) => {
        try {
            setLoading(true)
            if (otherHeardFromValue !== '') {
                values.heardFrom = otherHeardFromValue
            }
            const { hatQuestion, ...rest } = values
            const payload = {
                hatQuestion: stringToBoolean(hatQuestion),
                ...rest,
            }
            const response = await apiRequest(API_SIGN_UP.SET_GENERAL_INFORMATION, payload)
            if (response) {
                props.nextStep(response.registrationStep)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const { values, errors, setFieldValue, handleSubmit } = useForm(
        schema,
        getInitialValues(),
        onSubmit,
    )

    return (
        <div className={classes.mainContent}>
            <Box mt={3}>
                <Typography variant="h4" gutter='bottom2' align='center'>Applicant Details</Typography>
                <Notifications isPersonalInformation />
            </Box>
            <form action="#" onSubmit={handleSubmit} className={clsx('d-flex', 'flex-column', 'align-center')}>
                <div className={classes.inputWrapper}>
                    <Grid container spacing={2} rowSpacing={3}>
                        <Grid item lg={6} xs={12}>
                            <DayPicker
                                name='birthdate'
                                labelDate='Date of Birth'
                                value={values.birthdate}
                                onChange={(e) => setFieldValue('birthdate', e)}
                            />
                            <div>
                                {!!errors.birthdate &&
                                    <Typography noWrap={false} gutter='horizontalSpaceInputError' color="error" variant="caption">
                                        {typeof errors.birthdate === 'string' ? errors.birthdate : 'Error'}
                                    </Typography>
                                }
                            </div>
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <SelectField
                                id={GENDER_BOX}
                                value={values.gender}
                                placeholder='Gender *'
                                error={errors?.gender || null}
                                options={GENDER}
                                onChange={(evt: SelectChangeEvent) => setFieldValue('gender', evt.target.value)}
                            />
                        </Grid>
                        <Grid item lg={6} xs={12}>
                            <SelectField
                                id={T_SHIRT_BOX}
                                value={values.tShirt}
                                options={T_SHIRTS}
                                error={errors?.tShirt || null}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <div className={clsx('d-flex', 'gap-10')}>
                                            <img src={tShirt} />
                                            {!values.tShirt && <Typography variant='body2'>T-Shirt Size *</Typography>}
                                        </div>
                                    </InputAdornment>
                                }
                                onChange={(evt: SelectChangeEvent) => setFieldValue('tShirt', evt.target.value)}
                            />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <SelectField
                                id={DEVICE_PLATFORM}
                                value={values.device}
                                options={DEVICES}
                                error={errors?.device || null}
                                startAdornment={
                                    <InputAdornment position="start">
                                        <div className={clsx('d-flex', 'gap-10')}>
                                            <SmartphoneIcon fontSize='small' />
                                            {!values.device && <Typography variant='body2'>Device platform</Typography>}
                                        </div>
                                    </InputAdornment>
                                }
                                onChange={(evt: SelectChangeEvent) => setFieldValue('device', evt.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography gutter='label'>Would you like to wear a hat/visor during deliveries?</Typography>
                            <SelectField
                                id={HAT_BOX}
                                value={values.hatQuestionItem}
                                error={errors?.hatQuestionItem || null}
                                options={HAT}
                                onChange={(evt: SelectChangeEvent) => {
                                    setFieldValue('hatQuestionItem', evt.target.value)
                                    setFieldValue('hatQuestion', evt.target.value === 'none' ? 'false' : 'true')
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} rowSpacing={4} paddingTop={'5%'}>
                        <Grid item xs={12}>
                            <Typography gutter='label'>How did you hear about Medzoomer? *</Typography>
                            <SelectField
                                id={HEAR_ABOUT_BOX}
                                value={values.heardFrom as string | undefined}
                                error={errors?.heardFrom || null}
                                options={HEARD_FROM_OPTIONS}
                                onChange={(evt: SelectChangeEvent) => {
                                    setFieldValue('heardFrom', evt.target.value)
                                    setShowOtherField(evt.target.value === 'Other')
                                }
                                }
                            />
                        </Grid>
                        {showOtherField && (
                            <Grid item xs={12}>
                                <TextField
                                    name='heardFrom'
                                    label='Please, specify'
                                    value={otherHeardFromValue}
                                    error={errors?.heardFrom || null}
                                    onChange={(e) => setOtherHeardFromValue(e.target.value)}
                                />
                            </Grid>
                        )}
                        <Grid item xs={10}>
                            <Typography gutter='label'>Have you ever worked for another delivery service (Instacart, Uber Eats, etc)? *</Typography>
                            <RadioGroup
                                id={WORK_BEFORE_RADIO}
                                items={[]}
                                value={values.hasWorkedInOtherServices}
                                error={errors?.hasWorkedInOtherServices || null}
                                onChange={(e) => {
                                    const val = stringToBoolean(e.target.value)
                                    setFieldValue('hasWorkedInOtherServices', val)
                                }}
                            />
                            {values.hasWorkedInOtherServices && <SelectField
                                value={values.otherService as string | undefined}
                                error={errors?.otherService || null}
                                options={OTHER_DELIVERY_SERVICES}
                                placeholder='Select which one'
                                onChange={(evt: SelectChangeEvent) => setFieldValue('otherService', evt.target.value)}
                            />}
                        </Grid>
                        <Grid item xs={6}>
                            <Typography gutter='label'>Primary language</Typography>
                            <SelectField
                                id={PRIMARY_LANGUAGE}
                                value={values.primaryLanguage as string | undefined}
                                error={errors?.primaryLanguage || null}
                                options={LANGUAGES}
                                onChange={(evt: SelectChangeEvent) => setFieldValue('primaryLanguage', evt.target.value)}
                            />
                        </Grid>
                        {values.primaryLanguage !== '' && <Grid item xs={6}>
                            <Typography gutter='label'>Secondary language (Optional)</Typography>
                            <SelectField
                                value={values.secondaryLanguage as string | undefined}
                                error={errors?.secondaryLanguage || null}
                                options={LANGUAGES.filter((lang) => lang.value !== values.primaryLanguage)}
                                onChange={(evt: SelectChangeEvent) => setFieldValue('secondaryLanguage', evt.target.value)}
                            />
                        </Grid>}
                    </Grid>
                    <div className='flex-center'>
                        <Button
                            className={clsx('rounded-button', classes.continueButton)}
                            disabled={loading}
                            variant="contained"
                            color="secondary"
                            pending={loading}
                            type="submit"
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default DetailStep
