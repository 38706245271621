import Grid from '@mui/material/Grid'
import RestartAltIcon from '@mui/icons-material/RestartAlt'

import ModalDialog from '@/components/common/Modal'
import { Button } from '@/components/common/Buttons'
import { SelectField } from '@/components/common/Inputs'
import DayPicker from '@/components/common/Inputs/DayPicker'

import {
    TRANSACTIONS_FILTER_BY_DATES,
    TRANSACTION_STATUS_TYPES,
} from '@/constants/generics/transctions'
import { TransactionFilters } from '@/common/types'

import { useForm } from '@/hooks/useForm'
import { useScheme, getInitialFilterValues } from '../helpers'

interface Props {
    open: boolean
    title?: string
    text?: string
    pending?: boolean
    filters?: TransactionFilters
    handleApplyFilters: (_values: TransactionFilters) => void
    onClose: () => void
}

const FilterDialog = ({ open, filters, pending, handleApplyFilters, onClose }: Props) => {
    const schema = useScheme()

    const onSubmit = (values: TransactionFilters) => {
        handleApplyFilters(values)
        onClose()
    }

    const { handleSubmit, getFieldProps, values, resetForm, setFieldValue } = useForm(
        schema,
        getInitialFilterValues(filters || {}),
        onSubmit,
    )

    const onReset = () => {
        resetForm()
        handleApplyFilters({})
        onClose()
    }

    return (
        <ModalDialog
            open={open}
            title='Filters'
            secondaryActions={
                <Button variant='text' color='secondary' size='small' onClick={onReset}>
                    <RestartAltIcon fontSize='small' />
                    Reset
                </Button>
            }
            onClose={onClose}
        >
            <Grid container spacing={3} marginBottom={5}>
                <Grid item lg={6} md={6} xs={12}>
                    <SelectField
                        variant='outlined'
                        options={TRANSACTION_STATUS_TYPES}
                        label='Status'
                        multiple
                        handleDelete={(item) => {
                            const newValues = values && values.statuses.filter((value) => value !== item)
                            setFieldValue('statuses', newValues)
                        }}
                        {...getFieldProps('statuses')}
                    />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                    <SelectField
                        variant='outlined'
                        allowEmpty
                        options={TRANSACTIONS_FILTER_BY_DATES}
                        label='Filter By'
                        {...getFieldProps('dates.field')}
                    />
                </Grid>
                { values.dates.field &&
                <>
                    <Grid item xs={6}>
                        <DayPicker
                            label='From'
                            name='startDate'
                            value={values.dates.startDate}
                            onChange={(e) => setFieldValue('dates.startDate', e)}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DayPicker
                            label='To'
                            name='endDate'
                            value={values.dates.endDate}
                            onChange={(e) => setFieldValue('dates.endDate', e)}
                        />
                    </Grid>
                </>
                }
            </Grid>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} className='flex-center'>
                    <Button
                        disabled={pending}
                        pending={pending}
                        size='large'
                        variant="contained"
                        color='secondary'
                        onClick={handleSubmit}
                        id='applyButton'
                    >
                        Apply
                    </Button>
                </Grid>
            </Grid>
        </ModalDialog>
    )
}

export default FilterDialog