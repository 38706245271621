import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
    title: {
        color: 'gray',
        fontSize: '16px',
        marginBottom: '4px',
    },
    appImg: {
        maxHeight: '160px',
        maxWidth: '140px',
    },
    paper : {
        paddingLeft: '30px !important', 
        paddingRight: '30px !important',
    },
}))