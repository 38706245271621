import Grid from '@mui/material/Grid'
import { useStyles } from './styles'

import logo from '../../../assets/img/login-logo@3x.png'
import iconLogin from '../../../assets/icon/ill-med-delivery.svg'

import Footer from './Footer'

const AuthLayout = ({ children }: { children: React.ReactNode }) => {
    const { classes } = useStyles()

    return (
        <Grid container spacing={0} className={classes.height}>
            <Grid item xl={5} lg={5} md={5} sm={5} className={classes.imageBlock}>
                <img src={logo} alt="app-welcome-logo" className={classes.logoApp} />
                <div className={classes.authImage}>
                    <img src={iconLogin} alt="app-welcome-logo" className={classes.iconAuth} />
                </div>
                <div className={classes.footer}>
                    <Footer />
                </div>
            </Grid>
            <Grid className={classes.mobileHeader} item xs={12}>
                <img src={logo} alt="app-welcome-logo" className={classes.logoApp} />
            </Grid>
            <Grid item xl={7} lg={7} md={7} sm={7} xs={12} paddingInline={2}>
                {children}
            </Grid>

            {/* Mobile footer */}
            <Grid item display='flex' alignItems='flex-end'>
                <div className={classes.mobileFooter}>
                    <Footer />
                </div>
            </Grid>
        </Grid>
    )
}

export default AuthLayout
