import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@/components/generics/Paper'
import TableHead from '@/components/generics/Table/TableHead'
import noTransactions from '@/assets/icon/ico-delivery.svg'
import Item from '../../Earning/Components/TransactionList/Item'

import { SortDirectionTypes, TransactionList } from '@/common/types'
import { TRANSACTION_TABLE_HEADERS } from '../../helpers'
import Typography from '@mui/material/Typography'
import { useStyles } from '../styles'

interface Props extends TransactionList {
    orderBy: string,
    sortDirection: SortDirectionTypes,
    setSortBy: (_sortBy: string) => void
    setSortDirection: (_sortDirection: SortDirectionTypes) => void
}

const List = ({ records, orderBy, sortDirection, setSortBy, setSortDirection }: Props) => {
    const { classes } = useStyles()
    return (
        <>
            { records && Array.isArray(records) ? (
                <TableContainer component={Paper} radius={10} px={2} py={1}>
                    <Table sx={{ mb: 0, borderSpacing: 20, borderCollapse: 'inherit' }} aria-label="simple table">
                        <TableHead
                            headers={TRANSACTION_TABLE_HEADERS}
                            orderBy={orderBy}
                            sortDirection={sortDirection}
                            setSortBy={setSortBy}
                            setSortDirection={setSortDirection}
                        />
                        <TableBody>
                            {records.map((item) => (
                                <Item key={item.id} item={item} />
                            ))}
                        </TableBody>
                    </Table>
                    {!records.length && (
                        <div
                            className={classes.paper}
                            style={{ height: 'calc(100% - 99px - 32px - 127px)' }}
                        >
                            <div className={classes.noTransactions}>
                                <img src={noTransactions} />
                                <Typography className={classes.title}>You have not made transactions yet</Typography>
                                <Typography className={classes.subtitle}>
                                    All the information on completed transactions will appear here
                                </Typography>
                            </div>
                        </div>
                    )}
                </TableContainer>
            ) : (
                <div>No data</div>
            )}
        </>
    )
}

export default List