import dayjsSetup from './dayjs-setup'
import dayjs from 'dayjs'
import { Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(timezone)

const hourToDate = (hour: string) => {
    const [h, m] = hour.split(':')
    return dayjs().hour(Number(h)).minute(Number(m)).format('hh:mm A').toString()
}

const formatDate = (date: Dayjs | Date | string, format = 'YYYY-DD-MM HH:mm:ss', isUtc = false) => {
    if (!date) return ''
    return isUtc ? dayjs.utc(date).format(format) : dayjsSetup(new Date(date as string)).format(format)
}

const formatYearsOld = (date: Dayjs | Date | string | null) => {
    if (!date) return ''
    return dayjs().diff(date, 'year') + ' years old'
}

const generateDateRange = (interval: string) => {
    const endTime = dayjs()
    const dateMap: { [key: string]: number } = {
        today: 0,
        last_seven_days: 7,
        last_thirty_days: 30,
        year_to_date: 0,
        all: 0,
    }

    let startTime
    if (interval === 'year_to_date') {
        startTime = dayjs().startOf('year')
    } else {
        startTime = dayjs().subtract(dateMap[interval], 'day').startOf('day')
    }

    const dateRange = {
        startTime: startTime.format('YYYY-MM-DDTHH:mm:ss[Z]'),
        endTime: endTime.format('YYYY-MM-DDTHH:mm:ss[Z]'),
    }

    return dateRange
}

export {
    formatDate,
    hourToDate,
    formatYearsOld,
    generateDateRange,
}