import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import { OrdersListItem } from '@/common/types'
import Typography from '@/components/common/Typography'
import { formatDate } from '@/utils/dates'
import { formatCurrency, formatDistance, getOrderStatus } from '@/utils'
import { Box } from '@mui/system'
import StatusIcon from '@/components/generics/StatusIcon'
import { useAuth } from '@/hooks/useAuth'
import { CourierType } from '@/common/enums'

interface Props {
    item: OrdersListItem
}
const Item = ({ item }: Props) => {
    const { user } = useAuth()

    return (
        <TableRow key={item.id}>
            <TableCell align='center'>
                <Box >
                    <Typography variant='body2' color="hint">{formatDate(item.orders[0]?.createdAt, 'MMM DD, YYYY', true)}</Typography>
                </Box>
            </TableCell>
            <TableCell align='center'>
                <Typography variant='body2' color="hint">{formatDate(item.orders[0]?.createdAt, 'hh:mm A', true)}</Typography>
            </TableCell>
            <TableCell align='center'>
                <Typography variant='body2' color="hint">{item.orders[0]?.orderNumber}</Typography>
            </TableCell>
            <TableCell align='center'>
                <StatusIcon justify='center' status={item.orders[0]?.status ? getOrderStatus(item.orders[0]?.status) : ''} />
            </TableCell>
            <TableCell align='center'>
                <Typography variant='body2' color="hint">{item.totalDistance ? formatDistance(item.totalDistance) : '-'}</Typography>
            </TableCell>
            {user?.courierType === CourierType.MZ_1099 &&
                <TableCell align='center'>
                    <Typography weight='bold' variant='body2' color='#4cc71d'>{formatCurrency(item?.totalCourierPrice ? item.totalCourierPrice : 0)}</Typography>
                </TableCell>
            }
        </TableRow>
    )
}

export default Item