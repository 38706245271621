import { makeStyles } from 'tss-react/mui'
import { DRAWER_WIDTH } from '@/constants'

export const useStyles = makeStyles()((theme) => ({
    drawer: {
        width: DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            position: 'absolute',
            flexShrink: 1,
        },
    },
    spacing: {
        padding: '0 15px',
    },
    paper: {
        background: '#1c2127',
    },
    appBar: {
        background: '#1c2127',
        justifyContent: 'space-between',
    },
    image: {
        width: 40,
        height: 40,
        borderRadius: '100%',
    },
    userBox: {
        borderRadius: 3,
        maxWidth: DRAWER_WIDTH,
        '&:hover': {
            backgroundColor: 'rgba(226,28,64,.6)',
            cursor: 'pointer',
        },
    },
    toolbarWrapper: {
        '& .MuiToolbar-gutters': {
            paddingLeft: 10,
            paddingRight: 10,
        },
    },
    containerLink: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        gap: 20,
        paddingBlock: 3,
        color: 'white',
    },
    menuItem: {
        paddingBlock: 6,
        borderRadius: 3,
        '&:hover': {
            backgroundColor: 'rgba(226,28,64,.6)',
        },
    },
    subMenuItem: {
        borderRadius: 50,
        paddingBlock: 6,
        marginBottom: 6,
        '&:hover': {
            paddingBlock: 6,
            backgroundColor: 'rgba(226,28,64,.6)',
        },
    },
    menuNestedItem: {
        paddingBlock: 6,
        borderRadius: 9,
        color: 'rgba(176,197,217,.6)',
        paddingLeft: 25,
        '&:hover': {
            backgroundColor: 'rgba(226,28,64,.6)',
        },
    },
    menuItemLink: {
        fontSize: 15,
        color: '#fff',
    },
    activeItem: {
        borderRadius: 3,
        backgroundColor: 'rgba(226,28,64,.6)',
    },
    activeSubMenuItem: {
        borderRadius: 50,
        backgroundColor: 'rgba(226,28,64,.6)',
    },
    containerCollapseSidebar: {
        backgroundColor: '#262c33',
        color: '#fff',
        position: 'sticky',
        bottom: 0,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        padding: '0.8em 1.2em',
        widows: '100%',
        marginTop: 'auto',
    },
}))