import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '1em',
        backgroundColor: '#fff2d0',
        borderRadius: '20px',
        color: theme.palette.common.black,
    },
}))
