import clsx from 'clsx'
import Typography from '@/components/common/Typography'
import { Button } from '@/components/common/Buttons'
import { API_SIGNED_FILE_URL, API_SIGN_UP } from '@/constants/routes'
import { useStyles } from './styles'
import { SignUpComponentsProps } from '../../types'
import useCrud from '@/hooks/useCrud'
import { Box } from '@mui/material'
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined'
import { useEffect, useRef, useState } from 'react'
import { uploadFilesToS3 } from '@/utils'
import { FileType } from '@/common/enums'
import useToast from '@/hooks/useToast'

const TakeSelfieStep = (props: SignUpComponentsProps) => {
    const { classes } = useStyles()
    const [apiRequest] = useCrud('POST')

    const [picture, setPicture] = useState<File | null>(null)
    const [loading, setLoading] = useState(false)
    const [isOpenCamera, setIsOpenCamera] = useState(true)
    const videoRef = useRef<HTMLVideoElement>(null)
    const canvasRef = useRef<HTMLCanvasElement>(null)
    const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
    const [, toastError] = useToast()

    const onSubmit = async () => {
        try {
            if (!picture) return
            setLoading(true)

            const { uploadUri, uri } = await apiRequest(API_SIGNED_FILE_URL, {
                extension: picture.type.split('/')[1],
                type: FileType.COURIER_SELFIE,
            })

            await uploadFilesToS3([{ file: picture, uploadUri }])
            const response = await apiRequest(API_SIGN_UP.SET_SELFIE_PICTURE, { selfiePicture: uri })
            if (response) {
                props.nextStep(response.registrationStep)
            }
            setLoading(false)
        } catch (error) {
            console.error(error)
            toastError(error.message)
            setLoading(false)
        }
    }

    const startCamera = async () => {
        try {
            setLoading(true)
            const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'user' } })

            setIsOpenCamera(true)
            setMediaStream(stream)
        } catch (error) {
            console.error('The camera could not be accessed', error)
        } finally {
            setLoading(false)
        }
    }

    const takePhoto = () => {
        if (mediaStream && videoRef.current && canvasRef.current) {
            const context = canvasRef.current.getContext('2d')
            if (context) {
                context.drawImage(videoRef.current, 0, 0, canvasRef.current.width, canvasRef.current.height)
                canvasRef.current.toBlob((blob) => {
                    if (blob) {
                        const photoFile = new File([blob], 'photo.jpg', { type: 'image/jpeg' })
                        setPicture(photoFile)
                        setIsOpenCamera(false)
                        mediaStream.getTracks().forEach((track) => {
                            track.stop()
                        })
                    }
                }, 'image/jpeg', 0.9)
            }
        }
    }

    const retakePhoto = async () => {
        setPicture(null)
        await startCamera()
    }

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.srcObject = mediaStream
        }
    }, [mediaStream])

    return (
        <Box className={classes.takeSelfieContainer}>
            <Box textAlign='center'>
                <Typography variant='h4' weight='500' gutter='label'>Identification Photo</Typography>
                <Typography variant='body1'>
                    Please take a quick selfie, this just ensures that. <br />
                    it&apos;s really you filling out this application.
                </Typography>
            </Box>

            <Box display='flex' flexDirection='column' alignItems='center' gap={3}>
                {isOpenCamera && mediaStream ? (
                    <Box sx={isOpenCamera && mediaStream ? { width: '300px', height: '300px', overflow: 'hidden', borderRadius: '50%', position: 'relative' } : {}}>
                        <video ref={videoRef} autoPlay playsInline muted style={isOpenCamera && mediaStream ? { width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' } : {}} />
                    </Box>
                ) : (
                    picture &&
                    <Box textAlign='center'>
                        <div className={classes.pictureContainer}>
                            <img src={URL.createObjectURL(picture)} />
                        </div>
                        <Button variant='text' color='secondary' onClick={retakePhoto}>Retake Photo</Button>
                    </Box>
                )}
                <canvas ref={canvasRef} style={{ display: 'none' }} width={1080} height={1080} />

                {!mediaStream &&
                    <Button
                        id='open-camera-button'
                        variant="outlined"
                        color="secondary"
                        size='large'
                        sx={{ display: 'flex', gap: 1, paddingX: 2.5, paddingY: 1.5, borderRadius: 2 }}
                        pending={loading}
                        onClick={startCamera}
                    >
                        <CameraAltOutlinedIcon />
                        Take Picture
                    </Button>
                }

                {isOpenCamera && mediaStream &&
                    <Button
                        id='take-selfie-button'
                        variant="outlined"
                        color="secondary"
                        size='large'
                        sx={{ paddingX: 2.5, paddingY: 1.5, borderRadius: 2 }}
                        onClick={takePhoto}
                    >
                        <Typography color='secondary'>Take Selfie</Typography>
                    </Button>
                }

                {picture &&
                    <div className='flex-center'>
                        <Button
                            id="continue-selfie-button-step"
                            className={clsx('rounded-button')}
                            sx={{ paddingX: 5, paddingY: 1.5 }}
                            variant="contained"
                            color="secondary"
                            type="submit"
                            pending={loading}
                            disabled={loading}
                            onClick={onSubmit}
                        >
                            Continue
                        </Button>
                    </div>
                }
            </Box>
        </Box>
    )
}

export default TakeSelfieStep