import VideoPlayer from '../../../VideoPlayer'

import { useStyles } from '../../styles'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { Steps } from '@/common/types'
import clsx from 'clsx'

const VideoStep = ({ data }: { data: Steps }) => {
    if (!data) return null
    const { classes } = useStyles()

    return (
        <Box sx={{ backgroundColor: 'white' }}>
            <div className={classes.modal}>
                <div className={classes.body}>
                    <div className={clsx('text-center', 'w-100')}>
                        <Typography className={classes.title}>
                            {data.title}
                        </Typography>
                        <Typography className={classes.desc}>
                            {data.desc}
                        </Typography>
                        <div className={classes.video}>
                            <VideoPlayer
                                url={data.url ? data.url : ''}
                                controls={true}
                                onEnded={() => console.log('ended')}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </Box>
    )
}

export default VideoStep
