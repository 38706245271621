import { SelectField, TextField } from '@/components/common/Inputs'
import Modal from '@/components/common/Modal'
import { Button } from '@/components/common/Buttons'
import Grid from '@mui/material/Grid'
import { getInitialValues, useScheme } from './validations'
import { RequestState, useForm } from '@/hooks'
import { BANK_ACCOUNT, BankAccountValues } from './types'
import { SelectChangeEvent } from '@mui/material'
import Typography from '@/components/common/Typography'
import clsx from 'clsx'
import { useStyles } from '../../styles'

interface Props {
    open: boolean
    apiState: RequestState
    onClose: () => void
    onSubmit: (_values: BankAccountValues) => Promise<void>
}

const LinkAccountDialog = ({ open, apiState, onClose, onSubmit }: Props) => {
    const schema = useScheme()
    const { classes } = useStyles()

    const { getFieldProps, values, errors, setFieldValue, handleSubmit } = useForm(
        schema,
        getInitialValues(),
        onSubmit,
    )
    
    return (
        <Modal
            open={open}
            onClose={onClose}
        >

            <div className={clsx('text-center', 'w-100')}>
                <Typography gutter='bottomSpace' variant='h5' >
                    Link Bank Account
                </Typography>
            </div>
            <div className={clsx('flex-center', 'flex-column', 'w-100', 'height-100')}>
                <form action="#" onSubmit={handleSubmit}>
                    <Grid container spacing={2} rowSpacing={3}>
                        <Grid item lg={6} md={6} xs={12}>
                            <TextField
                                className={classes.textField}
                                label='Routing Number *'
                                {...getFieldProps('routingNumber')}
                            />
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                            <TextField
                                className={classes.textField}
                                label='Account Number *'
                                {...getFieldProps('accountNumber')}
                            />
                        </Grid>

                        <Grid item lg={6} md={6} xs={12}>
                            <SelectField
                                id={'bankAccountType'}
                                value={values.bankAccountType}
                                variant='outlined'
                                label='Bank Account Type *'
                                options={BANK_ACCOUNT}
                                error={errors?.bankAccountType || null}
                                onChange={(evt: SelectChangeEvent) => setFieldValue('bankAccountType', evt.target.value)}
                            />
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                            <TextField
                                className={classes.textField}
                                label='Name *'
                                {...getFieldProps('name')}
                            />
                        </Grid>
                        <Grid item xs={12} className='flex-center'>
                            <Button
                                className={clsx('rounded-button')}
                                color='primary'
                                type='submit'
                                size='large'
                                pending={apiState.loading}
                                disabled={apiState.loading}
                            >
                                Link Account
                            </Button>
                        </Grid>
                        <Grid item xs={12} className='flex-center'>
                            <Button
                                color='info'
                                variant='text'
                                size='small'
                                onClick={onClose}
                                disabled={apiState.loading}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </form>
            </div>
        </Modal>
    )
}

export default LinkAccountDialog