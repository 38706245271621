import clsx from 'clsx'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import { TimePicker } from '@/components/common/Inputs'
import { Button } from '@/components/common/Buttons'
import Typography from '@/components/common/Typography'

import { useForm } from '@/hooks'
import { getInitialValues, useScheme } from './helpers'
import { DayEnum } from '@/common/enums'
import { formatDate } from '@/utils/dates'
import { testProp } from '@/constants/testIds'
import { PickupTime, TimeType } from '@/common/types'
import { DAYS_OF_WEEK_TO_NUMBER } from '@/constants'

interface Props {
    error?: string
    apply: (_values: PickupTime) => void
}

const FormPickupTime = ({ error, apply }: Props) => {
    const schema = useScheme()

    const onSubmit = (values: Partial<PickupTime>) => {
        apply({
            id: '',
            isDefault: false,
            days: values.days || [],
            pickupTime: formatDate(values.pickupTime || '', 'HH:mm:ss'),
        })
    }

    const { errors, values, getFieldProps, setFieldValue, validateForm } = useForm<Partial<PickupTime>>(
        schema,
        getInitialValues(),
        onSubmit,
    )

    const savePickupTime = async () => {
        const formErrors = await validateForm()
        if (Object.keys(formErrors).length === 0 || (error && !formErrors.pickupTime)) {
            onSubmit(values)
        }
    }

    return (
        <Grid container spacing={4} {...testProp('addNewPickupTime')}>
            <Grid item xs={12} display='flex' flexDirection='column' alignItems='center'>
                <Typography variant='body1' gutter='label' weight='500'>Choose pickup days</Typography>
                <FormControl>
                    <FormGroup row className={clsx('flex-center')}>
                        {Object.values(DayEnum).filter(i => i !== DayEnum.Sunday).map((item) => (
                            <FormControlLabel
                                key={item}
                                label={item}
                                control={
                                    <Checkbox
                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                        checked={values.days?.find(i => i.day === item) ? true : false}
                                        onChange={() => setFieldValue('days', values.days?.find(i => i.day === item) ? values.days?.filter(i => i.day !== item) : [...(values.days || []), { day: item, dayNumber: DAYS_OF_WEEK_TO_NUMBER[item] }])}
                                        color='secondary'
                                    />
                                }
                            />
                        ))}
                    </FormGroup>
                    {errors?.days &&
                        <Typography color="error" variant="caption" className='text-center'>
                            Select at least one option
                        </Typography>
                    }
                </FormControl>
            </Grid>

            <Grid item xs={12} display='flex' flexDirection='column' alignItems='center' marginBottom={1}>
                <TimePicker
                    label='Enter pickup time'
                    value={values.pickupTime as TimeType}
                    onChange={(value) => setFieldValue('pickupTime', value)}
                    {...testProp('pickupTime', true)}
                />
                {(error || errors.pickupTime) && <Typography color="error" variant="caption">{errors.pickupTime || error}</Typography>}
            </Grid>

            <Grid item xs={12} className='flex-center'>
                <Button
                    color='secondary'
                    size='large'
                    onClick={savePickupTime}
                >
                    Apply
                </Button>
            </Grid>
        </Grid>
    )
}

export default FormPickupTime