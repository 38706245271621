import { shallow } from 'zustand/shallow'
import AppBar from '@/components/layouts/AppLayout/AppBar'
import AppBarContent from '@/components/generics/AppBarContent'
import Loader from '@/components/common/Loader'
import Pagination from '@/components/generics/Pagination'
import useListInit from '@/hooks/useListInit'
import { OrderList } from '@/common/types'
import { API_DELIVERIES } from '@/constants/routes'
import { getPaginationCount } from '@/utils'
import List from './Components/List'
import FilterDialog from './Components/FilterDialog'
import { FiltersType } from '../helpers'
import { useBooleanHandlers } from '@/hooks/useBooleanHandlers'
import { useDeliveriesStore } from '@/store/deliveries'

const SORT_BY_ACTIVE = 'created_at'

export const Delivery = () => {
    const { search, filters, setFilters: setStoreFilters } = useDeliveriesStore((state) => state, shallow)
    const [openFilters, setOpenFilters, setCloseFilters] = useBooleanHandlers(false)
    const { 
        data,
        isLoading,
        page,
        orderBy,
        setSearch,
        nextPage,
        prevPage,
        setFilter,
    } = useListInit<OrderList>({
        endpoint: API_DELIVERIES.GET_ORDERS,
        sortActive: SORT_BY_ACTIVE,
        setStoreFilters,
    })

    return (
        <div>
            <AppBar>
                <AppBarContent
                    showExport={false}
                    showAdd={false}
                    title='Order History'
                    pagination={
                        <Pagination
                            page={page}
                            count={getPaginationCount(data)}
                            pending={isLoading}
                            onNextPage={nextPage}
                            onPrevPage={prevPage}
                        />
                    }
                    filters={filters}
                    searchValue={search}
                    showFilters={true}
                    openFilterDialog={setOpenFilters}
                    onSubmitSearch={setSearch}
                />
            </AppBar>

            <FilterDialog
                filters={filters}
                open={openFilters}
                onClose={setCloseFilters}
                handleApplyFilters={(values: FiltersType) => setFilter(values)}
            />

            <div className='page-content'>
                <Loader open={isLoading} />
                {data && data.records &&
                <List
                    {...data}
                    orderBy={orderBy}
                />
                }
            </div>
        </div>
    )
}