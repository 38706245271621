import * as Yup from 'yup'

export const initialValues = {
    email: '',
    password: '',
    name: '',
    lastName: '',
    phone: '',
    address: {
        number: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    },
    apartment: '',
    queryString: '',
}

export const getInitialValues = () => {
    return Object.assign(
        {},
        initialValues,
    )
}

export const useScheme = () => {
    return Yup.object().shape({
        email: Yup.string().email().required('Email is required'),
        password: Yup.string().required('New password is required')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            ),
        name: Yup.string().matches(/^[A-Za-z ]+$/, 'Enter only letters and spaces').required('First name is required'),
        lastName: Yup.string().matches(/^[A-Za-z ]+$/, 'Enter only letters and spaces').required('Last name is required'),
        address: Yup.object().shape({
            street: Yup.string().required('Street is required'),
            city: Yup.string().required('City is required'),
            state: Yup.string().required('State is required'),
            zip: Yup.string().required('Zip is required'),
            number: Yup.string().required('Number is required'),
            country: Yup.string().required('Country is required'),
        }).required('Address is required'),
        phone: Yup.string().required('Phone number is required').matches(/^[0-9]+$/, 'Phone number must be only digits'),
    })
}
