import Typography from '@/components/common/Typography'
import { useStyles } from './styles'
import { useSettingsStore } from '@/store'
import { useEffect, useState } from 'react'

export const TermsConditions = () => {
    const { classes } = useStyles()
    const { settings } = useSettingsStore(state => state)
    const [terms, setTerms] = useState<string>('')

    useEffect(() => {
        if (settings) {
            const terms = settings.find(item => item.key === 'terms')
            setTerms(terms?.value || '')
        }
    }, [settings])

    return (
        <div className={classes.termsWrapper}>
            <div className={classes.textWrapper} dangerouslySetInnerHTML={{ __html: terms }} />
            <div className={classes.footer}>
                <Typography className={classes.title}>© Copyright {new Date().getFullYear()} MedZoomer</Typography>
                <Typography className={classes.title}>Terms and Conditions</Typography>
            </div>
        </div>
    )
}
