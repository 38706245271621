import { useState } from 'react'
import clsx from 'clsx'
import InputAdornment from '@mui/material/InputAdornment'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'

import AuthLayout from '@/components/layouts/AuthLayout'
import Typography from '@/components/common/Typography'
import { TextField } from '@/components/common/Inputs'
import { Button, RouterLink } from '@/components/common/Buttons'
import ErrorAuthMessage from '../../../components/Error'
import SuccessSent from '../SuccessSent'

import { getInitialValues, useScheme } from './validations'
import { useForm } from '@/hooks/useForm'

import { sendVerificationCode, validateResetPassword } from '@/services/amplify'

import styles from '../../styles.module.css'

const ResetPasswordForm = () => {
    const schema = useScheme()
    const [error, setError] = useState('')
    const [pending, setPending] = useState(false)
    const [success, setSuccess] = useState(false)

    const onSubmit = async ({ email }: { email: string }) => {
        setError('')
        setPending(true)
        try {
            await sendVerificationCode(email.toLowerCase())
            setSuccess(true)
        } catch (e) {
            try {
                const validate = await validateResetPassword(email)
                if (validate) {
                    if (validate.success) {
                        return setSuccess(true)
                    }
                    setError(validate.code)
                    return
                }
            } catch {
                setError(e.code || e.message || '')
                return
            }
            setError(e.code || e.message || '')
        } finally {
            setPending(false)
        }
    }

    const { getFieldProps, handleSubmit, values } = useForm(
        schema,
        getInitialValues(),
        onSubmit,
    )

    return (
        <AuthLayout>
            <div className={styles.mainContent}>
                <div className={clsx('flex-center', 'flex-column', 'w-100', 'height-100')}>
                    {success && <SuccessSent onResend={onSubmit} pending={pending} email={values.email} />}

                    {!success &&
                        <>
                            <Typography variant="h3" className={styles.title} align='center'>Reset Password</Typography>
                            <Typography variant="body1" color='placeholder' align='center'>
                                Please enter an email with which you registered
                            </Typography>
                            <form action="#" onSubmit={handleSubmit} className={clsx('w-100', 'd-flex', 'flex-column', 'align-center')}>
                                <div className={styles.inputWrapper}>
                                    <TextField
                                        className={styles.textField}
                                        placeholder="Email"
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <EmailOutlinedIcon />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant='standard'
                                        {...getFieldProps('email')}
                                    />
                                    {error && <ErrorAuthMessage error={error} className={styles.errorText} />}
                                </div>
                                <Button
                                    disabled={pending}
                                    variant="contained"
                                    pending={pending}
                                    type="submit"
                                    color='secondary'
                                    className='rounded-button'
                                    size='large'
                                >
                                    Send request
                                </Button>
                            </form>
                        </>
                    }
                </div>
                <div className='authFooter'>
                    <Typography variant='body1'>Back to</Typography>
                    <RouterLink to={'/'}>
                        Sign In
                    </RouterLink>
                </div>
            </div>
        </AuthLayout>
    )
}

export default ResetPasswordForm
