import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
    root: {
        overflow: 'hidden',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'row',
    },
    content: {
        minHeight: '100%',
        width: '100%',
        position: 'relative',
    },
    mobileContent: {
        minHeight: '100%',
        width: '100%',
        position: 'relative',
        paddingTop: 64,
    },
}))