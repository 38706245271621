import { useEffect, useState } from 'react'

import Modal from '../../common/Modal'
import DocumentsStep from '@/pages/Auth/SignUp/components/DocumentsStep'
import Fw9Form from '@/pages/Auth/SignUp/components/Fw9Step'
import { API_COURIER } from '@/constants/routes'

import { CourierRegistrationStatus, CourierRegistrationSteps, CourierStatus, CourierType } from '@/common/enums'
import { useAuth } from '@/hooks/useAuth'
import { useBooleanHandlers } from '@/hooks/useBooleanHandlers'

const SignAgreements = () => {
    const { user } = useAuth()
    const [openModal, setOpenModal, setCloseModal] = useBooleanHandlers()
    const [step, setStep] = useState<CourierRegistrationSteps | null>(null)

    useEffect(() => {
        if (user) {
            const missingAgreements = user.courierType === CourierType.MZ_1099 ? (!user.fleetAgreement || !user.fw9Agreement) || (!user.fleetAgreement?.file || !user.fw9Agreement?.file) : !user.fleetAgreement || !user.fleetAgreement.file
            const missingFw9TaxInformation = !user.fw9Information || !user.taxClassification
            if ((user.registrationStatus === CourierRegistrationStatus.SIGNATURE_PENDING && missingAgreements) || (user.status === CourierStatus.ACTIVE && missingAgreements)) {
                user.courierType !== CourierType.W2 && setOpenModal()
                if (missingFw9TaxInformation && user.courierType === CourierType.MZ_1099) {
                    setStep(CourierRegistrationSteps.FW9_TAX)
                } else {
                    setStep(CourierRegistrationSteps.SIGNATURES)
                }
            }
        }
    }, [user])

    return (
        <Modal
            open={openModal}
            maxWidth={'md'}
            center
            onClose={setCloseModal}
        >
            {step === CourierRegistrationSteps.FW9_TAX && <Fw9Form apiEndpointStep={API_COURIER.SET_FW9_AND_TAX_INFORMATION} nextStep={() => setStep(CourierRegistrationSteps.SIGNATURES)} />}
            {step === CourierRegistrationSteps.SIGNATURES && <DocumentsStep executeRequest={false} nextStep={() => {
                setCloseModal()
                setStep(null)
            }} />}
        </Modal>
    )
}

export default SignAgreements