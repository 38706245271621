import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
    mainContent: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'inherit',
    },
    inputWrapper: {
        display: 'flex',
        maxWidth: 700,
        padding: '35px 0',
        flexWrap: 'wrap',
    },
    textField: {
        maxWidth: '350px',
        '& input': {
            padding: '20px 14px',
        },
        backgroundColor: '#fff',
    },
    errorText: {
        marginTop: '25px !important',
    },
}))
