import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContextType } from '@/common/types/types'

import { useAppContext } from '@/contexts/AppContext'

import { signOut } from '@/services/amplify'
import { ROUTE_ROOT } from '@/constants/routes'

import * as Sentry from '@sentry/react'

export default function useLogout() {
    const navigate = useNavigate()
    const application = useAppContext() as AppContextType

    return useCallback(async () => {
        await signOut()
        application.setUser(null)
        Sentry.setUser(null)
        navigate(ROUTE_ROOT.path)
    }, [history, application])
}
