import Grid from '@mui/material/Grid'
import Paper from '@/components/generics/Paper'
import Typography from '@/components/common/Typography'
import Box from '@mui/material/Box'
import { useBooleanHandlers } from '@/hooks/useBooleanHandlers'
import { UnlinkedBankAccount } from './Components/UnlinkedBankAccount'
import { useAuth } from '@/hooks/useAuth'
import useCrud from '@/hooks/useCrud'
import { API_BANK_ACCOUNT } from '@/constants/routes'
import { useStyles } from './styles'
import LinkAccountDialog from './Components/LinkBankAccount/LinkAccountDialog'
import { BankAccountValues } from './Components/LinkBankAccount/types'
import { LinkedBankAccount } from './Components/LinkedBankAccount'
import CreateWithdrawDialog from './Components/CreateWithdraw/CreateWithdrawDialog'
import { WithdrawValues } from './Components/CreateWithdraw/types'
import UpdateAccountDialog from './Components/UpdateBankAccount/UpdateAccountDialog'
import ConfirmDialog from '@/components/generics/ConfirmDialog'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'

export const Payment = () => {
    const { classes } = useStyles()
    const [openModal, setOpenModal, setCloseModal] = useBooleanHandlers()
    const [openUpdateModal, setOpenUpdateModal, setCloseUpdateModal] = useBooleanHandlers()
    const [openRemove, setOpenRemove, setCloseRemove] = useBooleanHandlers()
    const [openModalWithdraw, setOpenModalWithdraw, setCloseModalWithdraw] = useBooleanHandlers()
    const [apiRequest, apiState] = useCrud('POST')
    const [apiRequestUpdate, apiStateUpdate] = useCrud('PUT')
    const [ApiRequestDelete, apiDeleteState] = useCrud('DELETE')
    const { user, setUser } = useAuth()
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })

    const handleLinkAccount = async (values: BankAccountValues) => {
        try {
            const bankAccount = await apiRequest(API_BANK_ACCOUNT.BANK, values)
            if (user){
                const newUser = { ...user, dwollaInformation: bankAccount }
                setUser(newUser)
            }
            setCloseModal()
        } catch (error) {
            console.error(error)
        }
    }
    const handleCreateWithdraw = async (values: WithdrawValues) => {
        try {
            const withdraw = await apiRequest(API_BANK_ACCOUNT.CREATE_WITHDRAW, values)
            if (user){
                const newUser = { ...user, balance: withdraw.balanceAfterTransaction }
                setUser(newUser)
            }
            setCloseModalWithdraw()
        } catch (error) {
            console.error(error)
        }
    }
    const handleUpdateLinkAccountModal = async (values: BankAccountValues) => {
        try {
            const bankAccount = await apiRequestUpdate(API_BANK_ACCOUNT.BANK, values)
            if (user){
                const newUser = { ...user, dwollaInformation: bankAccount }
                setUser(newUser)
            }
            setCloseUpdateModal()
        } catch (error) {
            console.error(error)
        }
    }
    const handleRemoveCard = async () => {
        try {
            const deletedBankAccount = await ApiRequestDelete(API_BANK_ACCOUNT.BANK)
            if (user){
                const newUser = { ...user, dwollaInformation: deletedBankAccount }
                setUser(newUser)
            }
            setCloseRemove()
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <div className='page-content'>
            <Grid item xs={12}>
                <Paper px={isSmallScreen?2:10} py={1}>
                    <Box sx={{ backgroundColor: 'white', marginTop: '15px' }}>
                        <Grid container spacing={3} flexDirection={'column'}>
                            <Grid item lg={12} xs={12}>
                                <Typography className='text-left' gutter='bottomSpace' variant={isSmallScreen?'h5':'h4'} >
                                    Payment Options
                                </Typography>
                                <Typography className='text-left' gutter='bottomSpace' variant='body2' >
                                    You need to add payment information, in order to be able to receive payment for delivery services.
                                </Typography>
                            </Grid>

                            <div className={classes.inputWrapper}>
                                {user && !user.dwollaInformation?.routingNumber ? 
                                    <UnlinkedBankAccount setOpenModal={setOpenModal}/>
                                    :
                                    <LinkedBankAccount courier={user} setOpenUpdateModal={setOpenUpdateModal} setOpenModalWithdraw={setOpenModalWithdraw} handleRemoveCard={setOpenRemove}/>
                                }
                            </div>
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
            <LinkAccountDialog
                open={openModal}
                onClose={setCloseModal}
                onSubmit={handleLinkAccount}
                apiState={apiState}
            />
            <UpdateAccountDialog
                open={openUpdateModal}
                dwollaInformation={user?.dwollaInformation}
                onClose={setCloseUpdateModal}
                onSubmit={handleUpdateLinkAccountModal}
                apiState={apiStateUpdate}
            />
            <CreateWithdrawDialog
                open={openModalWithdraw}
                onClose={setCloseModalWithdraw}
                onSubmit={handleCreateWithdraw}
                apiState={apiState}
            />
            <ConfirmDialog
                open={openRemove}
                title={'Remove Bank Account?'}
                text={'Are you sure you want delete the bank account?'}
                pending={apiDeleteState.loading}
                onClose={setCloseRemove}
                onConfirm={handleRemoveCard}
            />
        </div>
    )
}