import Grid from '@mui/material/Grid'
import ModalDialog from '@/components/common/Modal'
import { Button } from '@/components/common/Buttons'
import Typography from '@/components/common/Typography'

interface Props {
    open: boolean
    title?: string
    text?: string
    pending?: boolean
    textConfirm?: string
    center?: boolean
    onClose: () => void
    onConfirm?: () => void
}

const ConfirmDialog = ({ open, title, text, pending, center = false, textConfirm = 'Yes, delete', onClose, onConfirm }: Props) => {
    return (
        <ModalDialog
            open={open}
            center={center}
            title={title}
            onClose={onClose}
        >
            {text && (
                <Typography align="center" gutter="bottomSpace">
                    {text || 'Are you sure you want to delete this item?'}
                </Typography>
            )}
            <Grid container spacing={2} justifyContent="center">
                <Grid item>
                    <Button id={'Cancel'} disabled={pending} variant="outlined" color="primary" onClick={onClose}>
                        Cancel
                    </Button>
                </Grid>
                <Grid item>
                    <Button id={'Confirm'} disabled={pending} pending={pending} variant="contained" color='secondary' onClick={onConfirm}>
                        {textConfirm}
                    </Button>
                </Grid>
            </Grid>
        </ModalDialog>
    )
}

export default ConfirmDialog