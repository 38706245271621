import Typography from '@/components/common/Typography'
import { Button } from '@/components/common/Buttons'
import { IconSuccess } from '@/components/svg/Icons/IconSuccess'

import styles from '../../styles.module.css'

const SuccessReset = () => {
    return (
        <div className={styles.successReset}>
            <IconSuccess />
            <div>
                <Typography variant="h3" align='center'>Password changed</Typography>
                <Typography align='center' variant='body2' color='placeholder' className={styles.subtitle}>
                    Now you can use the new password to Sign In
                </Typography>
            </div>

            <Button
                variant="outlined"
                color="info"
                to='/'
            >
                Go to sign in
            </Button>
        </div>
    )
}

export default SuccessReset