import clsx from 'clsx'
import { styled, Theme, CSSObject } from '@mui/material/styles'
import List from '@mui/material/List'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'

import AppLogo from './components/Logo'
import Avatar from '@/components/common/Avatar'
import MenuItem from './components/MenuItem'

import { DRAWER_WIDTH, APP_MENU } from '@/constants'

import useLogout from '@/hooks/useLogout'
import { useAuth } from '@/hooks/useAuth'

import { IconLogout } from '@/components/svg/Icons/Menu/IconLogout'

import { useBooleanHandlers } from '@/hooks/useBooleanHandlers'
import { useStyles } from './styles'
import Typography from '@/components/common/Typography'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'
import { useEffect } from 'react'

const openedMixin = (theme: Theme): CSSObject => ({
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen + 50,
    }),
    overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen + 50,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(10)} + 1px)`,
    },
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: DRAWER_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
)

const Sidebar = () => {
    const { classes } = useStyles()
    const logout = useLogout()
    const { user } = useAuth()
    const [openDrawer, setDrawerOpen, setDrawerClose] = useBooleanHandlers(true)
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })

    useEffect(() => {
        if (isSmallScreen) {
            setDrawerClose()
        } else {
            setDrawerOpen()
        }
    }, [isSmallScreen])

    return (
        <Box>
            <Drawer
                open={openDrawer}
                classes={{
                    paper: classes.paper,
                }}
                variant='permanent'
            >
                <Toolbar disableGutters className={clsx(!isSmallScreen && classes.spacing, 'flex-center')}>
                    <AppLogo small={!openDrawer} />
                </Toolbar>
                <Divider variant='middle' light />
                <Toolbar disableGutters className={clsx(!isSmallScreen && classes.spacing)}>
                    <Avatar
                        text={openDrawer ? `${user?.name} ${user?.lastName}` : ''}
                        value={`${user?.name} ${user?.lastName}`}
                        subText={user?.email}
                        src={user?.profilePicture?.url}
                    />
                </Toolbar>
                <Divider variant='middle' light />

                {user &&
                    <List className={clsx(!isSmallScreen && classes.spacing)}>
                        {APP_MENU.map((item, index) => {
                            if (!item.show) return null
                            return (
                                <MenuItem key={index} item={item} openDrawer={openDrawer} user={user} />
                            )
                        })}

                        <Divider variant='middle' light />

                        <ListItemButton id='logoutButton' onClick={logout} classes={{
                            root: clsx(classes.menuItem),
                        }}>
                            <div className={classes.containerLink}>
                                <IconLogout />
                                {openDrawer &&
                                    <ListItemText primary='Logout' classes={{
                                        primary: classes.menuItemLink,
                                    }} />
                                }
                            </div>
                        </ListItemButton>
                    </List>}

                <div className={classes.containerCollapseSidebar} onClick={openDrawer ? setDrawerClose : setDrawerOpen}>
                    {openDrawer ? <SkipPreviousIcon /> : <SkipNextIcon />}
                    {openDrawer && <Typography variant='body1' color='white'>Hide</Typography>}
                </div>
            </Drawer>
        </Box>
    )
}

export default Sidebar