import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
    termsWrapper:{
        height: '100vh',
        width: '100%',
        overflow: 'auto',
        color: 'gray',
    },
    textWrapper: {
        padding: '30px 320px 0',
    },
    footer: {
        padding: '24px 0',
        borderTop: '1px solid $border',
        justifyContent: 'space-between',
        display: 'flex',
        margin: '0 30px',
    },
    title: {
        fontSize: '32px',
        textAlign: 'center',
        color: 'black',
    },
    preheader: {
        height: '20px',
        display: 'none',
    },
    header: {
        justifyContent: 'space-between',
        display: 'flex',
        padding: '20px 30px',
    },
    logo: {
        width: '180px',
        height: '42px',
    },
    signIn: {
        fontSize: '16px',
    },
    link: {
        color: 'secondary',
    },
    article: {
        padding: '20px 0',
    },
    text: {
        fontSize: '15px',
        color: '#536270',
    },
    subtitle: {
        color: 'subtitleFont',
        fontSize: '18px',
    },
}))
