import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'

import { DestructByKey, Steps } from '@/common/types'
import Button from '@mui/material/Button'
import { steps } from './types'
import Modal from '../Modal'
import { useStyles } from './styles'
import VideoStep from './Components/VideoStep'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'

const renderSteps = (step: number, data: Steps) => {
    const stepsView: DestructByKey<React.ReactNode> = {
        1: <VideoStep data={data} />,
        2: <VideoStep data={data} />,
        3: <VideoStep data={data} />,
    }

    return stepsView[step] || null
}

interface Props {
    isOpen: boolean
    setIsOpen: () => void
    setIsClose: () => void
}

const TrainingVideo = ({ isOpen, setIsOpen, setIsClose }: Props) => {
    const [step, setStep] = useState(1)
    const { classes } = useStyles()
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })

    const handlePlusStep = () => {
        if (step < steps.length) {
            setStep(step + 1)
        } else {
            setStep(1)
            setIsClose()
        }
    }

    const handleCloseModal = () => {
        if (isOpen) {
            setStep(1)
        }
        setIsClose()
    }

    const handleMinusStep = () => {
        if (step > 1) {
            setStep(step - 1)
        }
    }

    useEffect(() => {
        if (isOpen) {
            setIsOpen()
        }
    }, [isOpen])

    return (
        <Modal
            open={isOpen}
            onClose={handleCloseModal}
            maxWidth={'md'}
            title={`Step ${step}/${steps.length}`}
            tittleColor={'secondary'}
        >
            <Box sx={{ width: '100%' }}>
                {renderSteps(step, steps[step - 1])}
            </Box>

            <div className={isSmallScreen ? classes.mobileActions : classes.actions}>
                {step !== 1 ? (
                    <div className={classes.actionSpace}>
                        <Button className={classes.prevBtn} color="inherit" onClick={handleMinusStep}>
                            {'< previous'}
                        </Button>
                    </div>
                ) : <div className={classes.actionSpace} />}
                <Button
                    className={classes.nextBtn}
                    variant="contained"
                    color="secondary"
                    onClick={handlePlusStep}
                >
                    {step < steps.length ? 'Next Step' : 'Get Started'}
                </Button>
                <div className={classes.actionSpace} />
            </div>
        </Modal>
    )
}

export default TrainingVideo