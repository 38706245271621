import { http } from '@/services/app'
import { useState } from 'react'
import useToast from '@/hooks/useToast'

export interface RequestState {
  loading: boolean;
  error: Error | null;
  data: any;
}

type RequestMethod = 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'GET';

type RequestFunction = (_url: string, _data?: any) => Promise<any>;

type UseRequestHook = (
  _method: RequestMethod,
  _auth?: boolean,
) => [RequestFunction, RequestState];

const useRequest: UseRequestHook = (method, auth) => {
    const [, toastError] = useToast()
    const [state, setState] = useState<RequestState>({
        loading: false,
        error: null,
        data: null,
    })

    const request: RequestFunction = async (url, data) => {
        setState({ loading: true, error: null, data: null })

        try {
            let responseData
            switch (method) {
            case 'GET':
                responseData = await http.get(url, 'json', auth)
                break
            case 'POST':
                responseData = await http.post(url, data)
                break
            case 'PUT':
                responseData = await http.put(url, data)
                break
            case 'DELETE':
                responseData = await http.delete(url)
                break
            
            case 'PATCH':
                responseData = await http.patch(url, data)
                break
            default:
                break
            }

            setState({ loading: false, error: null, data: responseData })

            return responseData
        } catch (error) {
            toastError(error.data && error.data.message ? error.data.message : error.message)
            setState({ loading: false, error, data: null })

            throw error
        }
    }

    return [request, state]
}

export default useRequest