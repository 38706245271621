import { useState, useCallback } from 'react'
import clsx from 'clsx'

import AuthLayout from '@/components/layouts/AuthLayout'
import Typography from '@/components/common/Typography'
import { TextField } from '@/components/common/Inputs'
import { Button } from '@/components/common/Buttons'
import EyePassword from '@/components/generics/EyePassword'
import { InputAdornment } from '@mui/material'
import password from '@/assets/icon/ico-pass.svg'
import { useQueryUrl, useForm, useKeyLock } from '@/hooks'
import { forgotPasswordConfirmation } from '@/services/amplify'
import styles from '../../styles.module.css'
import SuccessReset from '../SuccessReset'
import { getInitialValues, useScheme } from './validations'
import ErrorAuthMessage from '../../../components/Error'

interface FormParamas {
    password: string
    confirmPassword: string
}

const SetPasswordForm = () => {

    const schema = useScheme()
    const capsLock = useKeyLock('CapsLock')
    const { query } = useQueryUrl()

    const [error, setError] = useState('')
    const [pending, setPending] = useState(false)
    const [success, setSuccess] = useState(false)
    const [inputTypeOne, setInputTypeOne] = useState('password')
    const [inputTypeTwo, setInputTypeTwo] = useState('password')

    const onSubmit = useCallback(async (params: FormParamas) => {
        const { username, code } = query
        if (!(username && code)) return
        setError('')
        try {
            setPending(true)
            forgotPasswordConfirmation(
                username.trim().replaceAll(' ', '+'),
                code,
                params.password,
            )
                .then(() => setSuccess(true))
                .catch((e) => setError(e.code || e.message || 'An error occurred'))
                .finally(() => setPending(false))
        } catch (e) {
            setError(e.code || e.message || '')
        }
    }, [query.username, query.code])

    const { getFieldProps, handleSubmit } = useForm(
        schema,
        getInitialValues(),
        onSubmit,
    )

    return (
        <AuthLayout>
            <div className={styles.mainContent}>
                <div className={clsx('flex-center', 'flex-column', 'w-100', 'height-100')}>
                        
                    { success && <SuccessReset /> }

                    { !success &&
                    <>
                        <Typography variant="h3" className={styles.title} align='center'>
                                Set New Password
                        </Typography>
                        <Typography variant="body1" color='placeholder' align='center'>
                                For more security the password should contain numbers and uppercase letters
                        </Typography>
                        <form action="#" onSubmit={handleSubmit} className={clsx('w-100', 'd-flex', 'flex-column', 'align-center')}>
                            <div className={styles.inputWrapper}>
                                <TextField
                                    variant='standard'
                                    className={styles.textField}
                                    placeholder='New Password'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={password} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <EyePassword type={inputTypeOne} onSetIcon={setInputTypeOne} />
                                        ),
                                    }}
                                    type={inputTypeOne}
                                    textHelper={capsLock ? 'Caps lock is on' : ''}
                                    {...getFieldProps('password')}
                                />
                                {error && <ErrorAuthMessage error={error} className={styles.errorText} />}
                                <TextField
                                    variant='standard'
                                    className={styles.textField}
                                    placeholder='Confirm New Password'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={password} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <EyePassword type={inputTypeTwo} onSetIcon={setInputTypeTwo} />
                                        ),
                                    }}
                                    type={inputTypeTwo}
                                    textHelper={capsLock ? 'Caps lock is on' : ''}
                                    {...getFieldProps('confirmPassword')}
                                />
                                {error && <ErrorAuthMessage error={error} className={styles.errorText} />}
                            </div>
                            { capsLock || error && <ErrorAuthMessage error={capsLock ? 'Caps lock is on' : '' || error} /> }
                            <Button
                                disabled={pending}
                                variant="contained"
                                pending={pending}
                                type="submit"
                                size='large'
                                className='rounded-button'
                            >
                                Change Password
                            </Button>
                        </form>
                    </>
                    }
                </div>
            </div>
        </AuthLayout>
    )
}

export default SetPasswordForm