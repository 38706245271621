import { useCallback } from 'react'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import Checkbox from '@mui/material/Checkbox'

import Typography from '../../Typography'
import IconSearch from '@/components/svg/Icons/IconSearch'

import useAutocomplete from '@/hooks/useAutocomplete'
import { AutocompleteProps } from './types'
import { ItemValueAutocomplete } from '@/common/types'

const CustomAutocomplete = ({
    options = [],
    label = '',
    error,
    labelKey,
    apiEndpoint = '',
    placeholder,
    fullWidth,
    multiple,
    disableCloseOnSelect = false,
    value,
    disabled,
    className,
    customLoading,
    onChange,
}: AutocompleteProps) => {
    const { classes } = useStyles()

    const { items, loading, handleInputChange } = useAutocomplete({
        labelKey,
        apiEndpoint,
        value,
    })

    const handleChange = useCallback((_event: React.SyntheticEvent<Element, Event>, value: ItemValueAutocomplete) => {
        try {
            if (typeof onChange === 'function' && !loading) {
                return onChange(value)
            }
        } catch (error) {
            return
        }
    }, [labelKey, onChange])

    return (
        <>
            {label && (
                <Typography noWrap={true} weight="500" color="placeholder" gutter="label" variant="body2">
                    {label}
                </Typography>
            )}
            <Autocomplete
                value={value}
                id={`autocomplete-${labelKey}`}
                fullWidth={fullWidth}
                options={options?.length ? options : items}
                multiple={multiple}
                disableCloseOnSelect={disableCloseOnSelect}
                color='secondary'
                getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                        return option
                    }
                    if (option.name) {
                        return option.name
                    }
                    return ''
                }}
                renderOption={(props, option, { selected }) => (
                    <li {...props} key={option.id}>
                        {multiple &&
                        <Checkbox
                            style={{ marginRight: 8 }}
                            color='secondary'
                            checked={selected}
                        /> }
                        {option.name}
                    </li>
                )}
                renderInput={
                    (params) =>
                        <TextField
                            {...params}
                            error={!!error}
                            helperText={error}
                            placeholder={placeholder}
                            className={clsx(classes.root, className)}
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <IconSearch />
                                ),
                                endAdornment: (
                                    <>
                                        {(loading || customLoading) ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                }
                onChange={handleChange}
                onInputChange={handleInputChange}
            />
        </>
    )
}

const useStyles = makeStyles()(() => ({
    root: {
        '& .MuiOutlinedInput-root': {
            fontSize: 14,
            // fieldset: {
            //     border: 'solid 1px rgba(0, 0, 0, 0.14)',
            // },
            // '&:hover fieldset': {
            //     border: 'solid 1px rgba(0, 0, 0, 0.14)',
            // },
            // '&.Mui-focused fieldset': {
            //     border: 'solid 1px rgba(64, 110, 206, 0.8)',
            // },
            '&.Mui-disabled fieldset': {
                background: '#f7f8fa',
            },
        },
    },
    option: {
        fontSize: 14,
        '&[aria-selected="true"]': {
            backgroundColor: 'rgba(0, 108, 240, 0.4) !important',
            color: '#fff !important',
        },
    },
}))

export default CustomAutocomplete