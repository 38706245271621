import { useEffect, useState } from 'react'
import clsx from 'clsx'
import Paper from '@/components/generics/Paper'
import Box from '@mui/material/Box'
import Typography from '@/components/common/Typography'
import { Button } from '@/components/common/Buttons'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import { useForm } from '@/hooks/useForm'
import Map from '@/components/generics/DrivingZoneMap'
import Switch from '@mui/material/Switch'
import { API_TEAMS } from '@/constants/routes'
import useToast from '@/hooks/useToast'
import Grid from '@mui/material/Grid'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useStyles } from './styles'
import { TeamsValues } from './types'
import { getInitialValues, useScheme } from './validations'
import useFetch from '@/hooks/useFetch'
import { ListInit, GroupTeam } from '@/common/types'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'
import LoaderSmall from '@/components/common/Loader/LoaderSmall'
import useCrud from '@/hooks/useCrud'
import { useAuth } from '@/hooks/useAuth'

export const Teams = () => {
    const { classes } = useStyles()
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })
    const schema = useScheme()
    const { user, setUser } = useAuth()
    const [toastSuccess] = useToast()
    const [apiRequest, apiState] = useCrud('PUT')
    const [teamsId, setTeamsId] = useState(Array<string>)
    const [geoJson] = useState({ type: '', features: [] })
    const [teams, loading] = useFetch<ListInit<GroupTeam>>(API_TEAMS.GET_TEAMS)

    useEffect(() => {
        if (user) {
            setTeamsId(user.teams.map(item => {return item.id}))
        }
    }, [user])

    const onSubmit = async (values: TeamsValues) => {
        try {
            await apiRequest(API_TEAMS.UPDATE_TEAMS, values)
            if (user){
                const newTeams = values.teams.map(item => {return { id: item }})
                const newUser = { ...user, teams: newTeams }
                setUser(newUser)
                toastSuccess('Teams Changed')
            }
        } catch (error) {
            console.error(error)
        }
    }

    const { values, setFieldValue, handleSubmit } = useForm(
        schema,
        getInitialValues({
            teams: teamsId,
        }),
        onSubmit,
    )

    return (
        <div className='page-content'>
            <Paper px={isSmallScreen?2:10} py={1}>
                <Box sx={{ backgroundColor: 'white', marginTop: '15px' }}>
                    <Typography variant='h4'>Select a Delivery Zone</Typography>
                    <Typography variant='body1' gutter='bottom2'>
                        Please select a team to receive orders from OnFleet. <br/>
                        You may choose multiple teams depending on your driving preferences.
                    </Typography>

                    <form action="#" onSubmit={handleSubmit} className={clsx('w-100', 'd-flex', 'flex-column', 'align-center')}>
                        <Grid container spacing={2} sx={{ mb: 5 }} flexDirection={isSmallScreen?'column':'row'} >
                            <Grid item xs={isSmallScreen?12:6}>
                                <Grid container justifyContent='space-between' sx={{ mt: 5, mb: 1 }}>
                                    <Typography weight='500'>Team</Typography>
                                    <Typography weight='500'>Activation</Typography>
                                </Grid>
                                { loading && <LoaderSmall /> }
                                {!loading && teams && teams.records.filter(i => !!i.state).map(item => {
                                    const { state, regions } = item
                                    return (
                                        <Accordion
                                            key={state}
                                            elevation={0}
                                            disableGutters
                                        >
                                            <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls="panel1bh-content"
                                                id="panel1bh-header"
                                            >
                                                <Typography>{state}</Typography>
                                            </AccordionSummary>
                                            {regions.map(region => {
                                                const { region: name, teams } = region
                                                return(
                                                    <Accordion sx={{ paddingInline: 1 }} key={name} elevation={0}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            id="panel1bh-content2"
                                                            aria-controls="panel1bh-content2"
                                                        >
                                                            <Typography>{name}</Typography>
                                                        </AccordionSummary>

                                                        <AccordionDetails>
                                                            <Grid container sx={{ paddingInline: 2 }}>
                                                                { teams.map(team => (
                                                                    <Grid item key={team.id} xs={12}>
                                                                        <div className={clsx('d-flex', 'justify-between', 'align-center')}>
                                                                            <Typography>{team.name}</Typography>
                                                                            <Switch
                                                                                id={team.name.replace(' ', '_')}
                                                                                color='success'
                                                                                onChange={(e) => {
                                                                                    const { checked } = e.target
                                                                                    const newTeam = checked ? [...values.teams, team.id] : values.teams.filter(item => item !== team.id)
                                                                                    setFieldValue('teams', newTeam)
                                                                                }}
                                                                                checked={values.teams.includes(team.id)}
                                                                            />
                                                                        </div>
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                )
                                            })}
                                        </Accordion>
                                    )
                                })}
                            </Grid>
                            <Grid item xs={isSmallScreen?12:6}>
                                <Map geoJson={geoJson} />
                            </Grid>
                        </Grid>
                        <div className='flex-center'>
                            <Button
                                className={clsx('rounded-button', classes.continueButton)}
                                variant="contained"
                                color="secondary"
                                type="submit"
                                pending={apiState.loading}
                                disabled={apiState.loading}
                            >
                                <Typography className={classes.buttonText}>Save</Typography>
                            </Button>
                        </div>
                    </form>
                </Box>
            </Paper>
        </div>
    )
}
