import { useContext } from 'react'
import { AppContext } from '@/contexts/AppContext'
import useCrud from './useCrud'
import { updateCourierPayload } from '@/common/types'
import { API_COURIER } from '@/constants/routes'

export function useAuth() {
    const { user, setUser } = useContext(AppContext)
    const [apiRequest] = useCrud('PUT')
    const updateCourier = async (values: updateCourierPayload) => {
        return await apiRequest(API_COURIER.UPDATE_COURIER, values)
    }

    return {
        isLogged: Boolean(user),
        user,
        setUser,
        updateCourier,
    }
}
