import { CourierType, FileType } from '@/common/enums'

export * from './generics/cognito'

export const DRAWER_WIDTH = 280
const ALL_COURIER_TYPES = Object.values(CourierType)

export const APP_MENU = [
    {
        path: '/dashboard',
        show: true,
        label: 'Dashboard',
        icon: 'dashboard',
        nestedItems: [
            {
                path: '/earning',
                show: true,
                label: 'Earning Metrics',
                icon: '',
                inNested: true,
                showForCourierTypes: ALL_COURIER_TYPES,
            },
            {
                path: '/order',
                show: true,
                label: 'Order History',
                icon: '',
                inNested: true,
                showForCourierTypes: ALL_COURIER_TYPES,
            },
            {
                path: '/transaction',
                show: true,
                label: 'Transaction History',
                icon: '',
                inNested: true,
                showForCourierTypes: [CourierType.MZ_1099],
            },
            {
                path: '/dashboard/terms',
                show: true,
                label: 'Terms and Conditions',
                icon: '',
                inNested: true,
                showForCourierTypes: ALL_COURIER_TYPES,
            },
        ],
    },
    {
        path: '/settings',
        show: true,
        label: 'Settings',
        icon: 'settings',
        nestedItems: [
            {
                path: '/payment',
                show: true,
                label: 'Payment Options',
                icon: '',
                inNested: true,
                showForCourierTypes: [CourierType.MZ_1099],
            },
            {
                path: '/profile',
                show: true,
                label: 'Profile',
                icon: '',
                inNested: true,
                showForCourierTypes: ALL_COURIER_TYPES,
            },
            {
                path: '/change-password',
                show: true,
                label: 'Change Password',
                icon: '',
                inNested: true,
                showForCourierTypes: ALL_COURIER_TYPES,
            },
            {
                path: '/teams',
                show: true,
                label: 'Teams',
                icon: '',
                inNested: true,
                showForCourierTypes: ALL_COURIER_TYPES,
            },
            {
                path: '/wizard',
                show: true,
                label: 'Training Wizard',
                icon: '',
                inNested: true,
                customAction: 'openModalOnboarding',
                showForCourierTypes: ALL_COURIER_TYPES,
            },
            {
                path: '/video',
                show: true,
                label: 'Training Video',
                icon: '',
                inNested: true,
                customAction: 'openModalVideo',
                showForCourierTypes: ALL_COURIER_TYPES,
            },
        ],
    },
]

export const INVOICING_OPTIONS = [
    { key: 'monthly', value: 'Monthly' },
    { key: 'bi_monthly', value: 'Bi-Monthly (1/15th)' },
    { key: 'bi_weekly', value: 'Bi-Weekly (every two weeks)' },
    { key: 'weekly', value: 'Weekly' },
]

export const REPORTING_OPTIONS = [
    { key: 'all_on_one_page', value: 'One page per customer' },
    { key: 'one_page_per_customer', value: 'All customers on one page' },
]

export const TIME_OPTIONS = [
    { key: 'am', value: 'AM' },
    { key: 'am', value: 'AM' },
]

export const PICKUP_TIMES_DEFAULT = [
    { time: '12:00:00', pickupTime: '12:00 PM', checked: false },
    { time: '15:00:00', pickupTime: '3:00 PM', checked: false },
]

export const LESS_THAN_25_PER_DAY_KEY = 'less_than_25_per_day'
export const GREATER_THAN_25_PER_DAY_KEY = 'greater_than_25_per_day'
export const FAILED_DELIVERY_CHARGE_KEY = 'failed_delivery_charge'

export const ORDER_VOLUMES_DEFAULT: { [propKey: string]: string } = {
    less_than_25_per_day: 'Less than 25/day',
    greater_than_25_per_day: 'Greater than 25/day',
    failed_delivery_charge: 'Failed Delivery Charge',
}

export const GROUP_AFFILIATION_TYPES = [
    { label: 'Individual', value: 'individual' },
    { label: 'Group', value: 'group' },
]

export const DELIVERY_RADIUS = [
    {
        label: '0-10 mi',
        value: '0-10',
    },
    {
        label: '0-25 mi',
        value: '0-25',
    },
    {
        label: '0-50 mi',
        value: '0-50',
    },
    {
        label: '50+ mi',
        value: '50+',
    },
]

export const DATE_PRETTY_FORMAT = 'MMM D, YYYY'
export const DATE_FORMAT = 'MM/DD/YYYY'
export const DATE_ALL_FORMAT = 'MM/DD/YYYY, h:mm A'
export const DATE_TIME_FORMAT = 'MM/DD/YY, h:mm  A'
export const HOUR_FORMAT = 'h:mm A'
export const DATE_PRETTY_FORMAT_AND_HOURS = 'MMM D, YYYY h:mm A'

export const COMPONENT_TYPE_MAP: { [key: string]: string } = {
    administrative_area_level_1: 'state',
    administrative_area_level_2: 'county',
    country: 'country',
    locality: 'city',
    sublocality: 'city',
    postal_code: 'zip',
    route: 'street',
    street_number: 'number',
}

export const KEY_UPLOAD_FILES: { [key: string]: string } = {
    [FileType.COURIER_SELFIE]: 'selfiePicture',
    [FileType.COURIER_PROFILE_PICTURE]: 'profilePicture',
    [FileType.COURIER_LICENSE]: 'licensePicture',
    [FileType.COURIER_INSURANCE]: 'insurancePicture',
    [FileType.COURIER_ID_CARD]: 'idCardPictureFile',
    [FileType.COURIER_VEHICLE_FRONT]: 'front',
    [FileType.COURIER_VEHICLE_BACK]: 'back',
    [FileType.COURIER_VEHICLE_LEFT]: 'leftSide',
    [FileType.COURIER_VEHICLE_RIGHT]: 'rightSide',
}

export const DAYS_OF_WEEK_TO_NUMBER = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
}
