import Grid from '@mui/material/Grid'
import Typography from '@/components/common/Typography'
import clsx from 'clsx'
import { Button } from '@/components/common/Buttons'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'
import { AuthUser } from '@/common/types'
import { useStyles } from '../styles'
import { CREATE_WITHDRAW_BUTTON, EDIT_ACCOUNT_BUTTON, UNLINK_ACCOUNT_BUTTON, testProp } from '@/constants/testIds'

interface Props {
    courier?: AuthUser | null
    setOpenUpdateModal: () => void
    setOpenModalWithdraw: () => void
    handleRemoveCard: () => void
}

export const LinkedBankAccount = ({ courier, setOpenUpdateModal, setOpenModalWithdraw, handleRemoveCard }: Props) => {
    const { classes } = useStyles()
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })

    return (
        <>
            <Grid container spacing={2} rowSpacing={4} marginBottom={3} columnSpacing={2} flexDirection={isSmallScreen?'column':'row'} wrap='nowrap'>
                <Grid item xs={isSmallScreen?12:6} >
                    <div className={classes.cardBlock}>
                        <div className={clsx(classes.linkedCard, classes.card)}>
                            <Typography className={classes.cardTitle} color='white'>Linked Bank Account</Typography>
                            <Typography className={classes.cardNumber} color='white'>
                                {courier?.dwollaInformation && courier?.dwollaInformation.accountNumber.slice(0, 4)}
                                **** **** {courier?.dwollaInformation && courier?.dwollaInformation.accountNumber.slice(-4)}
                            </Typography>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={isSmallScreen?12:6} >
                    <div className={classes.cardBlock}>
                        <div className={clsx(classes.card)}>
                            <Typography className={classes.balanceTitle}>Balance</Typography>
                            <Typography className={classes.money}>${Number(courier?.balance).toFixed(2)}</Typography>
                        </div>
                    </div>
                </Grid>
            </Grid>
            <Grid item xs={12} marginBottom={5} alignItems={'center'}>
                <Button
                    className={clsx('rounded-button', classes.bottons)}
                    {...testProp(CREATE_WITHDRAW_BUTTON)}
                    variant="outlined"
                    color="secondary"
                    onClick={setOpenModalWithdraw}
                >
                    <Typography variant='body1' color='light' >Withdraw Funds</Typography>
                </Button>
                <Button
                    color="secondary"
                    variant="outlined"
                    {...testProp(EDIT_ACCOUNT_BUTTON)}
                    onClick={setOpenUpdateModal}
                    className={clsx('rounded-button', classes.bottons)}
                >
                    <Typography variant='body1' color='light'>Change</Typography>
                </Button>
                <Button 
                    color="primary"
                    onClick={handleRemoveCard}
                    {...testProp(UNLINK_ACCOUNT_BUTTON)}
                    className={clsx('rounded-button', classes.bottons)}
                >
                    <Typography variant='body1' color='light'>Delete</Typography>
                </Button>
            </Grid>
        </>
    )
}