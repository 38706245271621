import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
    inputWrapper: {
        display: 'flex',
        maxWidth: 700,
        gridGap: 50,
        padding: '35px 24px',
        flexWrap: 'wrap',
    },
    cardBlock: {
        width: '100%',
        display: 'flex',
    },
    card: {
        width: '80%',
        height: '120px',
        maxWidth: '280px',
        border: '1px solid grey',
        padding: '25px 30px',
        borderRadius: '10px',
    },
    notLinkedCard: {
        background: '#edf0f2',
    },
    linkedCard: {
        color: 'white',
        background: '#ca1c3c url(https://courier.test.medzoomer.com/static/media/fill-1.d2b705e2.svg) no-repeat',
        backgroundSize: 'cover',
    },
    cardTitle: {
        marginBottom: '50px',
        fontWeight: '600',
    },
    cardNumber: {
        fontSize: '14px',
    },
    money: {
        fontSize: '57px',
        color: '#4cc71d',
        fontWeight: '500',
    },
    balanceTitle: {
        marginBottom: '16px',
        color: 'grey',
        fontWeight: 500,
    },
    textField: {
        maxWidth: '350px',
        '& input': {
            padding: '25px 14px',
        },
        backgroundColor: '#fff',
    },
    bottons: {
        margin: '10px',
    },
}))
