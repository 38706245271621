import Grid from '@mui/material/Grid'
import Typography from '@/components/common/Typography'
import clsx from 'clsx'
import { Button } from '@/components/common/Buttons'
import { useStyles } from '../styles'
import { LINK_ACCOUNT_BUTTON, testProp } from '@/constants/testIds'

interface Props {
    setOpenModal: () => void
}

export const UnlinkedBankAccount = ({ setOpenModal }: Props) => {
    const { classes } = useStyles()

    return (
        <>
            <Grid item lg={6} xs={12}>
                <div className={classes.cardBlock}>
                    <div className={clsx(classes.notLinkedCard, classes.card)}>
                        <Typography className={classes.cardTitle}>Bank Account</Typography>
                        <Typography className={classes.cardNumber}>0000 **** **** 0000</Typography>
                    </div>
                </div>
            </Grid>
            <Grid item lg={6} xs={12} marginBottom={5}>
                <Button
                    className={clsx('rounded-button')}
                    {...testProp(LINK_ACCOUNT_BUTTON)}
                    variant="contained"
                    color="secondary"
                    onClick={setOpenModal}
                >
                    <Typography variant='body1' color='light'>Link Bank Account</Typography>
                </Button>
            </Grid>
        </>
    )
}