import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
    codeInput: {
        '& > input': {
            marginRight: 15,
            MozAppearance: 'textfield',
            width: 35,
            fontSize: 20,
            height: 35,
            color: theme.palette.common.black,
            textAlign: 'center',
            border: '0px',
            borderBottom: '1px solid rgba(0, 0, 0, 0.14)',
            outline: 'none',
            padding: 5,
        },
        [theme.breakpoints.down('md')]: {
            '& > input': {
                width: 25,
            },
        },
    },
    inputNumberWrapper: {
        display: 'flex',
        gap: 30,
        marginTop: theme.spacing(4),
    },
}))
