import Grid from '@mui/material/Grid'
import clsx from 'clsx'
import { Button } from '@/components/common/Buttons'
import GooglePlacesAutocomplete from '@/components/generics/GooglePlacesAutocomplete'
import { useForm } from '@/hooks'
import Modal from '@/components/common/Modal'
import { useEffect, useState } from 'react'
import { useStyles } from './styles'
import { API_COURIER } from '@/constants/routes'
import { ProfileValues } from '@/pages/Profile/types'
import { useAuth } from '@/hooks/useAuth'
import useToast from '@/hooks/useToast'
import useCrud from '@/hooks/useCrud'
import { useBooleanHandlers } from '@/hooks/useBooleanHandlers'
import { getInitialValues, useScheme } from '@/pages/Profile/validations'
import Typography from '@/components/common/Typography'


const AddressModal = () => {
    const { classes } = useStyles()
    const [loading, setLoading] = useState(false)
    const { user, setUser } = useAuth()
    const schema = useScheme()
    const [availableWeekdays, setAvailableWeekdays] = useBooleanHandlers()
    const [availableSaturday, setAvailableSaturday] = useBooleanHandlers()
    const [openModal, setOpenModal, setCloseModal] = useBooleanHandlers()
    const [apiRequest] = useCrud('PUT')
    const [toastSuccess] = useToast()


    useEffect(() => {
        if (user) {
            setOpenModal()
        }
    }, [user])

    const onSubmit = async (values: ProfileValues) => {
        try {
            setLoading(true)

            const { address, apartment } = values
            const payload = {
                address: {
                    googleId: address.googleId,
                    number: address.number,
                    latitude: address.latitude,
                    longitude: address.longitude,
                    street: address.street,
                    city: address.city,
                    state: address.state,
                    country: address.country,
                    zipcode: address.zipcode,
                    address2: apartment || 'N/A',
                },
            }
            const response = await apiRequest(API_COURIER.UPDATE_COURIER, payload)
            setUser({ ...user, ...response })
            toastSuccess('Profile Updated')
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const { getFieldProps, values, errors, setFieldValue, handleSubmit } = useForm(
        schema,
        getInitialValues({
            name: user?.name,
            lastName: user?.lastName,
            email: user?.email,
            phone: user?.phone,
            address: {
                number: user?.address?.number,
                street: user?.address?.street,
                city: user?.address?.city,
                state: user?.address?.state,
                zip: user?.address?.zipcode,
                country: user?.address?.country,
                formattedAddress: user?.address?.fullAddress,
                googleId: user?.address?.googleId,
            },
            apartment: user?.address?.address2,
            birthdate: user?.birthDate,
            profilePicture: user?.profilePicture?.fileUri,
            licensePicture: user?.license?.fileUri,
            insurancePicture: user?.insurancePicture?.fileUri,
            gender: user?.gender,
            tShirt: user?.tShirtSize,
            make: user?.vehicle?.make,
            carModel: user?.vehicle?.model,
            carYear: user?.vehicle?.year,
            back: user?.vehicle?.backPicture?.fileUri,
            front: user?.vehicle?.frontPicture?.fileUri,
            leftSide: user?.vehicle?.leftPicture?.fileUri,
            rightSide: user?.vehicle?.rightPicture?.fileUri,
            workingHours: [],
            availableWeekdays: availableWeekdays,
            availableSaturday: availableSaturday,
            deliveryRadius: user?.deliveryRadius?.value,
        }),
        onSubmit,
    )

    return (
        <Modal
            open={openModal}
            maxWidth={'md'}
            center
            onClose={setCloseModal}
        >
            <Grid container spacing={2} rowSpacing={4}>
                <Grid item xs={12}>
                    <Typography variant="h4" weight='500' gutter='bottom2' align='center'>Update your information</Typography>
                    <Typography variant='body1'>
                        Please, type and select your address before continuing
                    </Typography>
                    <GooglePlacesAutocomplete
                        error={errors.address?.number}
                        value={values.address}
                        inputVariant='outlined'
                        handleSelectAddress={(address) => setFieldValue('address', address)}
                    />
                </Grid>
                <div className={classes.updateButtonContainer}>
                    <Button
                        className={clsx('rounded-button', classes.updateButton)}
                        variant="contained"
                        pending={loading}
                        color="secondary"
                        onClick={()=>onSubmit(values)}
                    >
                                            Update Information
                    </Button>
                </div>
            </Grid>
        </Modal>
    )
}

export default AddressModal