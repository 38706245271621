export const AUTH_NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED'
export const USER_NOT_CONFIRMED_EXCEPTION = 'UserNotConfirmedException'
export const USER_NOT_CONFIRMED = 'UserNotConfirmed'
export const USER_EXIST_EXCEPTION = 'UsernameExistsException'
export const USER_LAMBDA_EXIST_EXCEPTION = 'UserLambdaValidationException'
export const INVALID_PARAMETER_EXCEPTION = 'InvalidParameterException'
export const USER_NOT_FOUND_EXCEPTION = 'UserNotFoundException'
export const INVALID_PASSWORD_EXCEPTION = 'InvalidPasswordException'
export const INVALID_CREDENTIALS = 'NotAuthorizedException'
export const NOT_AUTHORIZED_EXCEPTION = 'NotAuthorizedException'
export const CODE_MISMATCH_EXCEPTION = 'CodeMismatchException'
export const USER_MIGRATION_FAILED_MESSAGE = 'UserMigration failed with error '
