
// login
export const LOGIN_BUTTON = 'LOGIN_BUTTON'
export const LOGIN_INPUT_EMAIL = 'LOGIN_INPUT_EMAIL'
export const LOGIN_INPUT_PASSWORD = 'LOGIN_INPUT_PASSWORD'

// earning
export const EARNING_BUTTON = 'EARNING_BUTTON'
export const VIEW_ALL_TRASACTIONS_BUTTON = 'VIEW_ALL_TRASACTIONS_BUTTON'
export const VIEW_ALL_ORDERS_BUTTON = 'VIEW_ALL_ORDERS_BUTTON'

// payment
export const LINK_ACCOUNT_BUTTON = 'LINK_ACCOUNT_BUTTON'
export const UNLINK_ACCOUNT_BUTTON = 'UNLINK_ACCOUNT_BUTTON'
export const EDIT_ACCOUNT_BUTTON = 'EDIT_ACCOUNT_BUTTON'
export const CREATE_WITHDRAW_BUTTON = 'CREATE_WITHDRAW_BUTTON'

// delivery
export const DELIVERY_DATE_INPUT = 'DELIVERY_DATE_INPUT'
export const DELIVERY_COPAY_INPUT = 'DELIVERY_COPAY_INPUT'
export const DELIVERY_CONTACTLESS_RADIO = 'DELIVERY_CONTACTLESS_RADIO'
export const DELIVERY_GROUP_RADIO = 'DELIVERY_GROUP_RADIO'
export const DELIVERY_TIMES_RADIO = 'DELIVERY_TIMES_RADIO'

// confirmation
export const CREATE_ORDER_BUTTON = 'CREATE_ORDER_BUTTON'
export const PRINT_BUTTON = 'PRINT_BUTTON'
export const CONFIRMATION_MODAL = 'EMAIL_BUTTON'


// sign up
export const SIGNUP_BUTTON = 'SIGNUP_BUTTON'
export const GET_STARTED = 'GET_STARTED'
export const ADDRESS_BOX = 'ADDRESS_BOX'
export const WEEKDAYS_BOX = 'WEEKDAYS_BOX'
export const SATURDAYS_BOX = 'SATURDAYS_BOX'
export const DELIVERY_RADIO_BOX = 'DELIVERY_RADIO_BOX'
export const GENDER_BOX = 'GENDER_BOX'
export const T_SHIRT_BOX = 'T_SHIRT_BOX'
export const DEVICE_PLATFORM = 'DEVICE_PLATFORM'
export const PRIMARY_LANGUAGE = 'PRIMARY_LANGUAGE'
export const HAT_BOX = 'HAT_BOX'
export const AUTO_POLICY_BOX = 'AUTO_POLICY_BOX'
export const HEAR_ABOUT_BOX = 'HEAR_ABOUT_BOX'
export const WORK_BEFORE_RADIO = 'WORK_BEFORE_RADIO'
export const FLEET_AGREEMENT = 'FLEET_AGREEMENT'
export const FW9_FORM = 'FW9_FORM'


// Helper function to select elements.
export const testProp = (id: string, customComponent = false) => {
    if (customComponent) {
        return {
            'data-test-id': id,
            dataTestId: id,
            errorTestId: `ERROR-${id}`,
            labelTestId: `LABEL-${id}`,
        }
    }
    return {
        'data-test-id': id,
    }
}
