import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
    root: {
        maxWidth: 345,
    },
    height: {
        height: '100vh',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        },
    },
    imageBlock: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: '2rem 2.5rem',
        backgroundColor: theme.palette.primary.main,
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    authImage: {
        display: 'flex',
        justifyContent: 'center',
    },
    logoApp: {
        width: 180,
        height: 42,
    },
    iconAuth: {
        maxWidth: '100%',
        objectFit: 'scale-down',
    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        alignItems: 'end',
        color: '#fff',
    },
    mobileHeader: {
        boxShadow: '0 10px 20px 0 rgba(78, 89, 120, 0.06)',
        height: 70,
        display: 'none',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            backgroundColor: theme.palette.primary.main,
            paddingInline: '1rem',
        },
    },
    mobileFooter: {
        padding: '0 1.875rem',
        width: '100%',
        display: 'none',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            backgroundColor: theme.palette.primary.main,
            padding: '1.5rem',
        },
    },
    link: {
        color: 'white',
    },
}))
