import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
    startStep: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingInline: '1em',
    },
    error: {
        margin: '-20px 0 15px',
    },
    list: {
        boxShadow: '0 0 1px 0 rgba(0, 0, 0, 0.5)',
        borderRadius: '10px',
        padding: '1em 2.5em',
    },
    listItem: {
        padding: '10px 0',
        color: '#000000',
    },
    continueButton: {
        padding: '15px 40px',
        margin: '2em',
    },
    signUpFooter: {
        width: '100%',
        height: '88px',
        fontSize: '16px',
        borderTop: '1px solid $greyBorder',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
}))
