import clsx from 'clsx'
import { useNavigate } from 'react-router-dom'

import Tooltip from '@mui/material/Tooltip'
import Collapse from '@mui/material/Collapse'
import ListItemButton from '@mui/material/ListItemButton'
import List from '@mui/material/List'

import { RouterLink } from '@/components/common/Buttons'
import { dashboard as IconDashboard } from '@/components/svg/Icons/Menu'

import ICONS from './icons'
import { useStyles } from '../../styles'

import useActiveRoute from '@/hooks/useActiveRoute'
import DashboardSteps from '@/components/common/Steps'
import { useBooleanHandlers } from '@/hooks/useBooleanHandlers'
import TrainingVideo from '@/components/common/TrainingVideo'
import { CourierType } from '@/common/enums'
import { AuthUser } from '@/common/types'

interface Props {
    openDrawer: boolean
    item: {
        path: string,
        label: string,
        icon: string,
        show: boolean,
        nestedItems?: NestedItem[]
        inNested?: boolean
    }
    handleDrawerClose?: () => void
}

interface NestedItem {
    path: string | undefined
    label: string
    icon: string
    customAction?: string
    showForCourierTypes: CourierType[]
}

interface MenuItem extends Props {
    user: AuthUser
}

type ItemButton = MenuItem

const filteredNestedItems = (nestedItems: NestedItem[], courierType: CourierType) => {
    return nestedItems.filter(item => item.showForCourierTypes.includes(courierType))
}

const ItemButton = ({ openDrawer, item, user, handleDrawerClose }: ItemButton) => {
    const { classes } = useStyles()
    const activeRoute = useActiveRoute()
    const IconComponent = ICONS[item.icon] || IconDashboard
    const navigate = useNavigate()

    const handleAction = () => {
        if (item.nestedItems && item.nestedItems.length && item.nestedItems[0].path) {
            const nestedItems = filteredNestedItems(item.nestedItems, user.courierType)
            if (nestedItems.length && nestedItems[0].path) {
                navigate(nestedItems[0].path)
            }
            handleDrawerClose ? handleDrawerClose() : null
        }
    }

    return (
        <Tooltip arrow title={!openDrawer ? item.label : ''} placement={'right'}>
            <ListItemButton
                className={item.path === activeRoute && !item.nestedItems ? classes.activeItem : ''}
                classes={{ root: clsx(classes.menuItem) }}
                onClick={handleAction}
            >
                <div className={classes.containerLink}>
                    <IconComponent />
                    {openDrawer && item.label}
                </div>
            </ListItemButton>
        </Tooltip>
    )
}

const MenuItem = ({ openDrawer, item, user, handleDrawerClose }: MenuItem) => {
    const { classes } = useStyles()
    const activeRoute = useActiveRoute()
    const [openModalOnboarding, setOpenModalOnboarding, setCloseOpenModalOnboarding] = useBooleanHandlers()
    const [openModalVideo, setOpenModalVideo, setCloseOpenModalVideo] = useBooleanHandlers()

    const openCustomAction = (customAction: string) => {
        if (customAction === 'openModalOnboarding') {
            setOpenModalOnboarding()
        }
        if (customAction === 'openModalVideo') {
            setOpenModalVideo()
        }
    }

    return (
        <>
            {item.nestedItems && item.nestedItems.length > 0 ? (
                <ItemButton user={user} openDrawer={openDrawer} item={item} handleDrawerClose={handleDrawerClose} />
            ) : (
                <RouterLink to={item.nestedItems && item.nestedItems.length && item.nestedItems[0].path ? item.nestedItems[0].path : item.path} className={classes.menuItemLink}>
                    <ItemButton user={user} openDrawer={openDrawer} item={item} handleDrawerClose={handleDrawerClose} />
                </RouterLink>
            )}
            {openDrawer && item.nestedItems && item.nestedItems.length > 0 &&
                <Collapse in={true} timeout="auto" unmountOnExit>
                    <List component="div" style={{ width: '90%' }} sx={{ ml: 2 }}>
                        {filteredNestedItems(item.nestedItems, user.courierType).map((nestedItem: NestedItem) => {
                            return !nestedItem.customAction ? (
                                (
                                    <RouterLink to={nestedItem.path ?? ''} key={nestedItem.path} className={classes.menuItemLink} handleDrawerClose={handleDrawerClose}>
                                        <ListItemButton
                                            id={nestedItem.label.replace(' ', '_')}
                                            className={nestedItem.path === activeRoute ? classes.activeSubMenuItem : ''}
                                            classes={{ root: clsx(classes.subMenuItem) }}
                                        >
                                            <div className={classes.containerLink}>
                                                {openDrawer && nestedItem.label}
                                            </div>
                                        </ListItemButton>
                                    </RouterLink>
                                )
                            ) :
                                (
                                    <ListItemButton
                                        id={nestedItem.label.replace(' ', '_')}
                                        key={nestedItem.path}
                                        className={nestedItem.path === activeRoute ? classes.activeSubMenuItem : ''}
                                        classes={{ root: clsx(classes.subMenuItem) }}
                                        onClick={() => openCustomAction(nestedItem.customAction as string)}
                                    >
                                        <div className={classes.containerLink}>
                                            {openDrawer && nestedItem.label}
                                        </div>
                                    </ListItemButton>
                                )
                        })}
                    </List>
                </Collapse>
            }
            {openModalOnboarding &&
                <DashboardSteps isOpen={openModalOnboarding} setIsOpen={setOpenModalOnboarding} setIsClose={setCloseOpenModalOnboarding} />
            }
            {openModalVideo &&
                <TrainingVideo isOpen={openModalVideo} setIsOpen={setOpenModalVideo} setIsClose={setCloseOpenModalVideo} />
            }
        </>
    )
}

export default MenuItem