import TextField from '@mui/material/TextField'
import CloseIcon from '@mui/icons-material/Close'

import Typography from '@/components/common/Typography'
import { Button, BackButton } from '@/components/common/Buttons'
import IconSearch from '@/components/svg/Icons/IconSearch'
import ExportFile from '@/components/generics/ExportFile'
import FilterButtonList from '@/components/generics/FilterButtonList'

import useSearchInput from '@/hooks/useSearchInput'

import { AppBarContentProps } from './types'

import styles from './styles.module.css'

const AppBarContent = (props: AppBarContentProps) => {
    const {
        title,
        buttonVariant = 'contained',
        buttonColor = 'info',
        buttonText = 'Add',
        showExport = false,
        showSearch = true,
        showFilters = false,
        showAdd = true,
        centerTitle = true,
        pagination,
        backButton,
        filters,
        endpointExport,
        searchValue,
        onSubmitSearch,
        onClickAdd,
        openFilterDialog,
    } = props

    const { text, onSubmit, onChange, onClearSearch } = useSearchInput({
        value: searchValue || '',
        onValueChange: onSubmitSearch && onSubmitSearch,
    })

    return (
        <div className={styles.containerBarContent}>
            <div className={styles.containerSearch}>
                { backButton && <BackButton /> }
                { !centerTitle && <Typography variant="h6">{title}</Typography> }
                { showSearch &&
                <form action="#" onSubmit={onSubmit}>
                    <TextField
                        size='small'
                        className={styles.searchInput}
                        placeholder='Search...'
                        value={text}
                        InputProps={{
                            endAdornment: (
                                text ? (
                                    <CloseIcon sx={{ cursor: 'pointer' }} fontSize='small' onClick={onClearSearch}/>
                                ) : (
                                    <IconSearch />
                                )
                            ),
                        }}
                        onChange={onChange}
                    />
                </form>
                }
                { showFilters && <FilterButtonList filters={filters} onClick={openFilterDialog} /> }
            </div>
            { centerTitle && <Typography variant="h6">{title}</Typography> }
            <div className={styles.containerActions}>
                { pagination }
                { showExport &&
                    <ExportFile
                        endpointExport={endpointExport || ''}
                        fileName={`${title}.csv`}
                        mimeType='text/csv'
                    />
                }
                { showAdd &&
                <Button variant={buttonVariant} color={buttonColor} onClick={onClickAdd}>
                    {buttonText}
                </Button>
                }
            </div>
        </div>
    )
}

export default AppBarContent