import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'

const useStyles = makeStyles()((theme) => ({
    root: {
        zIndex: theme.zIndex.tooltip + 50,
        backgroundColor: theme.palette.common.white,
    },
    absolute: {
        position: 'absolute',
    },
}))

export default function Loader({ open = false, fixed = false }) {
    const { classes: styles } = useStyles()

    return (
        <Backdrop className={clsx(styles.root, !fixed && styles.absolute)} open={open}>
            <CircularProgress size={50} color="primary" />
        </Backdrop>
    )
}
