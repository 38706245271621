import { Box } from '@mui/material'
import clsx from 'clsx'

import Typography from '@/components/common/Typography'
import { Button } from '@/components/common/Buttons'
import RadioGroup from '@/components/common/Inputs/RadioGroup'

import useCrud from '@/hooks/useCrud'
import { useAuth } from '@/hooks/useAuth'
import { useForm } from '@/hooks'
import { SignUpComponentsProps } from '../../types'
import { getInitialValues, useScheme } from './validations'
import { SignUpValues } from './types'
import { DELIVERY_RADIUS } from '@/constants'
import { stringToBoolean } from '@/utils'
import { useStyles } from './styles'
import { API_SIGN_UP } from '@/constants/routes'
import { CourierRegistrationSteps } from '@/common/enums'
import { DELIVERY_RADIO_BOX, SATURDAYS_BOX, WEEKDAYS_BOX, testProp } from '@/constants/testIds'
import useFetch from '@/hooks/useFetch'
import { PickupTime } from '@/common/types'
import PickupTimes from '@/components/generics/PickupTimes'
import { useEffect } from 'react'
import LoaderSmall from '@/components/common/Loader/LoaderSmall'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'


const ScheduleStep = (props: SignUpComponentsProps) => {
    const { classes } = useStyles()
    const schema = useScheme()
    const [apiRequest, apiState] = useCrud('POST')
    const [pickupTimes, loadingPickupTimes] = useFetch<{ records: PickupTime[] }>(API_SIGN_UP.GET_DEFAUL_PICKUP_TIMES, { isDefault: true })
    const { user } = useAuth()
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })

    const onSubmit = async (values: SignUpValues) => {
        try {
            const response = await apiRequest(API_SIGN_UP.SET_PREFERRED_WORKING_HOURS, values)
            if (response) {
                props.nextStep(CourierRegistrationSteps.GENERAL_INFORMATION)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const { values, errors, submitCount, setFieldValue, handleSubmit } = useForm<SignUpValues>(
        schema,
        getInitialValues(),
        onSubmit,
    )

    useEffect(() => {
        if (pickupTimes?.records) {
            setFieldValue('workingHours', pickupTimes?.records)
        }
    }, [pickupTimes])

    return (
        <Box className={classes.scheduleStep} px={isSmallScreen ? 1 : 5}>
            <Box textAlign='center'>
                <Box mt={3}>
                    <Typography variant='h4' gutter='label'>Set up your preferred working hours</Typography>
                </Box>
                <Typography variant='body1'>
                    Please set the hours you are able to deliver for Medzoomer.
                </Typography>
            </Box>
            {loadingPickupTimes ? (
                <LoaderSmall />
            ) : (
                <form action="#" onSubmit={handleSubmit} className={clsx('w-100', 'd-flex', 'flex-column', 'align-right')}>
                    <Box className={classes.hoursBlock} py={2}>
                        <PickupTimes pickupTimes={values.workingHours} onChange={(value) => setFieldValue('workingHours', value)} />
                        {submitCount >= 1 && !!values.workingHours.every((item) => !item.days.length) &&
                            <Typography noWrap={true} color="error" variant="caption">
                                Select at least one option
                            </Typography>
                        }
                        {/* <FormControl component="fieldset" variant="standard">
                        <FormGroup row>
                            {WORKING_HOURS.map((item, index) => (
                                <FormControlLabel
                                    key={item.key}
                                    control={
                                        <Checkbox
                                            {...testProp(index.toString())}
                                            color='secondary'
                                            checked={values.workingHours.includes(item.key as never)}
                                            value={item.key}
                                            onChange={(e) => {
                                                const val = e.target.value
                                                const isChecked = e.target.checked
                                                if (isChecked) {
                                                    setFieldValue('workingHours', [...values.workingHours, val])
                                                } else {
                                                    setFieldValue('workingHours', values.workingHours.filter((item) => item !== val))
                                                }
                                            }}
                                        />
                                    }
                                    label={item.value}
                                />
                            ))}
                        </FormGroup>
                        {errors?.workingHours &&
                            <Typography color="error" variant="caption">
                                Select at least one option
                            </Typography>
                        }
                    </FormControl> */}
                    </Box>
                    <Box className={classes.hoursBlock} pb={2} pt={3}>
                        <Typography variant='body1'>Are you available on weekdays? *</Typography>
                        <RadioGroup
                            id={WEEKDAYS_BOX}
                            items={[]}
                            value={values.availableWeekdays}
                            error={errors?.availableWeekdays || null}
                            onChange={(e) => {
                                const val = stringToBoolean(e.target.value)
                                setFieldValue('availableWeekdays', val)
                            }}
                        />
                    </Box>
                    <Box className={classes.hoursBlock} pb={2} pt={3}>
                        <Typography variant='body1'>Are you available on Saturdays? *</Typography>
                        <RadioGroup
                            id={SATURDAYS_BOX}
                            items={[]}
                            value={values.availableSaturday}
                            error={errors?.availableSaturday || null}
                            onChange={(e) => {
                                const val = stringToBoolean(e.target.value)
                                setFieldValue('availableSaturday', val)
                            }}
                        />
                    </Box>

                    <Box className={classes.hoursBlock} pb={3} pt={3}>
                        <Typography variant='body1'>What radius are you comfortable servicing your store?</Typography>
                        <RadioGroup
                            id={DELIVERY_RADIO_BOX}
                            items={DELIVERY_RADIUS}
                            value={values.deliveryRadius}
                            error={errors?.deliveryRadius || null}
                            onChange={(e: React.ChangeEvent<{ value: string }>) => {
                                const val = e.target.value
                                setFieldValue('deliveryRadius', val)
                            }}
                        />
                    </Box>
                    <div className='flex-center'>
                        <Button
                            className={clsx('rounded-button', classes.continueButton)}
                            disabled={apiState.loading || !user}
                            variant="contained"
                            color="secondary"
                            pending={apiState.loading}
                            type="submit"
                        >
                            Continue
                        </Button>
                    </div>
                </form>
            )}
        </Box>
    )
}

export default ScheduleStep
