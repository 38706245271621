import { makeStyles } from 'tss-react/mui'
import MUITypography from '@mui/material/Typography'
import clsx from 'clsx'
import { Variant } from '@mui/material/styles/createTypography'
import { TypographyWeightType } from '@/common/types/types'
import { SxProps } from '@mui/material'

const customColors = ['success', 'warning', 'disabled', 'white', 'label', 'active', 'hint', 'placeholder', 'link']
const customVariant = ['h1Big', 'h1Medium']

const useStyles = makeStyles()((theme) => ({
    root: {
        '& ul': { margin: '3px 0', paddingLeft: 22 },
        '& a': { color: theme.palette.primary.main, textDecoration: 'none' },
        '& img': { maxWidth: '100%' },
        '& p': { margin: '5px 0 10px' },
    },

    // colors
    success: { color: theme.palette.success.main },
    warning: { color: theme.palette.warning.main },
    disabled: { color: theme.palette.text.disabled },
    white: { color: theme.palette.common.white },
    black: { color: '#272727' },
    label: { color: theme.palette.grey[300] },
    active: { color: theme.palette.primary.main },
    hint: { color: theme.palette.text.disabled },
    placeholder: { color: theme.palette.text.disabled },
    link: { color: '#2445a7' },

    // Line height
    'lh-1.6': { lineHeight: 1.6 },
    lhOff: { lineHeight: 1.1 },
    icon: { lineHeight: 0 },
    uppercase: { textTransform: 'uppercase' },

    // Custom variants
    //h1Big: { ...theme.typography.h1Big },
    //h1Medium: { ...theme.typography.h1Medium },

    // Gutter
    'label-gt': { marginBottom: theme.spacing(1) },
    'title-gt': { marginBottom: theme.spacing(1.3) },
    'bottom2-gt': { marginBottom: theme.spacing(2.5) },
    'bottomSpace-gt': { marginBottom: theme.spacing(3) },
    'topSpace-gt': { marginTop: theme.spacing(3) },
    'verticalSpace-gt': { marginTop: theme.spacing(1.25), marginBottom: theme.spacing(1.25) },
    'verticalSpaceBig-gt': { marginTop: theme.spacing(3), marginBottom: theme.spacing(3) },
    'bottomSpaceBig-gt': { marginBottom: theme.spacing(5) },
    'horizontalSpace-gt': { marginLeft: theme.spacing(1.5), marginRight: theme.spacing(1.5) },
    'horizontalSpaceInputError-gt': { marginLeft: theme.spacing(2), marginRight: theme.spacing(2) },

    // fontFamily
    manrope: { fontFamily: theme.typography.fontFamily },
    lexend: { fontFamily: 'Lexend' },

    //weight
    300: { fontWeight: theme.typography.fontWeightLight },
    400: { fontWeight: theme.typography.fontWeightRegular },
    500: { fontWeight: theme.typography.fontWeightMedium },
    bold: { fontWeight: theme.typography.fontWeightBold },
    normal: { fontWeight: 'normal' },

    pointer: { cursor: 'pointer' },

    //decoration
    crossed: { textDecoration: 'line-through' },

    //align
    'text-center': { textAlign: 'center' },
    'text-right': { textAlign: 'right' },
    'text-left': { textAlign: 'left' },

    //word break
    'word-break-all': { wordBreak: 'break-all' },
    'word-break-word': { wordBreak: 'break-word' },
}))

interface TypographyProps {
    color?: string
    variant?: Variant
    className?: string
    disableLineHeight?: boolean
    uppercase?: boolean
    icon?: boolean
    fontFamily?: string
    weight?: TypographyWeightType
    lineHeight?: string
    gutter?: string
    title?: string
    align?: string
    pointer?: boolean
    children?: React.ReactNode | string
    noWrap?: boolean
    wordBreak?: 'all' | 'word'
    sx?: SxProps
}

export default function Typography(props: TypographyProps) {

    const {
        color = '',
        variant = 'body2',
        className,
        pointer,
        fontFamily,
        weight,
        disableLineHeight,
        uppercase,
        title,
        lineHeight,
        icon,
        gutter,
        children,
        align,
        noWrap,
        wordBreak,
    } = props

    const { classes } = useStyles()
    const isCustomColor = customColors.includes(color)
    const isCustomVariant = customVariant.includes(variant)

    return (
        <MUITypography
            color={isCustomColor ? 'initial' : color ? color : 'initial'}
            variant={variant}
            className={clsx(classes.root, className, pointer && classes['pointer'], fontFamily && classes[fontFamily], weight && classes[weight], {
                [classes[color]]: isCustomColor,
                [classes[variant]]: isCustomVariant,
                [classes.lhOff]: disableLineHeight,
                [classes.uppercase]: uppercase,
                [classes['title-gt']]: title,
                [classes[`lh-${lineHeight}`]]: lineHeight,
                [classes.icon]: icon,
                [classes[`${gutter}-gt`]]: !!gutter,
                [classes[`text-${align}`]]: align === 'center',
                [classes[`word-break-${wordBreak}`]]: wordBreak,
            })}
            noWrap={noWrap}
        >
            {children}
        </MUITypography>
    )
}
