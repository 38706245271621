import { VideoPlayers } from '@/common/enums'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'
import ReactPlayerVimeo from 'react-player/vimeo'
import ReactPlayerWistia from 'react-player/wistia'

interface VideoPlayerProps {
    url: string;
    onEnded?: () => void;
    controls?: boolean;
    playerType?: VideoPlayers;
}

const VideoPlayer = (props: VideoPlayerProps) => {
    const { url, controls, playerType = VideoPlayers.NATIVE, onEnded } = props
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })

    return (
        <>
            {playerType === VideoPlayers.VIMEO &&
                <ReactPlayerVimeo
                    width={isSmallScreen ? '100%' : '640px'}
                    height={isSmallScreen ? '100%' : '360px'}
                    playing={true}
                    volume={0.5}
                    controls={controls}
                    url={url}
                    onEnded={onEnded}
                />
            }
            {playerType === VideoPlayers.WISTIA &&
                <ReactPlayerWistia
                    playing={true}
                    volume={0.5}
                    controls={controls}
                    url={url}
                    onEnded={onEnded}
                />
            }
            {playerType === VideoPlayers.NATIVE &&
                <video
                    src={url}
                    preload="auto"
                    controls={true}
                    playsInline
                    onLoadStart={(e) => e.currentTarget.volume = 0.5}
                    autoPlay
                    style={{ width: isSmallScreen ? '100%' : '640px', height: isSmallScreen ? '100%' : '360px' }}
                    onEnded={onEnded}
                >
                    <source src={url} type="video/mp4" />
                </video>
            }
        </>
    )
}

export default VideoPlayer