import { makeStyles } from 'tss-react/mui'
import Typography from '../../Typography'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import MUISelect, { SelectChangeEvent } from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import Chip from '@mui/material/Chip'
import Box from '@mui/material/Box'
import CancelIcon from '@mui/icons-material/Cancel'
import InputLabel from '@mui/material/InputLabel'
import { useId } from 'react'

interface SelectProps {
    label?: string
    placeholder?: string
    value?: string | undefined
    options?: Array<{ key: string | number, value: string | number }>
    disabled?: boolean
    name?: string
    style?: any
    error?: string | null
    variant?: 'standard' | 'outlined' | 'filled'
    startAdornment?: React.ReactNode
    allowEmpty?: boolean
    multiple?: boolean
    customProps?: any
    id?: string
    onChange?: (_event: SelectChangeEvent) => void
    handleDelete?: (_item: string) => void
}

const ITEM_HEIGHT = 53
const ITEM_PADDING_TOP = 8
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
            minWidth: 120,
            maxWidth: 250,
        },
    },
}

const Select = ({ id, label, error, value, options, disabled, name, startAdornment, placeholder, style, variant='standard', allowEmpty = false, multiple = false, handleDelete, onChange, ...customProps }: SelectProps) => {
    const { classes } = useStyles()
    const labelId = `custom-select-mz-${useId()}`

    const getLabel = (key: string) => {
        const option = options?.find((option) => option.key === key)
        return option?.value
    }

    const getRenderValue = (selected: string | string[]) => {
        if (Array.isArray(selected)) {
            return <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, width: 250, position: 'relative' }}>
                {selected.map((value) => (
                    <Chip
                        color='secondary'
                        size='small'
                        key={value}
                        clickable
                        label={getLabel(value)}
                        onDelete={handleDelete && (() => handleDelete(value))}
                        deleteIcon={
                            <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                            />
                        }
                    />
                ))}
            </Box>
        }
        return getLabel(selected)
    }

    return (
        <div>
            {label && (
                <Typography weight="500" color="placeholder" gutter="label" variant="body2">
                    {label}
                </Typography>
            )}
            <FormControl fullWidth color='secondary'>
                { placeholder && <InputLabel className={classes.label}>{placeholder}</InputLabel> }
                <MUISelect
                    variant={variant}
                    MenuProps={MenuProps}
                    labelId={labelId}
                    id={id || labelId}
                    label={placeholder}
                    sx={style}
                    disabled={disabled}
                    name={name || label}
                    startAdornment={startAdornment}
                    value={value}
                    autoWidth
                    className={classes.select}
                    onChange={onChange}
                    multiple={multiple}
                    renderValue={getRenderValue}
                    {...customProps}
                >
                    {allowEmpty && <MenuItem value={multiple ? [] : ''}>--</MenuItem>}
                    {options?.map((option, index) => (
                        <MenuItem key={index} value={option.key} classes={{ selected: classes.itemSelected }}>
                            { !!multiple &&
                            <Checkbox color='secondary' checked={Boolean(value && value.indexOf(option.key as string) > -1)} />
                            }
                            <ListItemText id={option.key as string} primary={option.value} />
                        </MenuItem>
                    ))}
                </MUISelect>
            </FormControl>
            <div>
                { !!error &&
                <Typography gutter='horizontalSpaceInputError' color="error" variant="caption" align='left'>
                    {typeof error === 'string' ? error : label || 'Error' }
                </Typography>
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles()(() => ({
    select: {
        minHeight: '1.120em',
        '&.Mui-disabled': {
            background: '#f7f8fa',
        },
        backgroundColor: '#fff',
        '&:before': {
            borderBottom: '1px solid rgba(0, 0, 0, .14)',
        },
    },
    label: {
        fontSize: 15,
        color: 'rgba(0, 0, 0, .40)',
    },
    itemSelected: {
        backgroundColor: 'rgba(0, 108, 240, 0.4) !important',
        color: '#fff !important',
    },
}))

export default Select