import * as Yup from 'yup'

export const initialValues = {
    password: '',
    confirmPassword: '',
}

export const getInitialValues = () => {
    return Object.assign(
        {},
        initialValues,
    )
}

export const useScheme = () => {
    return Yup.object().shape({
        password: Yup.string().required('New password is required')
            .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
                'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character',
            ),
        confirmPassword: Yup.string().required('Confirm new password is required')
            .min(8, 'Must be minimun 8 digits')
            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
    })
}