import ModalDialog from './styles'
import { Slide } from '@mui/material'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import ModalHeader from './Header'
import { useStyles } from './styles'

import { DialogProps } from './types'
import Typography from '../Typography'
import clsx from 'clsx'

const PaperProps = { square: true, sx: { top: 20 } }

const Modal = ({ open, center, onClose, secondaryActions, children, actions, title, disableEscapeKeyDown, fullWidth = true, maxWidth='sm', tittleColor='inherit' }: DialogProps) => {
    const { classes } = useStyles()
    return (
        <ModalDialog
            open={open}
            className={clsx(center && classes.center)}
            disableEscapeKeyDown={disableEscapeKeyDown}
            fullWidth={fullWidth}
            maxWidth={maxWidth}
            PaperProps={PaperProps}
            TransitionComponent={Slide}
        >
            {title &&
            <ModalHeader id="customized-dialog-title" secondaryActions={secondaryActions} onClose={onClose}>
                <div>
                    <Typography variant='h6' color={tittleColor}>{title}</Typography>
                </div>
            </ModalHeader>
            }
            <DialogContent dividers>
                {children}
            </DialogContent>

            {actions && <DialogActions>{actions}</DialogActions>}
        </ModalDialog>
    )
}

export default Modal