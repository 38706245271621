import Grid from '@mui/material/Grid'
import Typography from '@/components/common/Typography'
import { TextField } from '@/components/common/Inputs'
import { FileType } from '@/common/enums'
import { AuthUser, UploadCourierFiles } from '@/common/types'
import FileInputProfile from '@/components/generics/FileInputProfile'
import { useStyles } from '../styles'
import { ProfileValues } from '../types'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'
import { FormikErrors } from 'formik'

interface Props {
    files: UploadCourierFiles
    getFieldProps: any
    values: ProfileValues
    user?: AuthUser | null
    errors: FormikErrors<ProfileValues>
    handleSelectFile: (_file: React.ChangeEvent<HTMLInputElement>, _fileType: string) => Promise<void>
}

const Vehicle = ({ files, getFieldProps, values, user, errors, handleSelectFile }: Props) => {
    const { classes } = useStyles()
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })

    return (
        <div >
            <Grid container spacing={2} rowSpacing={4}>
                <Grid container spacing={2} rowSpacing={4} marginBottom={3} columnSpacing={4}>
                    <FileInputProfile label="Driver's License *" errors={errors.licensePicture} profile={values.licensePicture} file={files.licensePicture} url={user?.license} fileType={FileType.COURIER_LICENSE} handleSelectFile={handleSelectFile}/>
                    <FileInputProfile label='Car Insurance Card *' errors={errors.insurancePicture} profile={values.insurancePicture} file={files.insurancePicture} url={user?.insurancePicture} fileType={FileType.COURIER_INSURANCE} handleSelectFile={handleSelectFile}/>
                </Grid>
                <Typography className='text-left' variant='h5' >
                    Vehicle information
                </Typography>
                <Grid container spacing={2} rowSpacing={4} marginBottom={3} columnSpacing={4}>
                    <Grid item xs={isSmallScreen?12:5}>
                        <TextField
                            className={classes.textField}
                            label='Make *'
                            {...getFieldProps('make')}
                        />
                    </Grid>
                    <Grid item xs={isSmallScreen?8:4}>
                        <TextField
                            className={classes.textField}
                            label='Model *'
                            {...getFieldProps('carModel')}
                        />
                    </Grid>
                    <Grid item xs={isSmallScreen?4:3}>
                        <TextField
                            className={classes.textField}
                            label='Year *'
                            {...getFieldProps('carYear')}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} rowSpacing={4} marginBottom={3} columnSpacing={4}>
                    <FileInputProfile label="Front *" errors={errors.front} profile={values.front} file={files.front} url={user?.vehicle?.frontPicture} fileType={FileType.COURIER_VEHICLE_FRONT} handleSelectFile={handleSelectFile}/>
                    <FileInputProfile label='Back *' errors={errors.back} profile={values.back} file={files.back} url={user?.vehicle?.backPicture} fileType={FileType.COURIER_VEHICLE_BACK} handleSelectFile={handleSelectFile}/>
                    <FileInputProfile label="Left Side *" errors={errors.leftSide} profile={values.leftSide} file={files.leftSide} url={user?.vehicle?.leftPicture} fileType={FileType.COURIER_VEHICLE_LEFT} handleSelectFile={handleSelectFile}/>
                    <FileInputProfile label='Right Side *' errors={errors.rightSide} profile={values.rightSide} file={files.rightSide} url={user?.vehicle?.rightPicture} fileType={FileType.COURIER_VEHICLE_RIGHT} handleSelectFile={handleSelectFile}/>
                </Grid>
            </Grid>
        </div>
    )
}

export default Vehicle
