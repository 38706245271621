import InputAdornment from '@mui/material/InputAdornment'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'

const EyePassword = ({ type, onSetIcon }: { type: string, onSetIcon: any }) => {
    const handleVisibilityPassword = () => onSetIcon(type === 'password' ? 'text' : 'password')

    return (
        <InputAdornment
            onClick={handleVisibilityPassword}
            position="end"
            style={{ cursor: 'pointer' }}
        >
            {type === 'password' ? <VisibilityOffOutlinedIcon color='action' /> : <VisibilityOutlinedIcon />}
        </InputAdornment>
    )
}

export default EyePassword