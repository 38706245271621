import Typography from '@/components/common/Typography'
import { Button } from '@/components/common/Buttons'
import { IconEmail } from '@/components/svg/Icons/IconEmail'

import styles from '../../styles.module.css'
  
interface FunctionParams {
    email: string
}

interface Props {
    email: string
    pending: boolean
    onResend: (_arg: FunctionParams) => void
}

const SuccessSent = ({ email, pending, onResend }: Props) => {
    return (
        <div className={styles.successSent}>
            <IconEmail />
            <div>
                <Typography variant="h3" align='center'>Check your email</Typography>
                <Typography align='center' variant='body2' color='placeholder' className={styles.subtitle}>
                    We have sent instructions to
                    <span className={styles.email}> {email} </span>
                </Typography>
            </div>

            <div className={styles.resend}>
                <Typography color='placeholder' variant="body2">
                    Didn’t receive the email?
                </Typography>
                <Button
                    className={styles.buttonResend}
                    variant='text'
                    color='info'
                    pending={pending}
                    disabled={pending}
                    onClick={() => onResend({ email })}
                >
                    Resend
                </Button>
            </div>
        </div>
    )
}

export default SuccessSent