import TextField, { TextFieldProps } from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import clsx from 'clsx'
import Typography from '../../Typography'
import { TimeType } from '@/common/types/types'
import { makeStyles } from 'tss-react/mui'
import { formatDate } from '@/utils/dates'
import { Dayjs } from 'dayjs'

interface Props {
    value: TimeType
    disabled?: boolean
    className?: string;
    label?: string
    labelDate?: string
    name: string
    formatReturnDate?: string
    variant?: 'standard' | 'outlined' | 'filled'
    onChange: (_newValue: TimeType) => void
}

const useStyles = makeStyles()(() => ({
    root: {
        width: '100%',
        backgroundColor: '#fff',
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
        },
    },
}))

const DayPicker = ({ value = null, label = '', labelDate = '', variant='standard', formatReturnDate = 'YYYY-MM-DD', onChange, disabled, className }: Props) => {
    const { classes: styles } = useStyles()

    return (
        <>
            {label && (
                <Typography weight="500" color="placeholder" gutter="label" variant="body2">
                    {label}
                </Typography>
            )}
            <DatePicker
                disabled={disabled}
                label={labelDate}
                value={value}
                inputFormat="MM-DD-YYYY"
                className={clsx(styles.root)}
                onChange={date => onChange(formatDate(date as Dayjs, formatReturnDate))}
                renderInput={
                    (params: JSX.IntrinsicAttributes & TextFieldProps) => <TextField size='small' variant={variant} {...params} placeholder='MM-DD-YYYY' className={clsx(styles.root, className)} />
                }
            />
        </>
    )
}

export default DayPicker
