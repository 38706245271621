import { AuthUser } from '@/common/types'
import Typography from '@/components/common/Typography'
import pendingApplication from '@/assets/icon/ico-application-pending.svg'
import Grid from '@mui/material/Grid'
import Paper from '@/components/generics/Paper'
import { useAuth } from '@/hooks/useAuth'
import { CourierStatus } from '@/common/enums'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'

import { getTexts } from './helpers'
import { useStyles } from './styles'


interface Props {
    courier?: AuthUser | null
}

const PendingStatus = ({ courier }: Props) => {
    const { classes } = useStyles()
    const text = getTexts(courier?.status, courier?.checkrStatus)
    const { user } = useAuth()
    const isCourierIncomplete = user?.status !== CourierStatus.ACTIVE
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })

    return (
        <>
            {isCourierIncomplete
                && (
                    <div className='page-content'>
                        <Grid container spacing={3} marginBottom={-3}>
                            <Grid item xs={12}>
                                <Paper px={isSmallScreen ? 2 : 10} py={4}>
                                    <div className={classes.pendingHeader}>
                                        <img src={pendingApplication} />
                                        <div className={classes.textBlock}>
                                            <Typography className={classes.titlePending}>
                                                {text.title}
                                            </Typography>
                                            <Typography className={classes.subtitlePending}>
                                                {text.text}
                                                <span className={classes.email}> {courier?.email}</span>
                                                <br />
                                                {text.text2}
                                            </Typography>
                                        </div>
                                    </div>
                                </Paper>
                            </Grid>
                        </Grid>
                    </div>
                )
            }
        </>
    )
}

export default PendingStatus