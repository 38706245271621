import SvgIcon from '@/components/common/SvgIcon'
import { PropsSvg } from '../types'

const IconSearch = (props: PropsSvg) => {
    const { width = 18, height = 18 } = props

    return (
        <SvgIcon width={width} height={height} viewBox="0 0 18 14">
            <g fill="none" fillRule="evenodd" transform="translate(-1 -1)">
                <path d="M0 0h16v16H0z" opacity=".101"/>
                <circle cx="7.5" cy="7.5" r="5.5" stroke="#999" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3"/>
                <path stroke="#999" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.3" d="M14 14l-2.5-2.5"/>
            </g>
        </SvgIcon>
    )
}

export default IconSearch
