import { FC } from 'react'
import { useStyles } from '../../styles'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { Steps } from '@/common/types'
import clsx from 'clsx'

const InfoStep: FC<{data: Steps}> = ({
    data,
}) => {
    const { classes } = useStyles()

    return (
        <Box sx={{ backgroundColor: 'white' }}>
            <div className={classes.modal}>
                <div className={classes.body}>
                    <div className={clsx('text-center', 'w-100')}>
                        <Typography className={classes.title}>
                            {data.title}
                        </Typography>
                        <Typography className={classes.desc}>
                            {data.desc}
                        </Typography>
                        <img className={classes.stepImg} src={data.img} alt="instruction" />
                    </div>
                </div>
            </div>

        </Box>
    )
}

export default InfoStep
