import { useEffect, useState } from 'react'
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { TextField } from '@/components/common/Inputs'

import { flattenAddressComponents, getFormattedAddress } from '@/utils'
import { AddressObject, Coordinate } from '@/common/types'
import Typography from '@/components/common/Typography'
import { Box } from '@mui/material'
import styles from './styles.module.css'
import { testProp } from '@/constants/testIds'

interface Props {
    label?: string
    inputVariant?: 'standard' | 'outlined' | 'filled'
    error?: string
    value: AddressObject
    disabled?: boolean
    handleSelectAddress: (_value: AddressObject) => void
    inputPlaceHolder?: string
}

const PlacesAutocomplete = ({ label, value, error, inputVariant = 'standard', disabled = false, handleSelectAddress, inputPlaceHolder }: Props) => {
    const [location, setLocation] = useState<string>(getFormattedAddress(value))

    const getLocation = (locationAddress: Coordinate) => {
        if (!locationAddress) return
        return {
            latitude: locationAddress.lat.toString(),
            longitude: locationAddress.lng.toString(),
        }
    }

    const handleChangeLocation = (e: string) => setLocation(e)

    const handleSelectSuggest = async (address: string, placeID: string) => {
        const geocodedPrediction = await getGeometryByAddress(address)
        if (geocodedPrediction && geocodedPrediction.location) {
            const geometricPoints = getLocation(await geocodedPrediction.geometry)
            handleSelectAddress({
                ...flattenAddressComponents(geocodedPrediction.location),
                ...geometricPoints,
                googleId: placeID,
                formattedAddress: address,
            })
        }
    }

    const getGeometryByAddress = async (address: string) => {
        return geocodeByAddress(address)
            .then(results => {
                return {
                    location: results[0].address_components,
                    geometry: getLatLng(results[0]),
                    placeId: results[0].place_id,
                }
            })
            .then(res => res)
            .catch(error => console.error('Error', error))
    }

    useEffect(() => {
        setLocation(value.formattedAddress || value.fullAddress || '')
    }, [value])

    return (
        <div className='w-100'>
            <GooglePlacesAutocomplete
                value={location}
                onChange={handleChangeLocation}
                onSelect={handleSelectSuggest}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps /*loading*/ }) => (
                    <div>
                        <TextField
                            {...getInputProps({
                                placeholder: inputPlaceHolder,
                            })}
                            label={label}
                            variant={inputVariant}
                            disabled={disabled}
                        />
                        <div {...testProp('ADDRESS_BOX')} className={styles.autocompleteDropdownContainer}>
                            {/* {loading && <Box sx={{ p: 1 }}><span>Loading...</span></Box>} */}
                            {suggestions.map((suggestion, index) => {
                                const className = suggestion.active
                                    ? styles.suggestionItemActive
                                    : styles.suggestionItem
                                return (
                                    <div
                                        {...getSuggestionItemProps(suggestion, {
                                            className,
                                        })}
                                        style={{ padding: 10, cursor: 'pointer' }}
                                        key={index}
                                    >
                                        <span>{suggestion.description}</span>
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                )}
            </GooglePlacesAutocomplete>
            {
                error && (
                    <Box ml={2}>
                        <Typography noWrap={true} color="error" variant="caption">
                            {typeof error === 'string' ? error : label || 'Error'}
                        </Typography>
                    </Box>
                )
            }
        </div>
    )
}

export default PlacesAutocomplete
