import * as Yup from 'yup'

export const initialValues = {
    licensePicture: '',
    insurancePicture: '',
    make: '',
    carModel: '',
    carYear: '',
    front: '',
    back: '',
    leftSide: '',
    rightSide: '',
    autoPolicy: '',
}

export const getInitialValues = () => {
    return Object.assign(
        {},
        initialValues,
    )
}

export const useScheme = () => {
    return Yup.object().shape({
        licensePicture: Yup.string().required('Driver\'s License is not allowed to be empty'),
        insurancePicture: Yup.string().required('Car Insurance Card is not allowed to be empty'),
        make: Yup.string().required('Make is required'),
        carModel: Yup.string().required('Model is required'),
        carYear: Yup.string().required('Car year is required').matches(/^[0-9]+$/, 'This field can only contain numeric values').matches(/^2[0][0][8-9]|2[0][1-3][0-9]/, 'Year should be 2008 or newer'),
        front: Yup.string().required('Vehicle Front Picture is not allowed to be empty'),
        back: Yup.string().required('Vehicle Back Picture is not allowed to be empty'),
        rightSide: Yup.string().required('Vehicle Right Picture is not allowed to be empty'),
        leftSide: Yup.string().required('Vehicle Left Picture is not allowed to be empty'),
        autoPolicy: Yup.boolean().required('Please, select one option'),
    })
}
