import { Widget } from '@typeform/embed-react'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const Typeform = ({ onSubmit, height='300px' }) => {
    return <Widget
        id="j4eUHliu"
        style={{ width: '100%', height }}
        className="my-form"
        onSubmit={async (event: any) => {
            await onSubmit(event)
        }}
    />
}
export default Typeform
