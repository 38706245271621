import { useCallback, useEffect, useState } from 'react'
import { Hub } from '@aws-amplify/core'
import clsx from 'clsx'
import { Box } from '@mui/system'

import Typography from '@/components/common/Typography'
import { Button } from '@/components/common/Buttons'
import CodeInput from '@/components/common/Inputs/CodeInput'
import ErrorAuthMessage from '../../../components/Error'

import { useStyles } from './styles'
import { SignUpComponentsProps } from '../../types'
import { confirmSignUp, resendConfirmationCode, signIn } from '@/services/amplify'
import { useSignUpStore } from '@/store'
import { useHttp } from '@/services/app'
import { AppContextType } from '@/common/types'
import { useAppContext } from '@/contexts/AppContext'
import { NOT_AUTHORIZED_EXCEPTION } from '@/constants'
import { CourierRegistrationSteps } from '@/common/enums'
import { useNavigate } from 'react-router-dom'
import { ROUTE_LOGIN } from '@/constants/routes'

import * as Sentry from '@sentry/react'

const SignInForm = ({ nextStep }: SignUpComponentsProps) => {
    const { classes } = useStyles()
    const navigate = useNavigate()

    const http = useHttp()
    const application = useAppContext() as AppContextType
    const [code, setCode] = useState<string>('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string | null>(null)
    const { user, email, tmp } = useSignUpStore((state) => state)

    const onSubmit = async () => {
        if ((user || email) && code && code.length === 6) {
            setError(null)
            setLoading(true)
            try {
                await confirmSignUp(user?.getUsername() || email as string, code)
                if (email && tmp) {
                    await signIn(email, tmp)
                    await loginToApplication()
                }
            } catch (error) {
                if (error?.code === NOT_AUTHORIZED_EXCEPTION) {
                    return loginToApplication()
                }
                setError(error.code || error.message || error)
            } finally {
                setLoading(false)
            }
        }
    }

    const loginToApplication = useCallback(async () => {
        const user = await http.getMe()
        console.log(user)
        if (user) {
            application.setUser(user)
            Sentry.setUser({ email: user.email })
        }
        nextStep(user.registrationStep as CourierRegistrationSteps)
    }, [http])

    const resendCode = async () => {
        if (!user && !email) return
        setLoading(true)
        try {
            await resendConfirmationCode(user?.getUsername() || email as string)
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const listenToAutoSignInEvent = () => {
        Hub.listen('auth', ({ payload }) => {
            const { event } = payload
            if (event === 'autoSignIn') {
                //const user = payload.data;
                loginToApplication()
            }
            // assign user
            //   } else if (event === 'autoSignIn_failure') {
            //     // redirect to sign in page
            //   }
        })
    }

    useEffect(() => {
        listenToAutoSignInEvent()
    }, [])

    useEffect(() => {
        if (!user && !email) {
            navigate(ROUTE_LOGIN.path)
        }
    }, [user, email])

    return (
        <div className={classes.mainContent}>
            {/* <div className={classes.backArrowContainer}>
                <BackButton />
            </div> */}
            <div className={clsx('flex-center', 'flex-column', 'w-100', 'height-100')}>
                <Typography variant="h4" align='center' gutter='bottom2'>Confirm your email address</Typography>
                <Box display='flex' justifyContent='center' flexWrap='wrap' gap={1}>
                    <Typography variant="body2" weight='400' align='center'>We sent a verification code to</Typography>
                    <Typography variant="body2" weight='400' color='primary' align='center'>{user?.getUsername() || email}</Typography>
                </Box>
                <Typography variant="body2" align='center'>Please enter the code below to continue</Typography>
                <div className={clsx('w-100', 'd-flex', 'flex-column', 'align-center')}>
                    <CodeInput
                        length={6}
                        onChange={(value) => setCode(value)}
                    />

                    <Box sx={{ mt: 2, textAlign: 'center' }}>
                        {error && <ErrorAuthMessage error={error} />}
                        <Button
                            variant="contained"
                            size='large'
                            color='secondary'
                            className={clsx('rounded-button', classes.button)}
                            pending={loading}
                            disabled={Boolean(loading || !code || (code && code.length < 6))}
                            onClick={onSubmit}
                        >
                            Continue
                        </Button>
                    </Box>
                </div>
            </div>
            <div className='authFooter'>
                <Typography variant='body1'>Didn&apos;t get a verification code?</Typography>
                <Button disabled={loading} onClick={resendCode} variant='text' color='secondary' size='small'>
                    Re-send
                </Button>
            </div>
        </div>
    )
}

export default SignInForm
