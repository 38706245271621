import { TaxClassificationTypes } from '@/common/enums'
import * as Yup from 'yup'

export const initialValues = {
    name: '',
    businessName: '',
    payeeCode: '',
    reportingCode: '',
    accountNumbers: '',
    securityNumber: '',
    identificationNumber: '',
    taxClassificationTypeId: '',
    taxClassificationNumber: '',
}

export const getInitialValues = () => {
    return Object.assign(
        {},
        initialValues,
    )
}

export const useScheme = () => {
    return Yup.object().shape({
        name: Yup.string().required('Name is required'),
        // payeeCode: Yup.string().min(1).max(13).required('Payee code is required'),
        // reportingCode: Yup.string().required('Fatca is required'),
        taxClassificationTypeId: Yup.string().required('Tax classification type is required'),
        securityNumber: Yup.string().matches(/^[0-9]{9}$/, 'SSN should be 9 digits'),
        identificationNumber: Yup.string().matches(/^[0-9]{9}$/, 'EIN should be 9 digits'),
    })
}