import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
    mainContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingInline: theme.spacing(10),
        [theme.breakpoints.down('md')]: {
            paddingInline: theme.spacing(1),
        },
    },
    backArrowContainer: {
        width: '100%',
    },
    inputWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: 20,
        padding: '35px 0',
        width: '100%',
        justifyItems: 'center',
    },
    continueButton: {
        padding: '15px 40px',
    },
    itemAgreement: {
        position: 'relative',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.02)',
        },
        'div:first-of-type': {
            display: 'flex',
            alignItems: 'center',
            gap: 20,
        },
    },
    agreementSuccess: {
        border: '1px solid ' + theme.palette.success.main,
    },
    agreementPending: {
        border: '1px solid ' + theme.palette.secondary.main,
    },
    iconSuccess: {
        position: 'absolute',
        top: 5,
        left: 5,
    },
    iconAgreement: {
        fontSize: '5em',
    },
    cursorPointer: {
        cursor: 'pointer',
    },
    errorText: {
        marginTop: '25px !important',
    },
    loading: {
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        borderRadius: 15,
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
    },
}))
