import { toast, ToastOptions } from 'react-toastify'

interface UseToastOptions {
  toastOptions?: ToastOptions;
}

type UseToast = [
  (_message: string) => void,
  (_message: string) => void,
  (_message: string) => void
];

const useToast = ({
    toastOptions = {},
}: UseToastOptions = {}): UseToast => {
    const showToast = (message: string, type: 'success' | 'error' | 'warning') => {
        const toastMethod = type === 'success' ? toast.success : type === 'error' ? toast.error : toast.warning

        toastMethod(message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            ...toastOptions,
        })
    }

    const handleSuccess = (message: string) => {
        showToast(message || 'Success!', 'success')
    }

    const handleError = (message: string) => {
        showToast(message || 'Something went wrong.', 'error')
    }

    const handleWarning = (message: string) => {
        showToast(message || 'Warning!', 'warning')
    }

    return [handleSuccess, handleError, handleWarning]
}

export default useToast