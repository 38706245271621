import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@mui/material'
import Grid from '@mui/material/Grid'

import Typography from '@/components/common/Typography'
import { WORKING_HOURS } from '../types'
import { useStyles } from '../styles'
import { stringToBoolean } from '@/utils'

import { ProfileValues } from '../types'
import { FormikErrors } from 'formik'
import RadioGroup from '@/components/common/Inputs/RadioGroup'
import { DELIVERY_RADIUS } from '@/constants'
import { DELIVERY_RADIO_BOX } from '@/constants/testIds'

interface Props {
    values: ProfileValues
    errors: FormikErrors<ProfileValues>
    setFieldValue: (_field: string, _value: any, _shouldValidate?: boolean | undefined) => Promise<void> | Promise<FormikErrors<ProfileValues>>
}

const Schedule = ({ values, errors, setFieldValue }: Props) => {
    const { classes } = useStyles()

    return (
        <div >
            <Grid container spacing={2} rowSpacing={4} flexDirection={'column'}>
                {/* <div className={classes.hoursBlock}>
                    <Typography variant='body1'>Available Working Hours *</Typography>
                    <FormControl component="fieldset" variant="standard">
                        <FormGroup row>
                            {WORKING_HOURS.map((item) => (
                                <FormControlLabel
                                    key={item.key}
                                    control={
                                        <Checkbox
                                            color='secondary'
                                            checked={values.workingHours.includes(item.key as never)}
                                            value={item.key}
                                            onChange={(e) => {
                                                const val = e.target.value
                                                const isChecked = e.target.checked
                                                if (isChecked) {
                                                    setFieldValue('workingHours', [...values.workingHours, val])
                                                } else {
                                                    setFieldValue('workingHours', values.workingHours.filter((item) => item !== val))
                                                }
                                            }}
                                        />
                                    }
                                    label={item.value}
                                />
                            ))}
                        </FormGroup>
                        { errors?.workingHours &&
                        <Typography color="error" variant="caption">
                            Select at least one option
                        </Typography>
                        }
                    </FormControl>
                </div>
                <div className={classes.hoursBlock}>
                    <Typography variant='body1'>Are you available on weekdays? *</Typography>
                    <RadioGroup
                        items={[]}
                        value={values.availableWeekdays}
                        error={errors?.availableWeekdays || null}
                        onChange={(e) => {
                            const val = stringToBoolean(e.target.value)
                            setFieldValue('availableWeekdays', val)
                        }}
                    />
                </div>
                <div className={classes.hoursBlock}>
                    <Typography variant='body1'>Are you available on Saturdays? *</Typography>
                    <RadioGroup
                        items={[]}
                        value={values.availableSaturday}
                        error={errors?.availableSaturday || null}
                        onChange={(e) => {
                            const val = stringToBoolean(e.target.value)
                            setFieldValue('availableSaturday', val)
                        }}
                    />
                </div> */}

                <div className={classes.hoursBlock}>
                    <Typography variant='body1'>Delivery distance you are going to work</Typography>
                    <RadioGroup
                        id={DELIVERY_RADIO_BOX}
                        items={DELIVERY_RADIUS}
                        value={values.deliveryRadius}
                        error={errors?.deliveryRadius || null}
                        onChange={(e: React.ChangeEvent<{ value: string }>) => {
                            const val = e.target.value
                            setFieldValue('deliveryRadius', val)
                        }}
                    />
                </div>
            </Grid>
        </div>
    )
}

export default Schedule
