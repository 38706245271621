import * as Yup from 'yup'

export const initialValues = {
    name: '',
    lastName: '',
    email: '',
    phone: '',
    address: {
        number: '',
        street: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    },
    apartment: '',
    birthdate: '',
    gender: '',
    tShirt: '',
    // selfiePicture: '',
    profilePicture: '',
    licensePicture: '',
    insurancePicture: '',
    make: '',
    carModel: '',
    carYear: '',
    front: '',
    back: '',
    leftSide: '',
    rightSide: '',
    workingHours: [],
    availableWeekdays: null,
    availableSaturday: null,
    deliveryRadius: '',
}

export const getInitialValues = (item = {}) => {
    return Object.assign(
        initialValues,
        item,
    
    )
}

export const useScheme = () => {
    return Yup.object().shape({
        birthdate: Yup.string().required('Date of birth is required'),
        name: Yup.string().required('First name is required'),
        lastName: Yup.string().required('Last name is required'),
        licensePicture: Yup.string().required('Driver\'s License is not allowed to be empty'),
        insurancePicture: Yup.string().required('Car Insurance Card is not allowed to be empty'),
        make: Yup.string().required('Make is required'),
        carModel: Yup.string().required('Model is required'),
        carYear: Yup.string().required('Car year is required').matches(/^[0-9]+$/, 'This field can only contain numeric values').matches(/^2[0][0][8-9]|2[0][1-9][0-9]/, 'Year should be 2008 or newer'),
        front: Yup.string().required('Front Picture is not allowed to be empty'),
        back: Yup.string().required('Back Picture is not allowed to be empty'),
        leftSide: Yup.string().required('Left Side Picture is not allowed to be empty'),
        rightSide: Yup.string().required('Right Side Picture is not allowed to be empty'),
    })
}
