import { AddressObject, KeyValue } from '@/common/types'

export type ProfileValues = {
    name: string,
    lastName: string,
    address: AddressObject,
    apartment: string,
    birthdate: string,
    gender: string,
    tShirt: string,
    profilePicture: string,
    licensePicture: string,
    insurancePicture: string,
    make: string,
    carModel: string,
    carYear: string,
    front: string,
    back: string,
    leftSide: string,
    rightSide: string,
    workingHours: string[],
    availableWeekdays: boolean | null,
    availableSaturday: boolean | null,
    deliveryRadius: string,
}

export const T_SHIRTS = [
    { key: 'XS', value: 'XS' },
    { key: 'S', value: 'S' },
    { key: 'M', value: 'M' },
    { key: 'L', value: 'L' },
    { key: 'XL', value: 'XL' },
    { key: 'XXL', value: 'XXL' },
]

export const GENDER = [
    { key: 'MALE', value: 'Male' },
    { key: 'FEMALE', value: 'Female' },
    { key: 'OTHER', value: 'Other' },
]

export const DISTANCE = [
    {
        label: '0-10 mi',
        value: '10',
    },
    {
        label: '0-25 mi',
        value: '25',
    },
    {
        label: '0-50 mi',
        value: '50',
    },
    {
        label: '50+ mi',
        value: '-1',
    },
]

export const WORKING_HOURS: KeyValue[] = [
    {
        value: '11:00 am - 2:00 pm',
        key: '11:00:00-14:00:00',
    },
    {
        value: '3:00 pm - 7:00 pm',
        key: '15:00:00-19:00:00',
    },
]
