import { CourierType } from '@/common/enums'
import { Steps } from '@/common/types'
const ALL_COURIER_TYPES = Object.values(CourierType)


export const steps: Steps[] = [
    {
        title: 'Courier Portal and Pay',
        desc: 'Please take a moment to watch our Training Video. It will help you understand how to use our Courier Portal.',
        video: true,
        url: 'https://files.medzoomer.com/Medzoomer-Teams.mp4',
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'training-video',
    },
    {
        title: 'Medzoomer Delivery App 101',
        desc: 'Please take a moment to watch our Training Video. It will help you understand how to use the Onfleet application.',
        video: true,
        url: 'https://files.medzoomer.com/Medzoomer-On-Fleet.mp4',
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'onfleet-video',
    },
    {
        title: 'Medzoomer Guidelines and Policies',
        desc: 'Please take a moment to watch our Training Video. It will help you understand courier duties and responsibilities.',
        video: true,
        url: 'https://files.medzoomer.com/Medzoomer-Guidelines.mp4',
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'guidelines-video',
    },
]