import * as Yup from 'yup'

export const initialValues = {
    teams: [''],
}

export const getInitialValues = (item = {}) => {
    return Object.assign(
        initialValues,
        item,
    
    )
}

export const useScheme = () => {
    return Yup.object().shape({
        teams: Yup.array().of(Yup.string()).required('Teams is required'),
    })
}
