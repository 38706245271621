export enum DayEnum {
    Monday = 'Monday',
    Tuesday = 'Tuesday',
    Wednesday = 'Wednesday',
    Thursday = 'Thursday',
    Friday = 'Friday',
    Saturday = 'Saturday',
    Sunday = 'Sunday',
}

export enum ResponseTypesHttp {
    JSON = 'json',
    TEXT = 'text',
    BLOB = 'blob',
}

export enum OrderTypes {
    REGULAR = 'regular',
    FACILITY = 'facility',
}

export enum OrderStatus {
    READY = 'ready',
    PENDING_PICKUP = 'pending_pickup',
    PICKED_UP = 'picked_up',
    EN_ROUTE = 'en_route',
    COMPLETED = 'completed',
    FAILED = 'failed',
    CANCELED = 'canceled',
    CANCELED_WITH_FEE = 'canceled_with_fee',
    CANCELED_WITH_CHARGE_AND_FEE = 'canceled_with_charge_and_fee'
}

export enum TaskType {
    PICKUP = 'pickup',
    DROPOFF = 'drop_off',
    RETURN = 'return',
    RETURN_PICKUP = 'return_pickup'
}

export enum TaskStatus {
    PENDING = 'pending',
    EN_ROUTE = 'en_route',
    COMPLETED = 'completed',
    FAILED = 'failed',
}

export enum PricingHistoryType {
    COURIER = 'courier',
    PHARMACY = 'pharmacy'
}

export enum DeliveryStatusTypes {
    PENDING = 'pending',
    EN_ROUTE = 'en_route',
    COMPLETED = 'completed',
}

export enum CourierOrderFields {
    NAME = 'user.name',
    CREATION_DATE = 'created_at',
    UPDATE_DATE = 'updated_at'
}

export enum QuizStatus {
    PASSED = 'passed',
    FAILED = 'failed',
    INCOMPLETE = 'incomplete'
}

export enum CourierStatus {
    ACTIVE = 'active',
    DECLINED = 'declined',
    INCOMPLETE = 'incomplete',
    INACTIVE = 'inactive',
    PENDING = 'pending',
    DELETED = 'deleted',
    SIGNATURE_PENDING = 'signature_pending',
    SIGNATURE_READY = 'signature_ready',
    CHECKR_ONBOARD_WAITLISTED = 'checkr_onboard_waitlisted',
    CHECKR_ONBOARD_PENDING = 'checkr_onboard_pending',
    CHECKR_ONBOARD_ENGAGED = 'checkr_onboard_engaged',
    SIGN_UP_FLOW_PENDING = 'sign_up_flow_pending',
    TERMINATED = 'terminated',
}

export enum CourierRegistrationStatus {
    REGISTERED = 'registered',
    PENDING = 'pending',
    SIGNATURE_PENDING = 'signature_pending',
    SIGNATURE_READY = 'signature_ready',
}

export enum TransactionType {
    BACKGROUND_CHECK = 'background_check',
    BONUS = 'bonus',
    CLAWBACK = 'clawback',
    ORDER_PAYOUT = 'order_payout',
    ORDER_PAYOUT_ADJUSTMENT = 'order_payout_adjustment',
    PAID_OUTSIDE = 'paid_outside',
    WITHDRAW = 'withdraw',
    EXTERNAL_PAYOUT = 'external_payout',
    DELIVERY = 'delivery',
}

export enum CourierCheckrStatus {
    PENDING = 'pending',
    CLEAR = 'clear',
    CONSIDER = 'consider',
    SUSPENDED = 'suspended',
    DISPUTE = 'dispute',
    INCOMPLETE = 'incomplete',
    COMPLETE = 'complete'
}

export enum CourierRegistrationSteps {
    START = 'SignUp',
    EMAIL_CONFIRMATION = 'Email',
    AVAILABILITY = 'availability',
    GENERAL_INFORMATION = 'general_information',
    ID_CARD = 'id_card',
    LICENSE_INSURANCE = 'license_insurance',
    DRIVING_ZONES = 'driving_zones',
    FW9_TAX = 'fw9_and_tax_information',
    TAKE_SELFIE = 'take_selfie',
    SIGNATURES = 'signatures',
    ONBOARDING = 'onboarding',
    ONBOARDING_PENDING = 'onboarding_pending',
}

// Transactions enums
export enum TransactionStatus {
    CREATED = 'created',
    PROCESSING = 'processing',
    FAILED = 'failed',
    CANCELED = 'canceled',
    SUCCESS = 'success'
}

export enum FileType {
    ORDER_PACKAGE = 'package_ref_task',
    ORDER_SIGNATURE = 'signature_task',
    ORDER_BUILDING = 'building_task',
    COURIER_INSURANCE = 'courier_insurance_picture',
    COURIER_SELFIE = 'courier_selfie_picture',
    COURIER_LICENSE = 'courier_license_picture',
    COURIER_PROFILE_PICTURE = 'courier_profile_picture',
    COURIER_VEHICLE_FRONT = 'courier_vehicle_front_picture',
    COURIER_VEHICLE_BACK = 'courier_vehicle_back_picture',
    COURIER_VEHICLE_LEFT = 'courier_vehicle_left_picture',
    COURIER_VEHICLE_RIGHT = 'courier_vehicle_right_picture',
    COURIER_ID_CARD = 'courier_id_card',
}

export enum AgreementType {
    FLEET_AGREEMENT = 'fleet',
    FW9_AGREEMENT = 'fw9'
}

export enum TaxClassificationTypes {
    INDIVIDUAL = 'individual',
    C_CORPORATION = 'c_corporation',
    S_CORPORATION = 's_corporation',
    PARTNERSHIP = 'partnership',
    TRUST = 'trust_state',
    LIMITED_LIABILITY = 'llc'
}

export enum StepType {
    TRAINING_VIDEO = 'training_video',
    HIPPA_VIDEO = 'hippa_video',
    SHOW_ONFLEET_VIDEO = 'show_onfleet_video',
    ONFLEET_VIDEO = 'onfleet_video',
    APP_VIDEO = 'app_video',
}

export enum VideoPlayers {
    WISTIA = 'wistia',
    NATIVE = 'native',
    VIMEO = 'vimeo'
}

export enum ApiGateways {
    COURIER = '/couriers',
    PUBLIC = '',
}

export enum CourierType {
    W2 = 'w2',
    MZ_1099 = '1099',
}

export enum RegistrationFlow {
    MZ = 'mz',
    W2 = 'w2',
    TEN1099 = '1099',
}