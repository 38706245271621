import { Auth as AmplifyAuth, CognitoUser } from '@aws-amplify/auth'
import {
    AUTH_NEW_PASSWORD_REQUIRED,
    USER_NOT_CONFIRMED_EXCEPTION,
} from '@/constants'
import { SignUpValues } from '@/common/types'
import { httpPublic } from '@/services/app'
import { API_VALIDATE_RESET_PASSWORD, API_VALIDATE_SIGN_IN } from '@/constants/routes'

AmplifyAuth.configure({
    Auth: {
        region: import.meta.env.VITE_COGNITO_REGION,
        userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
        userPoolWebClientId: import.meta.env.VITE_COGNITO_WEB_CLIENT_ID,
        authenticationFlowType: 'USER_PASSWORD_AUTH',
    },
})

const signIn = async (username: string, password: string) => {
    try {
        const user = await AmplifyAuth.signIn(username.toLowerCase(), password)
        if (user.challengeName === AUTH_NEW_PASSWORD_REQUIRED) {
            const { requiredAttributes } = user.challengeParam
            return {
                success: false,
                type: AUTH_NEW_PASSWORD_REQUIRED,
                user,
                requiredAttributes,
            }
        }

        return { success: true, user }
    } catch (e) {
        if (e.code === USER_NOT_CONFIRMED_EXCEPTION) {
            await resendConfirmationCode(username)
            return { success: false, type: 'UserNotConfirmed' }
        }

        throw e
    }
}

const signUp = async (values: SignUpValues) => {
    // eslint-disable-next-line no-useless-catch
    try {
        const { password, phone, name, lastName, address, apartment, queryString } = values
        const email = values.email.toLowerCase()
        const { user } = await AmplifyAuth.signUp({
            username: email,
            password,
            attributes: {
                name,
                family_name: lastName,
                email,
                phone_number: !phone.includes('+') ? `+${phone}` : phone,
                address,
                zoneinfo: apartment || 'N/A',
                website: JSON.stringify(queryString) || null,
            },
            autoSignIn: {
                enabled: true,
            },
        })

        return user
    } catch (error) {
        throw error
    }
}

const confirmSignUp = async (username: string, code: string) => {
    // eslint-disable-next-line no-useless-catch
    try {
        await AmplifyAuth.confirmSignUp(username.toLowerCase(), code)
        return true
    } catch (e) {
        throw e
    }
}

async function resendConfirmationCode(username: string) {
    try {
        return await AmplifyAuth.resendSignUp(username.toLowerCase())
    } catch (err) {
        console.log('error resending code: ', err)
        throw err
    }
}

const completeNewPasswordChallenge = async (user: CognitoUser, password: string, requiredAttributes: any = []) => {
    return AmplifyAuth.completeNewPassword(user, password, requiredAttributes)
}

const changePassword = (user: CognitoUser, oldPassword: string, newPassword: string) => {
    return AmplifyAuth.changePassword(user, oldPassword, newPassword)
}

const sendVerificationCode = async (email: string) => AmplifyAuth.forgotPassword(email.toLowerCase())

const forgotPasswordConfirmation = (username: string, code: string, newPassword: string) => AmplifyAuth.forgotPasswordSubmit(username.toLowerCase(), code, newPassword)

const currentUser = async () => AmplifyAuth.currentAuthenticatedUser()

const signOut = async () => AmplifyAuth.signOut()

const validateSignIn = async (email: string, password: string): Promise<{ success: boolean, code: string, message: string } | void> => {
    try {
        const environment = import.meta.env.VITE_ENV
        if (environment === 'production') {
            const apiResponse = await httpPublic.post(API_VALIDATE_SIGN_IN, { email: email.toLowerCase(), password, source: 'courier' }, false)
            return apiResponse
        }
    } catch (error) {
        console.log(error)
        throw error
    }
}

const validateResetPassword = async (email: string): Promise<{ success: boolean, code: string, message: string } | void> => {
    try {
        const environment = import.meta.env.VITE_ENV
        if (environment === 'production') {
            await httpPublic.post(API_VALIDATE_RESET_PASSWORD, { email: email.toLowerCase(), source: 'courier' }, false)
        }
    } catch (error) {
        console.log(error)
        throw error
    }
}

export { validateSignIn, validateResetPassword, signIn, signUp, currentUser, confirmSignUp, resendConfirmationCode, signOut, completeNewPasswordChallenge, sendVerificationCode, forgotPasswordConfirmation, changePassword }
