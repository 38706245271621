import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'

interface Props {
    children: React.ReactNode
    viewBox?: string
    width?: number
    height?: number
    coloredStroke?: boolean
    className?: string
    style?: React.CSSProperties
    noFill?: boolean
}

const useStyles = makeStyles()(() => ({
    svgRoot: {
        display: 'inline-block',
        transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        flexShrink: 0,
        MozUserSelect: 'none',
        verticalAlign: 'top',
        fill: 'white',
    },
    stroke: {
        stroke: 'currentColor',
    },
    fill: {
        fill: 'currentColor',
    },
}))

export const SvgIcon = ({
    children,
    viewBox,
    width = 18,
    height = 18,
    coloredStroke = false,
    className,
    style,
    noFill = false,
}: Props) => {
    const { classes: styles } = useStyles()

    return (
        <svg
            style={style}
            className={clsx(styles.svgRoot, !noFill && (coloredStroke ? styles.stroke : styles.fill), className)}
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={viewBox}
        >
            {children}
        </svg>
    )
}
