import { useEffect, useState } from 'react'
import clsx from 'clsx'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'
import { AuthUser, Steps, updateCourierPayload } from '@/common/types'
import { StepType, VideoPlayers } from '@/common/enums'
import VideoPlayer from '@/components/common/VideoPlayer'
import { useStyles } from '../../styles'

const PLAYER_BY_VIDEO_TYPE: { [key: string]: VideoPlayers } = {
    [StepType.TRAINING_VIDEO]: VideoPlayers.NATIVE,
    [StepType.HIPPA_VIDEO]: VideoPlayers.VIMEO,
    [StepType.ONFLEET_VIDEO]: VideoPlayers.WISTIA,
    [StepType.APP_VIDEO]: VideoPlayers.WISTIA,
}

interface VideoProps {
    data: Steps
    courier: AuthUser | null
    setUser: (_user: AuthUser | null) => void
    setCourier: (_values: updateCourierPayload) => Promise<AuthUser>
}

const VideoStep = ({ data, courier, setUser, setCourier }: VideoProps) => {
    const { classes } = useStyles()
    const [controls, setControls] = useState(false)

    const endedVideo = () => {
        if (data.type === StepType.TRAINING_VIDEO && courier) {
            setCourier({ videoTrainingCompleted: true })
            const newCourier = { ...courier, videoTrainingCompleted: true }
            setUser(newCourier)
        } else if (data.type === StepType.HIPPA_VIDEO && courier) {
            setCourier({ HIPAAcompleted: true })
            const newCourier = { ...courier, HIPAAcompleted: true }
            setUser(newCourier)
        }
    }

    useEffect(() => {
        if (data.type === StepType.TRAINING_VIDEO && courier) {
            setControls(courier.videoTrainingCompleted)
        } else if (data.type === StepType.HIPPA_VIDEO && courier) {
            setControls(courier.HIPAAcompleted)
        } else if (courier) {
            setControls(true)
        }
    }, [])

    return (
        <Box sx={{ backgroundColor: 'white' }}>
            <div className={classes.modal}>
                <div className={classes.body}>
                    <div className={clsx('text-center', 'w-100')}>
                        <Typography className={classes.title}>
                            {data.title}
                        </Typography>
                        <Typography className={classes.desc}>
                            {data.desc}
                        </Typography>
                        <div className={classes.video}>
                            <VideoPlayer
                                url={data.url ? data.url : ''}
                                onEnded={endedVideo}
                                controls={controls}
                                playerType={data.type && PLAYER_BY_VIDEO_TYPE[data.type] || VideoPlayers.NATIVE}
                            />
                        </div>
                    </div>
                </div>
            </div>

        </Box>
    )
}

export default VideoStep
