import Typography from '@/components/common/Typography'

import styles from './styles.module.css'
import { useAuth } from '@/hooks/useAuth'
import AuthLayout from '@/components/layouts/AuthLayout'
import { CourierStatus } from '@/common/enums'

const DeclinedRegistration = () => {
    const { user } = useAuth()

    return (
        <AuthLayout>
            <div className={styles.declinedRegistration}>
                <svg xmlns="http://www.w3.org/2000/svg" width="72" height="96" viewBox="0 0 72 96">
                    <g fill="none" fillRule="evenodd">
                        <path fill="#E21C40" d="M72 6v90H0V6z" />
                        <path fill="#AF0322" d="M64 14l-1.33 72.67L8 88l58.16 2.13z" />
                        <path fill="#FFF" d="M64 14v74H8V14z" />
                        <path fill="#B2B2B2" fillRule="nonzero" d="M48 62H24v4h24v-4zm0-8H24v4h24v-4zM24 74h16v-4H24v4zm20.87-42L48 35.176 33.412 50 24 40.47l3.13-3.176 6.282 6.353L44.87 32z" />
                        <path fill="#AF0322" d="M18 6h36v12H18z" />
                        <path fill="#AF0322" d="M27 0h18v8.07H27z" />
                        <path fill="#8D0019" d="M54 6l1.31 8H54zM18 18l36 1.47V18z" />
                    </g>
                </svg>
                <div>
                    {user?.status === CourierStatus.DECLINED &&
                        <>
                            <Typography variant="body2" align='center' gutter='label'>
                                {user?.name}, Thank you for taking the time and effort for applying
                            </Typography>
                            <Typography align='center' variant='body2' color='placeholder' className={styles.subtitle} gutter='bottom2'>
                                Unfortunately, we would like to let you know that you were not selected to move forward.
                            </Typography>
                        </>
                    }
                    {user?.status === CourierStatus.TERMINATED &&
                        <Typography variant="body2" align='center' gutter='label'>
                            <b>{user?.name}</b>, We are currently reviewing your account due to recent concerns/issues that may have taken place while you were
                            delivering for Medzoomer. At this time, we are pausing your account to ensure we address and correct any issues moving forward.
                        </Typography>
                    }
                </div>
            </div>
        </AuthLayout >
    )
}

export default DeclinedRegistration
