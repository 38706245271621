import { useEffect, useState } from 'react'
import clsx from 'clsx'

import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'

import Typography from '@/components/common/Typography'
import { Button } from '@/components/common/Buttons'
import ModalDialog from '@/components/common/Modal'
import Paper from '../Paper'

import { PickupTime } from '@/common/types'
import { DAYS_OF_WEEK_TO_NUMBER } from '@/constants'
import { hourToDate } from '@/utils/dates'
import { useBooleanHandlers } from '@/hooks/useBooleanHandlers'

import { DayEnum } from '@/common/enums'
import FormPickupTime from '@/components/forms/CustomPickupTime'
import { Box } from '@mui/material'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'

interface Props {
    pickupTimes: Array<PickupTime>
    value?: Array<PickupTime>
    onChange: (_modifiedValue: Array<PickupTime>) => void
}

const PickupTimes = ({ pickupTimes, value, onChange }: Props) => {
    const [openModal, setOpenModal, setCloseModal] = useBooleanHandlers(false)
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })
    const [error, setError] = useState('')

    const handleCheckboxChange = (time: DayEnum, index: number) => {
        const newPickupTimes = [...pickupTimes]
        const pickupTime = newPickupTimes[index]
        const dayNumber = DAYS_OF_WEEK_TO_NUMBER[time]

        const existDay = pickupTime.days.find(i => i.dayNumber === dayNumber)
        if (existDay) {
            pickupTime.days = pickupTime.days.filter(i => i.dayNumber !== dayNumber)
        } else {
            pickupTime.days.push({
                dayNumber,
                day: time,
            })
        }

        newPickupTimes[index] = pickupTime
        onChange(newPickupTimes)
    }

    const applyCustomPickupTime = (values: PickupTime) => {
        const existsPickupTime = pickupTimes.find(i => i.pickupTime === values.pickupTime)

        if (!existsPickupTime && values.pickupTime) {
            const newPickupTimes = [...pickupTimes]
            newPickupTimes.push({
                ...values,
                isDefault: false,
            })
            onChange(newPickupTimes)
            setCloseModal()
        } else {
            setError('Pickup time already exists')
        }
    }

    useEffect(() => {
        if (!openModal) setError('')
    }, [openModal])

    return (
        <>
            <Paper px={0} py={0}>
                <Box className={clsx('d-flex', isSmallScreen ? 'justify-center' : 'justify-between', 'flex-wrap', 'align-center')} mb={2}>
                    <Typography variant='body1' weight='500'>Available Working Hours *</Typography>
                    <Button variant='text' size='small' color='secondary' onClick={setOpenModal}>
                        <Typography color='secondary' variant='body1'>Add New Pickup Time</Typography>
                    </Button>
                </Box>
                <Grid container spacing={1} marginY={2}>
                    {pickupTimes.map((time, index) => (
                        <Grid item xs={12} key={time.pickupTime as string} className={clsx('d-flex', 'align-end', 'flex-wrap', isSmallScreen ? 'justify-center' : 'justify-between')}>
                            <Typography variant='body1' weight='500'>{hourToDate(time?.pickupTime as string)}</Typography>
                            {isSmallScreen ? (
                                <Box sx={{ textAlign: 'center' }}>
                                    {Object.values(DayEnum).filter(i => i !== DayEnum.Sunday).map((item) => (
                                        <FormControlLabel
                                            key={item}
                                            label={
                                                <Typography variant='body2'>{item}</Typography>
                                            }
                                            labelPlacement="top"
                                            sx={{ marginBottom: 2 }}
                                            control={
                                                <Checkbox
                                                    checked={time.days.find(i => i.day === item) ? true : false}
                                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, p: 0, pt: index ? 0 : 1 }}
                                                    value={item}
                                                    color='secondary'
                                                    id={`checkbox-${item}-${index}`}
                                                    onChange={() => handleCheckboxChange(item, index)}
                                                />
                                            }
                                        />
                                    ))}
                                </Box>
                            ) : (
                                <FormControl>
                                    <FormGroup row>
                                        {Object.values(DayEnum).filter(i => i !== DayEnum.Sunday).map((item) => (
                                            <FormControlLabel
                                                key={item}
                                                label={
                                                    <Typography variant='body2' color={index ? 'transparent' : 'inherit'}>{item}</Typography>
                                                }
                                                labelPlacement="top"
                                                control={
                                                    <Checkbox
                                                        checked={time.days.find(i => i.day === item) ? true : false}
                                                        sx={{ '& .MuiSvgIcon-root': { fontSize: 28 }, p: 0, pt: index ? 0 : 1 }}
                                                        value={item}
                                                        color='secondary'
                                                        id={`checkbox-${item}-${index}`}
                                                        onChange={() => handleCheckboxChange(item, index)}
                                                    />
                                                }
                                            />
                                        ))}
                                    </FormGroup>
                                </FormControl>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </Paper>
            <ModalDialog
                open={openModal}
                center
                title='Custom Pick Up Time'
                onClose={setCloseModal}
            >
                <FormPickupTime apply={applyCustomPickupTime} error={error} />
            </ModalDialog>
        </>
    )
}

export default PickupTimes