import SvgIcon from '@/components/common/SvgIcon'
import { PropsSvg } from '../types'

const COLORS: {[key: string]: string} = {
    success: '#17b471',
    pending: '#006CF0',
}

export const IconAgreement = (props: PropsSvg) => {
    const { width = 60, height = 100, color = 'pending' } = props

    return (
        <SvgIcon width={width} height={height} viewBox="0 0 50 60">
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g stroke={COLORS[color]} strokeWidth="2">
                    <g>
                        <g>
                            <g>
                                <g>
                                    <path d="M29.375 0h-23.5C2.63 0 0 2.642 0 5.9v47.2C0 56.358 2.63 59 5.875 59h35.25C44.37 59 47 56.358 47 53.1V17.7L29.375 0z" transform="translate(-1080 -326) translate(640) translate(80 193) translate(340 113) translate(21 21)"/>
                                    <path d="M29 0L29 18 47 18M36 32L12 32M36 44L12 44M18 21L15 21 12 21" transform="translate(-1080 -326) translate(640) translate(80 193) translate(340 113) translate(21 21)"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </SvgIcon>
    )
}
