import { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import RestartAltIcon from '@mui/icons-material/RestartAlt'

import ModalDialog from '@/components/common/Modal'
import { Button } from '@/components/common/Buttons'
import { DayPicker } from '@/components/common/Inputs'


import { useForm } from '@/hooks/useForm'
import { useScheme, getInitialFilterValues, FiltersType } from '../../helpers'

interface Props {
    open: boolean
    title?: string
    text?: string
    pending?: boolean
    filters?: FiltersType
    handleApplyFilters: (_values: FiltersType) => void
    onClose: () => void
}

const FilterDialog = ({ open, filters, pending, handleApplyFilters, onClose }: Props) => {
    const schema = useScheme()

    const onSubmit = (values: FiltersType) => {
        handleApplyFilters(values)
        onClose()
    }

    const { values, handleSubmit, setFieldValue, resetForm } = useForm(
        schema,
        getInitialFilterValues(filters || {}),
        onSubmit,
    )

    const onReset = () => {
        resetForm()
        handleApplyFilters({})
        onClose()
    }

    useEffect(() => {
        if (!values.dates.field) {
            setFieldValue('dates.startDate', '')
            setFieldValue('dates.endDate', '')
        }
    }, [values.dates.field])

    return (
        <ModalDialog
            open={open}
            title='Filters'
            secondaryActions={
                <Button variant='text' color='secondary' size='small' onClick={onReset}>
                    <RestartAltIcon fontSize='small' />
                    Reset
                </Button>
            }
            onClose={onClose}
        >
            <Grid container spacing={3} marginBottom={5}>
                <Grid item xs={6}>
                    <DayPicker
                        label='From'
                        name='startDate'
                        value={values.dates.startDate}
                        onChange={(e) => setFieldValue('dates.startDate', e)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <DayPicker
                        label='To'
                        name='endDate'
                        value={values.dates.endDate}
                        onChange={(e) => setFieldValue('dates.endDate', e)}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} className='flex-center'>
                    <Button
                        disabled={pending}
                        pending={pending}
                        size='large'
                        variant="contained"
                        color='secondary'
                        onClick={handleSubmit}
                    >
                        Apply
                    </Button>
                </Grid>
            </Grid>
        </ModalDialog>
    )
}

export default FilterDialog