import Typography from '@/components/common/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'
import { useNavigate } from 'react-router-dom'
import { AuthUser } from '@/common/types'
import TableHead from '@/components/generics/Table/TableHead'
import { TRANSACTION_TABLE_HEADERS } from '../../../helpers'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import Paper from '@/components/generics/Paper'
import Item from './Item'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'
import { VIEW_ALL_TRASACTIONS_BUTTON } from '@/constants/testIds'

interface Props {
    courier?: AuthUser | null
}

const TransactionInformation = ({ courier }: Props) => {
    const navigate = useNavigate()
    const handleViewAll = () => navigate('/transaction', { state: { courier } })
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })
    return (
        <Paper px={isSmallScreen?1:5} py={5} radius={20}>
            <Box display='flex' justifyContent='space-between' ml={2} mb={2}>
                <Typography variant={isSmallScreen?'body1': 'h4'}>Latest Transactions</Typography>
                <Button onClick={handleViewAll} id={VIEW_ALL_TRASACTIONS_BUTTON} variant='text' color='info' size='small' style={{ alignItems: 'left' }} >View All</Button>
            </Box>
            <TableContainer>
                <Table sx={{ mb: 0, borderSpacing: 20, borderCollapse: 'inherit' }} aria-label="simple table">
                    <TableHead headers={TRANSACTION_TABLE_HEADERS} />
                    <TableBody>
                        { courier?.latestTransactions && courier?.latestTransactions.map((item, index) => 
                            <Item key={index} item={item} />,
                        )}
                    </TableBody>
                </Table>
                {!courier?.latestTransactions?.length && (
                    <Typography className='text-center' variant='h6'>There are no transactions to display at the moment</Typography>
                )}
            </TableContainer>
        </Paper>
    )
}

export default TransactionInformation