import { useNavigate, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Box } from '@mui/material'

import AuthLayout from '@/components/layouts/AuthLayout'
import { EmailConfirmation } from './components'

import { ROUTE_DASHBOARD, ROUTE_LOGIN, ROUTE_SIGN_UP } from '@/constants/routes'
import { useAuth } from '@/hooks/useAuth'

import StartStep from './components/StartStep'
import ScheduleStep from './components/ScheduleStep'
import DetailStep from './components/DetailStep'
import Fw9Step from './components/Fw9Step'
import SignStep from './components/SignStep'
import LicenseInsuranceStep from './components/LicenseInsuranceStep'
import TakeSelfieStep from './components/SelfieStep'
import CardStep from './components/CardStep'
import { CourierRegistrationSteps, CourierStatus, RegistrationFlow } from '@/common/enums'
import SuccessRegistration from './components/SuccessRegistration'
import { getInitialRoute } from '@/utils'

const authLayoutComponents = [
    CourierRegistrationSteps.AVAILABILITY,
    CourierRegistrationSteps.GENERAL_INFORMATION,
    CourierRegistrationSteps.TAKE_SELFIE,
    CourierRegistrationSteps.LICENSE_INSURANCE,
    CourierRegistrationSteps.FW9_TAX,
    CourierRegistrationSteps.ONBOARDING,
    CourierRegistrationSteps.ID_CARD,
    CourierRegistrationSteps.ONBOARDING_PENDING,
]

const SignUp = () => {
    const navigate = useNavigate()
    const { search } = useLocation()
    const { user } = useAuth()

    const query = new URLSearchParams(search)
    const step = query.get('step') || '0' as CourierRegistrationSteps

    const [queryString, setQueryString] = useState({})

    const nextStep = (stepName: CourierRegistrationSteps) => {
        if (window.location.search !== '') {
            const params = new URLSearchParams(window.location.search)
            const jsonObject = Object.fromEntries(params)
            setQueryString(jsonObject)
        }

        if (!step) {
            navigate(`${ROUTE_SIGN_UP}?step=0`)
        }
        navigate(user?.registerFlow === RegistrationFlow.MZ && stepName === CourierRegistrationSteps.ONBOARDING ? ROUTE_DASHBOARD.path : `${ROUTE_SIGN_UP.path}?step=${stepName}`)
    }

    useEffect(() => {
        if (!user && authLayoutComponents.includes(step as CourierRegistrationSteps)) {
            navigate(ROUTE_LOGIN.path)
        }
        if (user) {
            const registrationStep = user?.registrationStep
            const path = getInitialRoute(registrationStep as CourierRegistrationSteps, user.status as CourierStatus)
            navigate(path)
        }
    }, [])

    return (
        <AuthLayout>
            {user?.status === CourierStatus.SIGN_UP_FLOW_PENDING || ([CourierStatus.DECLINED, CourierStatus.PENDING, CourierStatus.CHECKR_ONBOARD_PENDING, CourierStatus.CHECKR_ONBOARD_WAITLISTED].includes(user?.status as CourierStatus) && user?.registrationStep === CourierRegistrationSteps.ONBOARDING_PENDING) ? (
                <SuccessRegistration />
            ) : (
                <Box height='100%'>
                    {step === '0' && <StartStep nextStep={nextStep} />}
                    {step === 'SignUp' && <SignStep nextStep={nextStep} queryString={queryString} />}
                    {step === 'Email' && <EmailConfirmation nextStep={nextStep} />}
                    {step === CourierRegistrationSteps.AVAILABILITY && <ScheduleStep nextStep={nextStep} />}
                    {step === CourierRegistrationSteps.GENERAL_INFORMATION && <DetailStep nextStep={nextStep} />}
                    {step === CourierRegistrationSteps.LICENSE_INSURANCE && <LicenseInsuranceStep nextStep={nextStep} />}
                    {step === CourierRegistrationSteps.TAKE_SELFIE && <TakeSelfieStep nextStep={nextStep} />}
                    {step === CourierRegistrationSteps.ID_CARD && <CardStep nextStep={nextStep} />}
                    {step === CourierRegistrationSteps.FW9_TAX && <Fw9Step nextStep={nextStep} />}
                    {[CourierRegistrationSteps.ONBOARDING, CourierRegistrationSteps.ONBOARDING_PENDING].includes(step as CourierRegistrationSteps) && <SuccessRegistration />}
                </Box>
            )}
        </AuthLayout>
    )
}

export default SignUp
