import * as Yup from 'yup'

export const initialValues = {
    amount: 0,
}

export const getInitialValues = (item = {}) => {
    return Object.assign(
        item,
        initialValues,
    )
}

export const useScheme = () => {
    return Yup.object().shape({
        amount: Yup.string().required('Please enter a valid amount').matches(/^(?!0$)[0-9]+(?:\.[0-9]{1,2})?$/, 'Please enter a valid amount'),
    })
}