import Paper from '@/components/generics/Paper'
import Typography from '@/components/common/Typography'
import { Box, FormControl } from '@mui/material'
import Select from '@/components/common/Inputs/Select'
import { formatCurrency } from '@/utils'
import { generateDateRange } from '@/utils/dates'
import LoaderSmall from '@/components/common/Loader/LoaderSmall'
import { DATERANGE_FILTERS } from '@/pages/Dashboard/helpers'
import useListInit from '@/hooks/useListInit'
import { API_DASHBOARD } from '@/constants/routes'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'
import { useAuth } from '@/hooks/useAuth'
import { CourierType } from '@/common/enums'

interface DashboardMetrics {
    balance: number
    totalEarned: number
    totalBonus: number
    totalDeliveries: number
}

const EarningMetrics = () => {
    const { startTime, endTime } = generateDateRange('all')
    const {
        data,
        isLoading,
        filters,
        setFilters,
    } = useListInit<DashboardMetrics>({ endpoint: API_DASHBOARD, defaultFilters: { dates: { field: 'all', startDate: startTime, endDate: endTime } } })
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })
    const { user } = useAuth()

    const selectOptionFilter = (value: string) => {
        const { startTime, endTime } = generateDateRange(value)
        setFilters({ dates: { field: value, startDate: startTime, endDate: endTime } })
    }

    return (
        <Paper px={0} py={5} radius={20}>
            <Box display='flex' justifyContent={'space-between'} flexDirection={isSmallScreen ? 'column' : 'inherit'} ml={2} mb={1}>
                <Typography className='text-left' gutter='bottomSpace' variant={isSmallScreen ? 'h6' : 'h4'}>Earning Metrics</Typography>
                <FormControl>
                    <Select
                        variant='standard'
                        id="demo-simple-select"
                        value={filters.dates.field}
                        options={DATERANGE_FILTERS}
                        onChange={(e) => selectOptionFilter(e.target.value as string)}
                    />
                </FormControl>
            </Box>
            <Box display='flex' gap={2} justifyContent='space-around' flexDirection={isSmallScreen ? 'column' : 'inherit'}>
                <Box>
                    <Typography align='center' color='hint'>Total Deliveries</Typography>
                    <Typography color='hint' variant='h3' align='center' title='Balance'>{data && data.totalDeliveries || 0}</Typography>
                </Box>
                <Box>
                    <Typography align='center' color='hint'>Total Bonus</Typography>
                    <Typography color='#4cc71d' variant='h3' align='center' title='Balance'>{formatCurrency(data?.totalBonus || 0)}</Typography>
                </Box>
                {/* <Box>
                    <Typography align='center' color='hint'>Balance</Typography>
                    <Typography color='#4cc71d' variant='h3' align='center' title='Balance' >{formatCurrency(data?.balance || 0)}</Typography>
                
                </Box> */}
                {user?.courierType === CourierType.MZ_1099 &&
                    <Box>
                        <Typography align='center' color='hint'>Total Earned</Typography>
                        <Typography color='#4cc71d' variant='h3' align='center' title='Balance'>{formatCurrency(data?.totalEarned || 0)}</Typography>
                    </Box>
                }
            </Box>
            {isLoading && <LoaderSmall />}
        </Paper>
    )
}

export default EarningMetrics