import { currentUser } from '@/services/amplify'
import { AuthUser } from '@/common/types/types'
import { ApiGateways } from '@/common/enums'

const MAX_RETRIES = 1

interface HttpHeaders {
    'Authorization'?: string,
    'Content-Type'?: string,
}

interface HttpOptions {
    headers?: HttpHeaders,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH',
    body?: string,
    auth?: boolean,
    responseType?: string,
}

class HttpService {

    /**
        * @private
    */
    #protocol = import.meta.env.VITE_API_URL.includes('localhost') ? 'http' : 'https'
    #baseUrl = `${this.#protocol}://${import.meta.env.VITE_API_URL}${ApiGateways.COURIER}`

    /**
        * @private
    */
    #headers: HttpHeaders = {}

    constructor(path = '/couriers', baseUrl: string = import.meta.env.VITE_API_URL) {
        this.#headers = {
            'Content-Type': 'application/json',
        }
        this.#baseUrl = `${this.#protocol}://${baseUrl}${path}`
    }

    async getMe(): Promise<AuthUser> {
        await this.setAuthorizationToken()
        return this._fetchWithRetry('/me', { method: 'GET' }, 1)
    }

    async setAuthorizationToken() {
        try {
            const { signInUserSession: { idToken } } = await currentUser()
            if (idToken) {
                this.#headers['Authorization'] = `Bearer ${idToken.jwtToken}`
            }
        } catch (error) {
            throw new Error(error)
        }
    }

    async _fetchWithRetry(url: string, options: HttpOptions, retriesLeft: number = MAX_RETRIES): Promise<any> {
        try {
            const { responseType = 'json', auth = true, ...httpOptions } = options
            auth && await this.setAuthorizationToken()
            const response = await fetch(this.#baseUrl + url, {
                ...httpOptions,
                headers: {
                    ...this.#headers,
                    ...options.headers,
                },
            })

            if (!response.ok) {
                throw await response.json()
            }

            if (options.method === 'GET' || options.method === 'POST' || options.method === 'PUT' || options.method === 'PATCH') {
                if (responseType === 'json') {
                    return await response.json()
                }
                if (responseType === 'text')
                    return response.text()
            }

            return response
        } catch (error) {
            if (options.method !== 'GET') throw error

            if (retriesLeft === 1) {
                throw error
            }

            return this._fetchWithRetry(url, options, retriesLeft - 1)
        }
    }

    async get<T>(url: string, responseType = 'json', auth = true): Promise<T> {
        return this._fetchWithRetry(url, { method: 'GET', responseType, auth })
    }

    async post(url: string, data: object, auth = true) {
        return this._fetchWithRetry(url, {
            method: 'POST',
            auth,
            body: JSON.stringify(data),
        })
    }

    async put(url: string, data: object) {
        return this._fetchWithRetry(url, {
            method: 'PUT',
            body: JSON.stringify(data),
        })
    }

    async patch(url: string, data: object) {
        return this._fetchWithRetry(url, {
            method: 'PATCH',
            body: JSON.stringify(data),
        })
    }

    async delete(url: string) {
        return this._fetchWithRetry(url, { method: 'DELETE' })
    }
}

export default HttpService