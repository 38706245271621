import clsx from 'clsx'
import CircularProgress from '@mui/material/CircularProgress'
import { ButtonColors } from '@/common/types'

interface Props {
    color?: ButtonColors
    size?: string
    position?: string
    fullWidth?: boolean
}

const LoaderSmall = ({ position = 'center', size, color, fullWidth = true }: Props) => {

    const positionClasses: { [key: string]: string } = {
        center: 'justify-center',
        left: 'justify-start',
        right: 'justify-end',
    }

    return (
        <div id='loader-small-component' className={clsx('d-flex', 'align-center', positionClasses[position], fullWidth && 'w-100')}>
            <CircularProgress size={size} color={color} />
        </div>
    )
}

export default LoaderSmall
