import clsx from 'clsx'

import logo from '@/assets/img/dashboard-logo@3x.png'
import smallLogo from '@/assets/img/compact-logo@3x.png'

import styles from './styles.module.css'

const AppLogo = ({ src, className, small }: { src?: string, className?: string, small: boolean }) => {
    return (
        <img
            alt={'Medzoomer'}
            src={small ? smallLogo : src || logo}
            className={clsx(className, styles.logoBig)}
        />
    )
}

export default AppLogo