import { useState } from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import InputAdornment from '@mui/material/InputAdornment'
import WarningRoundedIcon from '@mui/icons-material/WarningRounded'
import Tooltip from '@mui/material/Tooltip'
import { Box, Grid } from '@mui/material'

import Typography from '@/components/common/Typography'
import { PhoneInput, TextField } from '@/components/common/Inputs'
import { Button, RouterLink } from '@/components/common/Buttons'
import EyePassword from '@/components/generics/EyePassword'
import ErrorAuthMessage from '../../../components/Error'
import CheckBox from '@/components/common/Checkbox'
import GooglePlacesAutocomplete from '@/components/generics/GooglePlacesAutocomplete'

import { useForm, useKeyLock } from '@/hooks'
import { SignUpValues } from './types'
import { SignUpComponentsProps } from '../../types'
import { useStyles } from './styles'
import { getInitialValues, useScheme } from './validations'
import { signUp } from '@/services/amplify'
import { USER_EXIST_EXCEPTION, INVALID_PARAMETER_EXCEPTION, USER_LAMBDA_EXIST_EXCEPTION } from '@/constants'
import { useSignUpStore } from '@/store'

import avatar from '@/assets/icon/ico-login.svg'
import password from '@/assets/icon/ico-pass.svg'
import email from '@/assets/icon/ico-email.svg'
import { ROUTE_LOGIN } from '@/constants/routes'
import { formatEmail, getInputError } from '@/utils'
import { CourierRegistrationSteps } from '@/common/enums'

const getStartAdornment = (icon: string) => {
    return (
        <InputAdornment position="start">
            <img src={icon} />
        </InputAdornment>
    )
}

const SignStep = (props: SignUpComponentsProps) => {
    const { classes } = useStyles()
    const [isAgree, setIsAgree] = useState(false)
    const schema = useScheme()
    const capsLock = useKeyLock('CapsLock')
    const [inputType, setInputType] = useState('password')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const { setUser: setUserStore, setEmail, setTmp } = useSignUpStore((state) => state)

    const onSubmit = async (values: SignUpValues) => {
        setError('')
        try {
            const { address, ...rest } = values
            setLoading(true)
            const user = await signUp({
                ...rest,
                address: address.formattedAddress,
                queryString: props.queryString || {},
            })

            setUserStore(user)
            setEmail(formatEmail(values.email))
            setTmp(values.password)

            props.nextStep(CourierRegistrationSteps.EMAIL_CONFIRMATION)
        } catch (error) {
            let errorMessage = error?.message
            if (error?.code === USER_EXIST_EXCEPTION || error?.code === USER_LAMBDA_EXIST_EXCEPTION) {
                errorMessage = 'A user with this email address is already registered. Please login or reset your password'
            }
            if (error?.code === INVALID_PARAMETER_EXCEPTION) {
                errorMessage = 'Invalid parameter'
            }

            setError(errorMessage)
        } finally {
            setLoading(false)
        }
    }

    const { values, errors, touched, submitCount, getFieldProps, setFieldValue, handleSubmit } = useForm(
        schema,
        getInitialValues(),
        onSubmit,
    )

    return (
        <div className={classes.mainContent}>
            {/* <BackButton /> */}
            <div className={clsx('flex-center', 'flex-column', 'w-100', 'height-100')}>
                <Box mt={3}>
                    <Typography variant="h4" align='center'>Sign Up</Typography>
                </Box>

                <form action="#" onSubmit={handleSubmit} className={clsx('w-100', 'd-flex', 'flex-column', 'align-center')}>
                    <div className={classes.inputWrapper}>
                        <Grid container columnSpacing={1}>
                            <Grid item xs={6}>
                                <TextField
                                    variant='standard'
                                    placeholder='First Name *'
                                    InputProps={{
                                        startAdornment: (
                                            getStartAdornment(avatar)
                                        ),
                                    }}
                                    {...getFieldProps('name')}
                                    error={getInputError(touched.name, errors.name, submitCount >= 1)}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    variant='standard'
                                    placeholder='Last Name *'
                                    InputProps={{
                                        startAdornment: (
                                            getStartAdornment(avatar)
                                        ),
                                    }}
                                    {...getFieldProps('lastName')}
                                    error={getInputError(touched.lastName, errors.lastName, submitCount >= 1)}
                                />
                            </Grid>
                        </Grid>
                        <TextField
                            variant='standard'
                            placeholder='Email *'
                            InputProps={{
                                startAdornment: (
                                    getStartAdornment(email)
                                ),
                            }}
                            {...getFieldProps('email')}
                        />
                        <TextField
                            variant='standard'
                            placeholder='Password'
                            InputProps={{
                                startAdornment: (
                                    getStartAdornment(password)
                                ),
                                endAdornment: (
                                    <>
                                        <EyePassword type={inputType} onSetIcon={setInputType} />
                                        <Tooltip arrow title={<Typography color='light' variant='body2'>Please enter a valid password that is at least 12 characters with one uppercase, one lowercase, one numeric, and one special character.</Typography>} placement='top'>
                                            <WarningRoundedIcon sx={{ ml: 1 }} color='warning' />
                                        </Tooltip>
                                    </>
                                ),
                            }}
                            type={inputType}
                            textHelper={capsLock ? 'Caps lock is on' : ''}
                            {...getFieldProps('password')}
                        />

                        <div className='w-100'>
                            <PhoneInput
                                InputProps={classes.phoneField}
                                value={values.phone}
                                error={errors?.phone || null}
                                onChange={(e) => setFieldValue('phone', e)}
                            />
                        </div>

                        <GooglePlacesAutocomplete
                            inputPlaceHolder='Address *'
                            error={errors.address?.number || errors.address?.street || errors.address?.city || errors.address?.state || errors.address?.zip || ''}
                            value={values.address}
                            handleSelectAddress={(address) => setFieldValue('address', address)}
                        />

                        <TextField
                            variant='standard'
                            placeholder='Apartment, suite, etc.'
                            {...getFieldProps('apartment')}
                        />

                        <div className={clsx('flex-center')}>
                            <CheckBox className={classes.checkBox} checked={isAgree} onChange={() => setIsAgree(!isAgree)} />
                            <Typography color='placeholder' variant='body2'>
                                I agree to
                                <Link to={'/terms'} className={classes.link}>
                                    Terms of Use
                                </Link>
                                and
                                <Link to={'/policy'} className={classes.link}>
                                    Privacy Policy
                                </Link>
                            </Typography>
                        </div>
                        <Typography color='placeholder' variant='body2'>
                            I agree to receive promotional messages sent via SMS, and this agreement isn’t a condition of any purchase. I also agree to the Terms of Service and Privacy Policy 4 Msgs/Month. Msg & Data rates may apply.
                        </Typography>
                    </div>

                    <Box sx={{ mt: 3, textAlign: 'center', maxWidth: '400px' }}>
                        {error && <ErrorAuthMessage error={error} />}
                        <Button
                            className={clsx('rounded-button', classes.continueButton)}
                            variant="contained"
                            color="secondary"
                            type="submit"
                            pending={loading}
                            disabled={loading || !isAgree}
                        >
                            Continue
                        </Button>
                    </Box>

                </form>
            </div>
            <div className='authFooter'>
                <Typography variant='body1'>Already have an account? </Typography>
                <RouterLink to={ROUTE_LOGIN.path} >
                    Sign In
                </RouterLink>
            </div>
        </div>
    )
}

export default SignStep
