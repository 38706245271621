import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
    mainContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
    phoneField: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.14) !important',
    },
    button: {
        marginTop: theme.spacing(4),
    },
    inputWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridGap: 20,
        padding: '35px 0',
        paddingBottom: '0px',
        width: '100%',
        justifyItems: 'center',
        maxWidth: 400,
    },
    continueButton: {
        padding: '15px 40px',
        margin: '2em',
    },
    checkBox: {
        marginRight: '2px',
    },
    link: {
        margin: '0 4px',
        textDecoration: 'underline !important',
        color: '#000 !important',
    },
    errorText: {
        marginTop: '25px !important',
    },
}))
