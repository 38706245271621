import clsx from 'clsx'
import InputAdornment from '@mui/material/InputAdornment'
import Grid from '@mui/material/Grid'

import Typography from '@/components/common/Typography'
import { TextField } from '@/components/common/Inputs'
import { Button } from '@/components/common/Buttons'
import FileInput from '@/components/generics/FileInputSignUp'
import Notifications from '@/components/generics/Notifications'

import useCrud from '@/hooks/useCrud'
import { useForm } from '@/hooks'
import { LicenseInsuranceValues } from './types'
import { SignUpComponentsProps } from '../../types'
import { useStyles } from './styles'
import { getInitialValues, useScheme } from './validations'
import { stringToBoolean, uploadFilesToS3 } from '@/utils'
import { API_SIGNED_FILE_URL, API_SIGN_UP } from '@/constants/routes'
import { FileType } from '@/common/enums'
import { UploadCourierFiles } from '@/common/types'
import { KEY_UPLOAD_FILES } from '@/constants'

import car from '@/assets/icon/ico-car.svg'
import { useState } from 'react'
import { Box } from '@mui/material'
import { AUTO_POLICY_BOX } from '@/constants/testIds'
import RadioGroup from '@/components/common/Inputs/RadioGroup'

const LicenseInsuranceStep = (props: SignUpComponentsProps) => {
    const { classes } = useStyles()
    const schema = useScheme()
    const [apiRequest] = useCrud('POST')

    const [files, setFiles] = useState<UploadCourierFiles>({})
    const [loading, setLoading] = useState(false)

    const onSubmit = async (values: LicenseInsuranceValues) => {
        try {
            setLoading(true)
            await uploadFilesToS3(Object.values(files))

            const { carModel, carYear, make, front, back, leftSide, rightSide, ...rest } = values
            const payload = {
                vehicle: {
                    make,
                    model: carModel,
                    year: parseInt(carYear),
                    frontPicture: front,
                    backPicture: back,
                    leftPicture: leftSide,
                    rightPicture: rightSide,
                },
                ...rest,
            }
            const response = await apiRequest(API_SIGN_UP.SET_LICENSE_INSURANCE_INFORMATION, payload)
            if (response) {
                props.nextStep(response.registrationStep)
            }
        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const { getFieldProps, values, errors, setFieldValue, handleSubmit } = useForm(
        schema,
        getInitialValues(),
        onSubmit,
    )

    const handleSelectFile = async (file: React.ChangeEvent<HTMLInputElement>, fileType: FileType) => {
        if (!file.target.files?.length) return
        const selectedFile = file.target.files[0]

        const keyFile = KEY_UPLOAD_FILES[fileType]
        setFiles((files) => ({
            ...files, [keyFile]: {
                loading: true,
            },
        }))

        const { uploadUri, uri } = await apiRequest(API_SIGNED_FILE_URL, {
            extension: selectedFile.type.split('/')[1],
            type: fileType,
        })

        if (!uploadUri || !uri) {
            setFiles((files) => ({
                ...files, [keyFile]: {
                    error: true,
                    loading: false,
                },
            }))
            return
        }

        setFiles((files) => ({
            ...files, [keyFile]: {
                error: false,
                uri,
                uploadUri,
                file: selectedFile,
                preview: URL.createObjectURL(selectedFile),
                loading: false,
            },
        }))

        setFieldValue(keyFile, uri)
    }

    return (
        <div className={classes.mainContent}>
            <Box mt={3}>
                <Typography variant="h4" gutter='label' align='center'>Applicant Details</Typography>
                <Notifications isPersonalInformation />
            </Box>

            <form action="#" onSubmit={handleSubmit} className={clsx('d-flex', 'flex-column', 'align-center')}>
                <div className={classes.inputWrapper}>
                    <Grid container spacing={2} rowSpacing={3} marginBottom={2}>
                        <Grid item xs={12}>
                            <FileInput
                                id={'License'}
                                label="Driver's License"
                                required
                                inputProps={{ accept: '.png,jpeg,.jpg' }}
                                isTooltip
                                isLoading={files.licensePicture?.loading}
                                value={files.licensePicture?.preview}
                                errors={errors.licensePicture && !values.licensePicture ? errors.licensePicture : ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectFile(e, FileType.COURIER_LICENSE)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FileInput
                                id={'Insurance'}
                                label='Car Insurance Card'
                                required
                                inputProps={{ accept: '.png,jpeg,.jpg' }}
                                isTooltip
                                isLoading={files.insurancePicture?.loading}
                                value={files.insurancePicture?.preview}
                                errors={errors.insurancePicture && !values.insurancePicture ? errors.insurancePicture : ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectFile(e, FileType.COURIER_INSURANCE)}
                            />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                            <Grid item xs={12}>
                                <Typography gutter='label'>
                                    Is your personal auto policy endorsed to allow for business use?
                                </Typography>
                                <RadioGroup
                                    id={AUTO_POLICY_BOX}
                                    items={[]}
                                    value={values.autoPolicy}
                                    error={errors?.autoPolicy || null}
                                    onChange={(e) => setFieldValue('autoPolicy', stringToBoolean(e.target.value))}
                                />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} rowSpacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                variant='standard'
                                placeholder='Make'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img src={car} />
                                        </InputAdornment>
                                    ),
                                }}
                                {...getFieldProps('make')}
                                id='input-carMake'
                                error={errors.make && !values.make ? errors.make : ''}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                variant='standard'
                                placeholder='Model'
                                {...getFieldProps('carModel')}
                                id='input-carModel'
                                error={errors.carModel && !values.carModel ? errors.carModel : ''}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                variant='standard'
                                placeholder='Year'
                                {...getFieldProps('carYear')}
                                id='input-carYear'
                                error={(errors.carYear || !values.carYear) ? errors.carYear : ''}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FileInput
                                id={'vehicleFrontPicture'}
                                label="Vehicle Front Picture"
                                required
                                inputProps={{ accept: '.png,.jpeg,.jpg,.webp,.avif' }}
                                isTooltip
                                isLoading={files.front?.loading}
                                value={files.front?.preview}
                                errors={errors.front && !values.front ? errors.front : ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectFile(e, FileType.COURIER_VEHICLE_FRONT)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FileInput
                                id={'vehicleBackPicture'}
                                label='Vehicle Back Picture'
                                required
                                inputProps={{ accept: '.png,.jpeg,.jpg,.webp,.avif' }}
                                isTooltip
                                isLoading={files.back?.loading}
                                value={files.back?.preview}
                                errors={errors.back && !values.back ? errors.back : ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectFile(e, FileType.COURIER_VEHICLE_BACK)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FileInput
                                id={'vehicleLeftPicture'}
                                label='Vehicle Left Picture'
                                required
                                inputProps={{ accept: '.png,.jpeg,.jpg,.webp,.avif' }}
                                isTooltip
                                isLoading={files.leftSide?.loading}
                                value={files.leftSide?.preview}
                                errors={errors.leftSide && !values.leftSide ? errors.leftSide : ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectFile(e, FileType.COURIER_VEHICLE_LEFT)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FileInput
                                id={'vehicleRightPicture'}
                                label='Vehicle Right Picture'
                                required
                                inputProps={{ accept: '.png,.jpeg,.jpg,.webp,.avif' }}
                                isTooltip
                                isLoading={files.rightSide?.loading}
                                value={files.rightSide?.preview}
                                errors={errors.rightSide && !values.rightSide ? errors.rightSide : ''}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSelectFile(e, FileType.COURIER_VEHICLE_RIGHT)}
                            />
                        </Grid>
                    </Grid>
                    <div className='flex-center'>
                        <Button
                            className={clsx('rounded-button', classes.continueButton)}
                            disabled={loading}
                            variant="contained"
                            color="secondary"
                            pending={loading}
                            type="submit"
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default LicenseInsuranceStep
