import Typography from '@/components/common/Typography'
import { CODE_MISMATCH_EXCEPTION, INVALID_CREDENTIALS, INVALID_PASSWORD_EXCEPTION, USER_NOT_CONFIRMED_EXCEPTION, USER_NOT_FOUND_EXCEPTION } from '@/constants'
import { Box } from '@mui/material'

const ERROR_MESSAGES: {[key: string]: string} = {
    [USER_NOT_CONFIRMED_EXCEPTION]: 'User not confirmed',
    [INVALID_CREDENTIALS]: 'Incorrect email or password',
    [INVALID_PASSWORD_EXCEPTION]: 'Password does not conform to policy',
    [USER_NOT_FOUND_EXCEPTION]: 'User not found',
    [CODE_MISMATCH_EXCEPTION]: 'Invalid verification code provided, please try again',
}

interface Props {
    error: string,
    mb?: number,
    className?: string
}

const ErrorAuthMessage = ({ error = '', mb = 1, className }: Props) => {
    return (
        <Box sx={{ mb }}>
            <Typography color="error" className={className}>
                {error && ERROR_MESSAGES[error] ? ERROR_MESSAGES[error] : error}
            </Typography>
        </Box>
    )
}

export default ErrorAuthMessage
