import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
    takeSelfieContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '3em',
        justifyContent: 'center',
        height: '100%',
    },
    pictureContainer: {
        width: '200px',
        height: '200px',
        border: '1px solid #006cf0',
        padding: 5,
        borderRadius: 10,
        '& img': {
            borderRadius: 10,
            objectFit: 'fill',
            width: '100%',
            height: '100%',
        },
    },
}))
