import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@mui/material/Grid'

import { DialogTitleProps } from '../types'

const Header = ({ children, secondaryActions, onClose }: DialogTitleProps) => {
    return (
        <DialogTitle sx={{ m: 0, paddingBlock: 1, paddingInline: 2 }}>
            <Grid container alignItems='center' justifyContent="space-between">
                <Grid item>{secondaryActions}</Grid>
                <Grid item>{children}</Grid>
                { onClose &&
                <Grid item>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Grid>
                }
            </Grid>
        </DialogTitle>
    )
}

export default Header