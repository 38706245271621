import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'

import { AuthUser, DestructByKey, Settings, Steps, updateCourierPayload } from '@/common/types'
import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Loader from '@/components/common/Loader'
import useFetch from '@/hooks/useFetch'
import { API_SETTINGS } from '@/constants/routes'
import { useAuth } from '@/hooks/useAuth'
import { testProp } from '@/constants/testIds'
import { IconButton } from '@mui/material'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'
import { CourierRegistrationStatus, CourierRegistrationSteps, CourierType, QuizStatus, StepType } from '@/common/enums'
import { steps, stepsApp, stepsOnFleet } from './types'
import Modal from '../Modal'
import { useStyles } from './styles'
import InfoStep from './Components/InfoStep'
import VideoStep from './Components/VideoStep'
import TestStep from './Components/TestStep'
import GuideStep from './Components/GuideStep'
import AppStep from './Components/appStep'

const renderSteps = (step: number, currentStep: string, data: Steps, setUser: (_user: AuthUser | null) => void, setCourier: (_values: updateCourierPayload) => Promise<AuthUser>, courier?: AuthUser | null) => {
    const stepsView: DestructByKey<React.ReactNode> = {
        welcome: <InfoStep data={data} />,
        'training-video': <VideoStep data={data} courier={courier ? courier : null} setUser={setUser} setCourier={setCourier} />,
        'hippa-video': <VideoStep data={data} courier={courier ? courier : null} setUser={setUser} setCourier={setCourier} />,
        quiz: <TestStep data={data} courier={courier ? courier : null} setUser={setUser} />,
        'join-team': <InfoStep data={data} />,
        'link-bank': <InfoStep data={data} />,
        'view-stats': <InfoStep data={data} />,
        'view-profile': <InfoStep data={data} />,
        'we-are-here': <InfoStep data={data} />,
        'need-help': <InfoStep data={data} />,
        'onfleet-video': <VideoStep data={data} courier={courier ? courier : null} setUser={setUser} setCourier={setCourier} />,
        'onfleet-pdf': <GuideStep data={data} />,
        'app-download': <AppStep data={data} />,
        'app-video': <VideoStep data={data} courier={courier ? courier : null} setUser={setUser} setCourier={setCourier} />,
        'onfleet-settings': <InfoStep data={data} />,
        ready: <InfoStep data={data} />,
    }

    return stepsView[currentStep] || null
}

interface Props {
    isOpen: boolean
    setIsOpen: () => void
    setIsClose: () => void
}

const filterStepsByCourierType = (steps: Steps[], courierType: CourierType) => {
    return steps.filter(item => item.showForCourierTypes.includes(courierType))
}

const DashboardSteps = ({ isOpen, setIsOpen, setIsClose }: Props) => {
    const [step, setStep] = useState(1)
    const [totalSteps, setTotalSteps] = useState(steps)
    const { user, setUser, updateCourier } = useAuth()
    const [settings, isLoading] = useFetch<Settings[]>(API_SETTINGS.GET_SETTINGS)
    const { classes } = useStyles()
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })
    const [currentStep, setCurrentStep] = useState(steps[0].key)

    const handlePlusStep = () => {
        if (step < totalSteps.length) {
            setStep(step + 1)
            setCurrentStep(totalSteps[step].key)
        }
    }

    const onboardingFinished = () => {
        updateCourier({ onboarded: true })
        setIsClose()
    }

    const handleCloseModal = () => {
        if (isOpen) {
            setStep(1)
            setCurrentStep(steps[0].key)
        }
        setIsClose()
    }

    const handleMinusStep = () => {
        if (step > 1) {
            setStep(step - 1)
            setCurrentStep(totalSteps[step - 2].key)
        }
    }

    useEffect(() => {
        if (settings && settings.find(item => item.key === StepType.SHOW_ONFLEET_VIDEO)?.value === '1') {
            setTotalSteps(filterStepsByCourierType([...steps, ...stepsOnFleet], user?.courierType as CourierType))
        } else if (settings) {
            setTotalSteps(filterStepsByCourierType([...steps, ...stepsApp], user?.courierType as CourierType))
        }
        if (settings && user?.registrationStatus !== CourierRegistrationStatus.SIGNATURE_PENDING && [CourierRegistrationSteps.ONBOARDING, CourierRegistrationSteps.ONBOARDING_PENDING].includes(user?.registrationStep as CourierRegistrationSteps) && (!user?.onboarded || !user.HIPAAcompleted || user.quizStatus !== QuizStatus.PASSED)) {
            setIsOpen()
        }
    }, [settings, user, isOpen])

    return (
        <>
            {isLoading ? (
                <Loader open={isLoading} />
            ) :
                <Modal
                    open={isOpen}
                    center
                    onClose={handleCloseModal}
                    maxWidth={'md'}
                >
                    {user?.quizStatus === QuizStatus.PASSED && (
                        <IconButton size="small" onClick={handleCloseModal} className={classes.closeBtn}>
                            <CloseIcon />
                        </IconButton>
                    )}
                    <div className={classes.header}>
                        Step {step}/{totalSteps.length}
                    </div>
                    <Box sx={{ width: '100%' }}>
                        {renderSteps(step, currentStep, totalSteps[step - 1], setUser, updateCourier, user)}
                    </Box>

                    <div className={isSmallScreen ? classes.mobileActions : classes.actions}>
                        {step !== 1 ? (
                            <div className={classes.actionSpace}>
                                <Button className={classes.prevBtn} color="inherit" onClick={handleMinusStep}>
                                    {'< previous'}
                                </Button>
                            </div>
                        ) : <div className={classes.actionSpace} />}
                        <Button
                            {...testProp(step.toString())}
                            className={classes.nextBtn}
                            variant="contained"
                            color="secondary"
                            onClick={step >= totalSteps.length ? onboardingFinished : handlePlusStep}
                            disabled={
                                (
                                    (step === 2 && !user?.videoTrainingCompleted) || (step === 3 && !user?.HIPAAcompleted) || (step === 4 && user?.quizStatus !== QuizStatus.PASSED)
                                )
                            }
                        >
                            {step === 1 ? 'Start' : step < totalSteps.length ? 'Next Step' : 'Get Started'}
                        </Button>
                        <div className={classes.actionSpace} />
                    </div>
                </Modal>
            }
        </>
    )
}

export default DashboardSteps