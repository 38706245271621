import { Grid } from '@mui/material'
import Paper from '@/components/generics/Paper'
import { useAuth } from '@/hooks/useAuth'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'
import DownloadApp from './Components/DownloadApp'
import TransactionInformation from './Components/TransactionList/TransactionInformation'
import EarningMetrics from './Components/EarningMetrics/EarningMetrics'
import OrderInformation from './Components/OrderList/OrderInformation'
import { CourierType } from '@/common/enums'

export const Earning = () => {
    const { user } = useAuth()
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })

    return (
        <div className='page-content'>
            <Grid container spacing={1} marginBottom={3}>
                <Grid item xs={12}>
                    <Paper px={isSmallScreen ? 2 : 10} py={1}>
                        <EarningMetrics />
                    </Paper>
                </Grid>
            </Grid>
            {user?.courierType === CourierType.MZ_1099 &&
                <Grid container spacing={1} marginBottom={3}>
                    <Grid item xs={12}>
                        <Paper px={isSmallScreen ? 0 : 10} py={1}>
                            <TransactionInformation courier={user} />
                        </Paper>
                    </Grid>
                </Grid>
            }
            <Grid container spacing={1} marginBottom={3}>
                <Grid item xs={12}>
                    <Paper px={10} py={1}>
                        <OrderInformation courier={user} />
                    </Paper>
                </Grid>
            </Grid>
            <Grid container spacing={1} marginBottom={3}>
                <DownloadApp />
            </Grid>
        </div>
    )
}