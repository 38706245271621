import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
    mainContent: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    backArrowContainer: {
        width: '100%',
    },
    inputWrapper: {
        display: 'grid',
        maxWidth: 500,
        gridGap: 30,
        padding: '35px 0',
    },
    continueButton: {
        padding: '15px 40px',
    },
}))
