import React from 'react'
import ReactDOM from 'react-dom/client'
import {
    BrowserRouter,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from 'react-router-dom'
import * as Sentry from '@sentry/react'
import App from './App'
import './index.css'

const env = import.meta.env.VITE_ENV

Sentry.init({
    dsn: 'https://aebfadd29d676cdef0b2fb494b2e3ef9@o4506430111416320.ingest.sentry.io/4506814474223616',
    integrations: [
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration({
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
    // Performance Monitoring
    tracesSampleRate: env === 'development' || env === 'demo' ? 0 : 0.5,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['https://courier.demo.medzoomer.com', 'https://courier.dev.medzoomer.com', 'https://courier.test.medzoomer.com', 'https://courier.medzoomer.com'],
    // Session Replay
    replaysSessionSampleRate: env === 'development' || env === 'demo'
        ? 0 : 0.5,
    replaysOnErrorSampleRate: 1.0,
    enabled: env === 'production',
    environment: env || 'development',
    beforeSend(event) {
        if (event.exception && event.exception.values && event.exception.values[0]) {
            const error = event.exception.values[0]
            if (error.type === 'Error' && error.value && error.value === 'The user is not authenticated') {
                return null
            }
        }
        return event
    },
})

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
)
