import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
    mainContent: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
    backArrowContainer: {
        width: '100%',
    },
    button: {
        padding: '15px 40px',
        margin: '2em',
    },
    signInFooter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '10px',
    },
}))
