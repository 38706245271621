import { useId } from 'react'
import clsx from 'clsx'
import { makeStyles } from 'tss-react/mui'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import Typography from '@/components/common/Typography'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import { InputBaseProps } from '@mui/material'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import ErrorOutlinedIcon from '@mui/icons-material/ErrorOutlined'
import Input from '@mui/material/Input'

import LoaderSmall from '@/components/common/Loader/LoaderSmall'

export type FileFieldProps = InputBaseProps & {
    id?: string
    label?: string;
    secondLabel?: string;
    imageShow?: boolean;
    value?: any;
    errors?: string;
    isLoading?: boolean;
    isTooltip?: boolean;
    err?: boolean;
    isVideo?: boolean;
    required?: boolean;
    onChange: (_e: React.ChangeEvent<HTMLInputElement>) => void;
};

const FileInputRegister: React.FC<FileFieldProps> = (props) => {
    const { classes } = useStyles()
    const inputId = useId()

    const {
        id, label, required, inputProps, onChange, value, errors, secondLabel = 'Required', imageShow = true, isLoading, isTooltip, err, isVideo,
    } = props

    return (
        <>
            <div className={classes.wrapper}>
                <FormControl className={classes.root}>
                    <InputLabel shrink htmlFor={inputId} classes={{ formControl: classes.inputLabel }} id={id}>
                        <FileUploadOutlinedIcon className={classes.fileIcon} />
                        {label}
                        <Input
                            inputProps={inputProps}
                            id={inputId}
                            name={id}
                            type={'file'}
                            onChange={onChange}
                            classes={{ root: classes.input, input: classes.inputRoot }}
                        />
                    </InputLabel>
                    {isLoading ? (
                        <LoaderSmall size='1.5em' position='right' fullWidth={false} />
                    ) : imageShow && value && !isLoading ? (
                        <img src={value} alt="empty" className={classes.img} />
                    ) : (
                        required && <Typography className={classes.optional}>{secondLabel}</Typography>
                    )}
                </FormControl>
                {isTooltip ? (
                    <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        arrow
                        title={
                            <Typography variant='body2' className={clsx(classes.warning, { [classes.err]: err })}>
                                Formats required: {isVideo ? 'mp4, mpeg-4, mov, avi' : 'jpg, jpeg or png'}
                            </Typography>
                        }
                        placement={'top'}
                    >
                        <IconButton className={classes.infoIcon}>
                            {err ? <ErrorOutlinedIcon color='primary' /> : <InfoOutlinedIcon color='secondary' />}
                        </IconButton>
                    </Tooltip>
                ) : null}
            </div>
            <div>
                {!!errors &&
                    <Typography noWrap={true} color="error" variant="caption">
                        {typeof errors === 'string' ? errors : 'Error'}
                    </Typography>
                }
            </div>
        </>
    )
}

const useStyles = makeStyles()((theme) => ({
    input: {
        display: 'none',
        'label + &': {
            marginTop: '0 !important',
        },
    },
    wrapper: {
        display: 'flex',
        alignItems: 'baseline',
        marginBottom: 5,
        width: '100%',
    },
    tooltip: {
        boxShadow: '0 0 4px 0 rgba(0, 0, 0, 0.06)',
        border: '1px solid rgba(0, 0, 0, 0.06)',
        backgroundColor: '#ffffff',
        borderRadius: '25px',
        color: 'red',
    },
    warning: {
        color: '#e2971c',
    },
    err: {
        color: '#e21c1c',
    },
    root: {
        //marginBottom: theme.spacing(1),
        width: '100%',
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        borderBottomStyle: 'dashed',
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexFlow: 'row',
    },
    inputLabel: {
        position: 'relative',
        transform: 'none',
        '&.Mui-focused': {
            color: theme.palette.text.secondary,
        },
        fontWeight: theme.typography.fontWeightMedium,
        marginBottom: 9,
        color: theme.palette.secondary.main,
        fontSize: 15,
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
    },
    inputRoot: {
        width: '100%',
    },
    fileIcon: {
        marginRight: '5px',
    },
    infoIcon: {
        marginLeft: '10px',
        padding: '0px',
    },
    optional: {
        marginBottom: '9px',
        fontSize: 13,
        color: '#999999',
    },
    img: {
        paddingBottom: 5,
        maxWidth: '20%',
        width: '80px',
        height: '60px',
        objectFit: 'cover',
        [theme.breakpoints.down('xs')]: {
            maxWidth: '100%',
        },
    },
}))

export default FileInputRegister
