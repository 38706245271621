import FilterListIcon from '@mui/icons-material/FilterList'
import Badge from '@mui/material/Badge'

import { Button } from '@/components/common/Buttons'

import styles from './styles.module.css'
import { FilterValues } from '@/common/types'

interface Props {
    filters?: FilterValues
    onClick?: () => void
}

const FilterButtonList = ({ filters, onClick }: Props) => {
    return (
        <Badge color="primary" badgeContent={filters ? Object.keys(filters).length : 0}>
            <Button
                id='filterButton'
                className={styles.filterButton}
                isIcon
                variant='outlined'
                size='small'
                onClick={onClick}
            >
                <FilterListIcon />
            </Button>
        </Badge>
        
    )
}

export default FilterButtonList