import clsx from 'clsx'
import MUITextField from '@mui/material/TextField'
import Typography from '../../Typography'
import useStyles from './styles'

interface TextFieldProps {
    InputProps?: any;
    error?: string | boolean | null | undefined;
    maxLength?: number;
    disabled?: boolean
    label?: string;
    fullWidth?: boolean;
    disableBrowserAutoComplete?: boolean;
    big?: boolean;
    medium?: boolean;
    className?: string;
    id?: string;
    name?: string;
    align?: string;
    variant?: 'standard' | 'outlined' | 'filled';
    textHelper?: string;
    noWrapLabel?: boolean;
    numericKeyboard?: boolean;
    value?: string;
    placeholder?: string;
    type?: string;
    inputRef?: any;
    onChange?: (_e: React.ChangeEvent<HTMLInputElement>) => void;
    onKeyDown?: (_e: React.KeyboardEvent<HTMLInputElement>) => void;
}

export default function TextField(props: TextFieldProps) {
    const {
        numericKeyboard = false,
        InputProps: _InputProps,
        error,
        maxLength,
        disabled = false,
        label = null,
        fullWidth = true,
        disableBrowserAutoComplete = true,
        big = false,
        medium = false,
        className,
        id = '',
        name = '',
        align = '',
        variant = 'outlined',
        textHelper = '',
        noWrapLabel = false,
        inputRef,
        ...rest
    } = props
    const InputProps = _InputProps || {}
    const { classes: styles } = useStyles()

    if (disableBrowserAutoComplete) InputProps.autoComplete = 'off'
    if (typeof maxLength === 'number') InputProps.maxLength = maxLength
    if (numericKeyboard) InputProps.inputProps = { inputMode: 'numeric', pattern: '[0-9]*' }
    return (
        <>
            {label && (
                <Typography noWrap={noWrapLabel} weight="500" color="placeholder" gutter="label" variant="body2">
                    {label}
                </Typography>
            )}
            <MUITextField
                id={id || `input-${name}`}
                name={name}
                disabled={disabled}
                InputProps={InputProps}
                inputRef={inputRef}
                variant={variant}
                error={Boolean(error)}
                helperText={error || textHelper}
                FormHelperTextProps={{
                    classes:{
                        root: styles.errorText,
                    },
                }}
                hiddenLabel={!(error || label)}
                fullWidth={fullWidth}
                autoComplete={'off'}
                className={clsx(
                    styles.root,
                    className,
                    big && styles.bigInput,
                    medium && styles.mediumInput,
                    align && `textfield-${align}`,
                )}
                {...rest}
            />
        </>
    )
}
