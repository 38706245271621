import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@/components/common/Typography'
import { formatDate } from '@/utils/dates'
import { formatCurrency, formatToTitleCase } from '@/utils'
import { Box } from '@mui/system'
import { DATE_PRETTY_FORMAT_AND_HOURS } from '@/constants'
import StatusIcon from '@/components/generics/StatusIcon'
import { TransactionListItem } from '@/common/types/transactions'

interface Props {
   item: TransactionListItem
}
const Item = ({ item }: Props) => {
    return (
        <TableRow key={item.id}>
            <TableCell align='center'>
                <Box >
                    <Typography variant='body2' color="hint">{formatDate(item.createdAt, DATE_PRETTY_FORMAT_AND_HOURS)}</Typography>
                </Box>
            </TableCell>
            <TableCell align='center'>
                {formatToTitleCase(item.type.replaceAll('_', ' '))} {item?.task?.orderNumber ?  `(${item?.task?.orderNumber})` : '' }
            </TableCell>
            <TableCell align='center'>
                <StatusIcon justify='center' status={item.status} />
            </TableCell>
            <TableCell align='center'>
                <Typography weight='bold' variant='body2' color={ (item.amount &&item.amount >= 0) ? '#4cc71d' : 'primary' }>{formatCurrency(item.amount || 0)}</Typography>
            </TableCell>
            <TableCell align='center'>
                <Typography weight='bold' variant="body2" color={ item.balanceAfterTransaction >= 0 ? '#4cc71d' : 'primary' }>{formatCurrency(item.balanceAfterTransaction)}</Typography>
            </TableCell>
        </TableRow>
    )
}

export default Item