import Dialog from '@mui/material/Dialog'
import { styled } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'

const ModalDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-container': {
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    '& .MuiBackdrop-root': {
        background: 'rgba(255, 255, 255, 0.75)',
    },
    '& .MuiDialog-paper': {
        borderRadius: 25,
        boxShadow: '0 0 20px 0 rgb(0 0 0 / 14%)',
        margin: 0,
        width: '100%',
    },
    '& .MuiDialogTitle-root': {
        textAlign: 'center',
    },
    '& .MuiDialogContent-root': {
        padding: '30px 40px',
        background: 'white',
        border: 'none',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}))

const useStyles = makeStyles()(() => ({
    center: {
        '& .MuiDialog-container': {
            alignItems: 'center !important',
        },
    },
}))

export { useStyles }

export default ModalDialog