import { DayEnum } from '@/common/enums'
import { TimeType } from '@/common/types'
import dayjs from 'dayjs'
import * as Yup from 'yup'

export const initialValues = {
    pickupTime: null,
    days: [],
}

export const getInitialValues = (item = {}) => {
    return Object.assign(
        item,
        initialValues,
    )
}

export const useScheme = () => {
    return Yup.object().shape({
        pickupTime: Yup.string().required('Pickup Time is required').test('is-valid-time', 'Invalid time', (value?: TimeType) => {
            if (!value) return false
            return dayjs(value).isValid()
        }).nullable(),
        days: Yup.array().min(1).required('Pickup Days is required'),
    })
}