import * as Yup from 'yup'

export const initialValues = {
    idCardPictureFile: '',
}

export const getInitialValues = () => {
    return Object.assign(
        {},
        initialValues,
    )
}

export const useScheme = () => {
    return Yup.object().shape({
        idCardPictureFile: Yup.string().required('Please, upload your picture'),
    })
}
