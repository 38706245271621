import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()((theme) => ({
    mainContent: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: 20,
        minHeight: '100vh',
    },
    exampleImageContainer: {
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(4),
        margin: theme.spacing(2, 0), 
    },
    exampleImage: {
        width: 'auto', 
        height: theme.spacing(20), 
    },
    exampleImageWrapper: {
        position: 'relative',
        display: 'flex', 
    },
    goodExampleBorder: {
        border: `2px solid ${theme.palette.success.light}`,
        borderRadius: theme.shape.borderRadius, 
    },
    badExampleBorder: {
        border: `2px solid ${theme.palette.error.light}`, 
        borderRadius: theme.shape.borderRadius,
    },
    icon: {
        position: 'absolute',
        top: 0,
        right: 0,
        fontSize: theme.spacing(3), 
    },
    checkIcon: {
        color: theme.palette.success.main, 
    },
    crossIcon: {
        color: theme.palette.error.main, 
    },
    inputWrapper: {
        width: '100%', 
        marginTop: theme.spacing(2),
    },
    continueButton: {
        padding: '15px 40px',
        marginBlock: 20,
    },
}))

