import { TimeType } from '@/common/types'

export type SignUpValues = {
    birthdate: TimeType,
    gender: string,
    tShirt: string,
    hatQuestion: string,
    heardFrom: string,
    hasWorkedInOtherServices: boolean | null,
    hatQuestionItem: string,
    primaryLanguage: string,
    secondaryLanguage: string | null,
    otherService: string | null,
    device: string,
}

export const DEVICES = [
    { key: 'ios', value: 'iOS' },
    { key: 'android', value: 'Android' },
]

export type LicenseInsuranceValues = {
    licensePicture: string,
    insurancePicture: string,
    make: string,
    carModel: string,
    carYear: string,
}

export const T_SHIRTS = [
    { key: 'XS', value: 'XS' },
    { key: 'S', value: 'S' },
    { key: 'M', value: 'M' },
    { key: 'L', value: 'L' },
    { key: 'XL', value: 'XL' },
    { key: 'XXL', value: 'XXL' },
]

export const GENDER = [
    { key: 'MALE', value: 'Male' },
    { key: 'FEMALE', value: 'Female' },
    { key: 'OTHER', value: 'Other' },
]

export const HAT = [
    { key: 'hat', value: 'Hat' },
    { key: 'visor', value: 'Visor' },
    { key: 'none', value: 'None' },
]

export const LANGUAGES = [
    { key: 'English', value: 'English' },
    { key: 'Spanish', value: 'Spanish' },
    { key: 'Hindi', value: 'Hindi' },
    { key: 'French', value: 'French' },
]

export const HEARD_FROM_OPTIONS = [
    { key: 'Social Media', value: 'Social Media' },
    { key: 'Google', value: 'Google' },
    { key: 'Referral', value: 'Referral' },
    { key: 'Other', value: 'Other' },
]

export const OTHER_DELIVERY_SERVICES = [
    { key: 'DOORDASH', value: 'Doordash' },
    { key: 'UBER_EATS', value: 'Uber eats' },
    { key: 'INSTACART', value: 'Instacart' },
    { key: 'GRUBHUB', value: 'Grubhub' },
]

export const DISTANCE = [
    {
        label: '0-10 mi',
        value: '10',
    },
    {
        label: '0-25 mi',
        value: '25',
    },
    {
        label: '0-50 mi',
        value: '50',
    },
    {
        label: '50+ mi',
        value: '-1',
    },
]
