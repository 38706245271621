import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
    termsWrapper:{
        height: '100vh',
        width: '100%',
        overflow: 'auto',
        color: 'gray',
    },
    textWrapper: {
        padding: '30px 120px 0',
    },
    footer: {
        padding: '24px 0',
        borderTop: '1px solid $border',
        justifyContent: 'space-between',
        display: 'flex',
        margin: '0 30px',
    },
    title: {
        textAlign: 'center',
        paddingBottom: '10px',
    },
}))
