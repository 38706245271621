import { Settings } from '@/common/types'
import { create } from 'zustand'

interface SettingsStore {
    settings: Settings[]
    checkrOnboardSignUpPreference: boolean
    setSettings: (_values: Settings[]) => void
}

export const useSettingsStore = create<SettingsStore>((set) => ({
    settings: [],
    checkrOnboardSignUpPreference: false,
    setSettings: (settings: Settings[]) => set((state) => {
        return {
            ...state,
            settings,
            checkrOnboardSignUpPreference: settings.find((setting) => setting.key === 'checkr_onboard_sign_up')?.value === '1',
        }
    }),
}))