import { createTheme as createMuiTheme } from '@mui/material/styles'
import { spacing } from './theme/common'
import palette from './theme/pallete'
import typography from './theme/typography'

const theme = createMuiTheme({
    direction: 'ltr',
    typography,
    palette,
    spacing,
    /*
shape: {
  borderRadius: 16
},
*/
    //breakpoints: { values: breakpoints.values },
    zIndex: {
        mobileStepper: 1000,
        speedDial: 1050,
        appBar: 1100,
        tooltip: 1500,
        drawer: 1600,
        modal: 1700,
        snackbar: 1800,
    },
})

export default theme
