export type BankAccountValues = {
    routingNumber: string,
    accountNumber: string,
    bankAccountType: string,
    name: string,
}

export const BANK_ACCOUNT = [
    { key: 'checking', value: 'Checking' },
    { key: 'savings', value: 'Savings' },
    // { key: 'loan', value: 'Loan' },
    // { key: 'general-ledger', value: 'General Ledger' },
]