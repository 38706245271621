import stepImg1 from '../../../assets/img/stepImg1@2x.png'
import stepImg3 from '../../../assets/img/stepImg3@2x.png'
import stepImg4 from '../../../assets/img/stepImg4@2x.png'
import stepImg5 from '../../../assets/img/stepImg5@2x.png'
import stepImg6 from '../../../assets/img/stepImg6@2x.png'
import stepImg7 from '../../../assets/img/stepImg7@2x.png'
import stepImg8 from '../../../assets/img/stepImg8@2x.png'
import stepImg10 from '../../../assets/img/stepImg10@2x.png'
import stepImg11 from '../../../assets/img/stepImg11@2x.png'
import stepImg12 from '../../../assets/img/stepImg12@2x.png'
import { StepType, CourierType } from '@/common/enums'
import { Steps } from '@/common/types'

const ALL_COURIER_TYPES = Object.values(CourierType)

export const steps: Steps[] = [
    {
        title: 'Congratulations on becoming a MedZoomer courier!',
        desc: 'Please complete this training wizard so you can start Zooming!',
        img: stepImg1,
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'welcome',
    },
    {
        title: 'Training Video',
        desc: 'Please take a moment to watch our Training Video. It will help you understand our delivery process. Watch the whole video and select Get Started once complete.',
        video: true,
        type: StepType.TRAINING_VIDEO,
        url: 'https://files.medzoomer.com/Medzoomer-Full-Training.mp4',
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'training-video',
    },
    {
        title: 'HIPAA Training',
        desc: 'Please take a moment to watch the Courier HIPAA Training Video, check the box below once complete.',
        video: true,
        type: StepType.HIPPA_VIDEO,
        url: 'https://player.vimeo.com/video/279540131?title=0&byline=0&portrait=0',
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'hippa-video',
    },
    {
        title: 'Take a test',
        desc: 'You are required to take this quiz before continuing on as a MedZoomer driver.',
        test: true,
        url: 'test',
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'quiz',
    },
    {
        title: 'Join a Team',
        desc:
            'Before you start receiving deliveries you must join at least one team through the Teams tab found in the left hand menu. Select as many teams as you like to make up your delivery area. You can always edit your selection later!',
        img: stepImg3,
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'join-team',
    },
    {
        title: 'Link your bank',
        desc: 'Link your bank account to begin receiving payments! \nUnder the Payment options tab input your banking details into the provided fields.',
        img: stepImg4,
        showForCourierTypes: [CourierType.MZ_1099],
        key: 'link-bank',
    },
    {
        title: 'View your stats',
        desc:
            'See how you’re doing! You can view your order history and total earnings through the Earning metrics and Order history tabs in the right hand menu.',
        img: stepImg5,
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'view-stats',
    },
    {
        title: 'View your profile',
        desc:
            'Update your personal information along with your driver’s license and vehicle photos through the Profile tab.',
        img: stepImg6,
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'view-profile',
    },
    {
        title: 'We’re here for you',
        desc:
            'One of our customer service reps will reach out by phone to confirm your information and finalize your account. Please save this number as we will be calling you for live support: (813) 613 6575',
        img: stepImg7,
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'we-are-here',
    },
    {
        title: 'Need some help?',
        desc: 'Visit https://www.medzoomer.com/get-help/ to view our FAQ page or to ask us a question.',
        img: stepImg8,
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'need-help',
    },
]

export const stepsOnFleet: Steps[] = [
    {
        title: 'Get familiar with OnFleet',
        desc: 'Please watch the brief training video.',
        video: true,
        type: StepType.ONFLEET_VIDEO,
        url: 'https://fast.wistia.com/embed/iframe/ak8wo2lfb4',
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'onfleet-video',
    },
    {
        title: 'Get familiar with OnFleet',
        desc: 'Please download and read this quick start guide.',
        pdf: true,
        img: stepImg10,
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'onfleet-pdf',
    },
    {
        title: 'Get familiar with OnFleet',
        desc: 'A text message will be sent to setup your OnFleet account, please remember to enter your vehicle make, color, and license plate in your OnFleet Driver App under the settings tab.',
        img: stepImg11,
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'onfleet-settings',
    },
    {
        title: 'You are ready!',
        desc: 'As your first step, please choose your MedZoomer team.',
        img: stepImg12,
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'ready',
    },
]

export const stepsApp: Steps[] = [
    {
        title: 'Get familiar with Medzoomer Courier App',
        desc: 'Please watch the brief training video.',
        video: true,
        type: StepType.APP_VIDEO,
        url: 'https://fast.wistia.com/embed/iframe/ak8wo2lfb4',
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'app-video',
    },
    {
        title: 'Download App',
        desc: 'Please download the Medzoomer Courier app for your device:',
        app: true,
        img: stepImg10,
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'app-download',
    },
    {
        title: 'You are ready!',
        desc: 'As your first step, please choose your MedZoomer team.',
        img: stepImg12,
        showForCourierTypes: ALL_COURIER_TYPES,
        key: 'ready',
    },
]