import MUIPaper from '@mui/material/Paper'
import { makeStyles } from 'tss-react/mui'
import clsx from 'clsx'
import React from 'react'

const useStyles = makeStyles()((theme) => ({
    forwardScroll: { height: '100%', overflow: 'hidden' },
    hidden: { overflow: 'hidden' },
    width: { width: 'auto' },

    'radius-0': { borderRadius: 0 },
    'radius-4': { borderRadius: 4 },
    'radius-8': { borderRadius: 8 },
    'radius-10': { borderRadius: 10 },
    'radius-15': { borderRadius: 15 },
    'radius-20': { borderRadius: 20 },

    '10py': { paddingTop: theme.spacing(10), paddingBottom: theme.spacing(10) },
    '7py': { paddingTop: theme.spacing(7), paddingBottom: theme.spacing(7) },
    '5py': { paddingTop: theme.spacing(5), paddingBottom: theme.spacing(5) },
    '4py': { paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4) },
    '3py': { paddingTop: theme.spacing(3), paddingBottom: theme.spacing(3) },
    '2py': { paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) },
    '1py': { paddingTop: theme.spacing(1), paddingBottom: theme.spacing(1) },
    '0py': { paddingTop: theme.spacing(0), paddingBottom: theme.spacing(0) },

    '10px': { paddingLeft: theme.spacing(7), paddingRight: theme.spacing(10) },
    '7px': { paddingLeft: theme.spacing(7), paddingRight: theme.spacing(7) },
    '5px': { paddingLeft: theme.spacing(5), paddingRight: theme.spacing(5) },
    '4px': { paddingLeft: theme.spacing(4), paddingRight: theme.spacing(4) },
    '3px': { paddingLeft: theme.spacing(3), paddingRight: theme.spacing(3) },
    '2px': { paddingLeft: theme.spacing(2), paddingRight: theme.spacing(2) },
    '1px': { paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) },
    '0px': { paddingLeft: theme.spacing(0), paddingRight: theme.spacing(0) },

    default: { backgroundColor: theme.palette.background.default },
    transparent: { backgroundColor: 'transparent' },
    primary: { backgroundColor: theme.palette.primary.dark },
    //primaryTabs: { backgroundColor: theme.palette.primary.tabs, color: theme.palette.common.white },
    //gray: { backgroundColor: theme.palette.gray.main },
    //paperForm: { backgroundColor: theme.palette.background.paperForm },
    //paperSecondary: { backgroundColor: theme.palette.gray.background },
    //active: { backgroundColor: theme.palette.gray.paperActive },
    success: { backgroundColor: theme.palette.success.main, color: theme.palette.common.white },
    error: { backgroundColor: theme.palette.error.main, color: theme.palette.common.white },
    info: { backgroundColor: theme.palette.info.main, color: theme.palette.common.white },

    'hover-default': { '&:hover': { backgroundColor: theme.palette.background.default } },
    'hover-transparent': { '&:hover': { backgroundColor: 'transparent' } },
    'hover-primary': { '&:hover': { backgroundColor: theme.palette.primary.dark } },
    //'hover-paperForm': { '&:hover': { backgroundColor: theme.palette.background.paperForm } },
    'bg-paperGray': { '&:hover': { backgroundColor: theme.palette.background.paper } },
    //'hover-active': { '&:hover': { backgroundColor: theme.palette.gray.paperActive } },
    'hover-info': { '&:hover': { backgroundColor: theme.palette.info.main } },

    //'fade-primary': { backgroundColor: theme.palette.primary.fade },
    //'fade-secondary': { backgroundColor: theme.palette.secondary.fade },
    //'fade-success': { backgroundColor: theme.palette.success.fade },
    //'fade-warning': { backgroundColor: theme.palette.warning.fade },
    //'fade-error': { backgroundColor: theme.palette.error.fade },
    //'fade-info': { backgroundColor: theme.palette.info.fade },
    //'fade-gray': { backgroundColor: theme.palette.gray.fade },
}))

interface PaperProps {
    children?: React.ReactNode;
    className?: string;
    hidden?: boolean;
    forwardScroll?: boolean;
    radius?: 0 | 4 | 8 | 10 | 15 | 20;
    elevation?: number;
    px?: 10 | 7 | 5 | 4 | 3 | 2 | 1 | 0;
    py?: 10 | 7 | 5 | 4 | 3 | 2 | 1 | 0;
    bgColor?: 'paperGray';
    fullWidth?: boolean;
}

const Paper = (props: PaperProps) => {
    const { classes: styles } = useStyles()
    const { children, fullWidth = true, bgColor = '', className, hidden, forwardScroll, radius = 10, elevation = 0, px = 3, py = 3, ...rest } = props
    return (
        <MUIPaper
            className={clsx(
                className,
                forwardScroll && styles.forwardScroll,
                hidden && styles.hidden,
                radius && styles[`radius-${radius}`],
                styles[`${py}py`],
                styles[`${px}px`],
                bgColor && styles[`bg-${bgColor}`],
                fullWidth && styles.width,
            )}
            elevation={elevation}
            {...rest}
        >
            {children}
        </MUIPaper>
    )
}

export default Paper
