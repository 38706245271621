import clsx from 'clsx'
import List from '@mui/material/List'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import Divider from '@mui/material/Divider'
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious'
import SkipNextIcon from '@mui/icons-material/SkipNext'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import MenuIcon from '@mui/icons-material/Menu'
import AppBar from '@mui/material/AppBar'

import AppLogo from '../Sidebar/components/Logo'
import Avatar from '@/components/common/Avatar'
import MenuItem from '../Sidebar/components/MenuItem'

import { APP_MENU } from '@/constants'

import useLogout from '@/hooks/useLogout'
import { useAuth } from '@/hooks/useAuth'

import { IconLogout } from '@/components/svg/Icons/Menu/IconLogout'

import { useBooleanHandlers } from '@/hooks/useBooleanHandlers'
import { useStyles } from './styles'
import Typography from '@/components/common/Typography'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'
import IconButton from '@mui/material/IconButton'

const AppBarMenu = () => {
    const { classes } = useStyles()
    const logout = useLogout()
    const { user } = useAuth()
    const [openDrawer, setDrawerOpen, setDrawerClose] = useBooleanHandlers(false)
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })

    const handleDrawerOpen = () => {
        setDrawerOpen()
    }
    const handleDrawerClose = () => {
        setDrawerClose()
    }

    return (
        <Box>
            <AppBar position="absolute" className={classes.paper} >
                <Toolbar className={classes.appBar}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(openDrawer && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="body1" noWrap color='inherit' align='center'>
                        Medzoomer
                    </Typography>
                    <img src={user?.profilePicture?.url} className={classes.image} />
                </Toolbar>
            </AppBar>
            <Drawer
                open={openDrawer}
                classes={{
                    paper: classes.paper,
                }}
                anchor="left"
            >
                <Toolbar disableGutters className={clsx(!isSmallScreen && classes.spacing, 'flex-center')}>
                    <AppLogo small={!openDrawer} />
                </Toolbar>
                <Divider variant='middle' light />
                <Toolbar disableGutters className={clsx(!isSmallScreen && classes.spacing)}>
                    <Avatar
                        text={openDrawer ? `${user?.name} ${user?.lastName}` : ''}
                        value={`${user?.name} ${user?.lastName}`}
                        subText={user?.email}
                        src={user?.profilePicture?.url}
                    />
                </Toolbar>
                <Divider variant='middle' light />

                {user &&
                    <List className={clsx(!isSmallScreen && classes.spacing)}>
                        {APP_MENU.map((item, index) => {
                            if (!item.show) return null
                            return (
                                <MenuItem user={user} key={index} item={item} openDrawer={openDrawer} handleDrawerClose={handleDrawerClose} />
                            )
                        })}

                        <Divider variant='middle' light />

                        <ListItemButton id='logoutButton' onClick={logout} classes={{
                            root: clsx(classes.menuItem),
                        }}>
                            <div className={classes.containerLink}>
                                <IconLogout />
                                {openDrawer &&
                                    <ListItemText primary='Logout' classes={{
                                        primary: classes.menuItemLink,
                                    }} />
                                }
                            </div>
                        </ListItemButton>
                    </List>
                }

                <div className={classes.containerCollapseSidebar} onClick={openDrawer ? setDrawerClose : setDrawerOpen}>
                    {openDrawer ? <SkipPreviousIcon /> : <SkipNextIcon />}
                    {openDrawer && <Typography variant='body1' color='white'>Hide</Typography>}
                </div>
            </Drawer>
        </Box>
    )
}

export default AppBarMenu