import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { Breakpoint } from '@mui/system'

interface BreakpointProp {
  breakpoint: Breakpoint;
}

export function useMediaQueryDown(breakpoint: BreakpointProp) {
    const theme = useTheme()
    const { breakpoint: size } = breakpoint
    return useMediaQuery(theme.breakpoints.down(size))
}

export function useMediaQueryUp(breakpoint: BreakpointProp) {
    const theme = useTheme()
    const { breakpoint: size } = breakpoint
    return useMediaQuery(theme.breakpoints.up(size))
}
