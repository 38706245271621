import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(() => ({
    scheduleStep: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '2em',
        justifyContent: 'center',
        height: '100%',
    },
    continueButton: {
        padding: '15px 40px',
        margin: '2em',
    },
    hoursBlock: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.14)',
    },
    hoursBlockItem: {
        position: 'relative',
        marginBottom: '15px',
    },
}))
