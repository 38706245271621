import { CognitoUser } from '@aws-amplify/auth'
import { create } from 'zustand'

interface SignUpData {
    user: CognitoUser | null
    email: string | null
    tmp: string | null
    setUser: (_user: CognitoUser) => void
    setEmail: (_email: string) => void
    setTmp: (_tmp: string | null) => void
}

export const useSignUpStore = create<SignUpData>((set) => ({
    user: null,
    email: null,
    tmp: null,
    setUser: (user: CognitoUser) => set({ user }),
    setEmail: (email: string) => set({ email }),
    setTmp: (tmp: string | null) => set({ tmp }),
}))
