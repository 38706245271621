import { useMemo } from 'react'
import { useStyles } from './styles'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Typography from '@/components/common/Typography'

const Messages = {
    agreement: 'Please read and sign our “Courier Agreement” which we use to onboard our new couriers. If you have any questions, please reach out to our Medzoomer Support Team at (888)954-9666. Thank You.',
    information: 'We will not, in any circumstances, share your personal information with other individuals or organizations without your permission, including public organizations, corporations or individuals, except when applicable by law. We do not sell, communicate or divulge your information to any mailing lists.',
    fw9Description: 'We need this information in order to process your taxes at the end of the year, please make sure all information is accurate and up to date.',
    _default: '',
}

const Notifications = ({
    isAgreement = false,
    isPersonalInformation = false,
    isFW9 = false,
    fill = false,
}) => {
    const message = useMemo(() => {
        if (isAgreement) return Messages.agreement
        if (isPersonalInformation) return Messages.information
        if(isFW9) return Messages.fw9Description
        return Messages._default
    }, [isAgreement, isPersonalInformation])
    const { classes } = useStyles()

    const containerStyle = useMemo(() => {
        if (fill) return { width: '100%' }
        return { maxWidth: 420 }
    }, [fill])

    return (
        <div className={classes.container} style={containerStyle}>
            <InfoOutlinedIcon sx={{ mr: 1 }}/>
            <Typography>{message}</Typography>
        </div>
    )
}

export default Notifications
