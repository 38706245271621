import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import MUITableHead from '@mui/material/TableHead'
import TableSortLabel from '@mui/material/TableSortLabel'

import { TableHeader, SortDirectionTypes } from '@/common/types/types'
import Typography from '@/components/common/Typography'

interface Props {
    headers: TableHeader
    orderBy?: string
    sortDirection?: SortDirectionTypes
    setSortBy?: (_sortBy: string) => void
    setSortDirection?: (_sortDirection: SortDirectionTypes) => void
}

const TableHead = ({ headers, orderBy, sortDirection, setSortBy, setSortDirection }: Props) => {

    const handleRequestSort = (property: string) => {
        if (!setSortBy || !setSortDirection || !property) return
        const isAsc = orderBy === property && sortDirection === 'ASC'
        setSortDirection(isAsc ? 'DESC' : 'ASC')
        setSortBy(property)
    }

    return (
        <MUITableHead>
            <TableRow>
                {headers.map(item => (
                    <TableCell
                        align={item.align || 'justify'}
                        key={item.label}
                        width={item.width || 'auto'}
                    >
                        { item.sortable && typeof setSortBy === 'function' ? (
                            <TableSortLabel
                                id={item.label + 'SortButton' || ''}
                                active={item.sortable && orderBy === item.orderBy}
                                direction={sortDirection && sortDirection === 'ASC' ? 'asc' : 'desc'}
                                onClick={() => handleRequestSort(item.orderBy || '')}
                            >
                                <Typography weight='500' color='placeholder'>{item.label}</Typography>
                            </TableSortLabel>
                        ) : (
                            <Typography weight='500' color='placeholder'>{item.label}</Typography>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </MUITableHead>
    )
}

export default TableHead