import { useState, useEffect } from 'react'
import useFetch from './useFetch'
import { SortDirectionTypes, FilterValues } from '@/common/types'

type UseListInitResult<T> = {
    data: T;
    isLoading: boolean;
    page: number;
    query: string;
    error: Error | null;
    orderBy: string;
    sort: SortDirectionTypes;
    filters: Partial<FilterValues>;
    setData: (_data: T) => void;
    setFilters: (_filters: FilterValues) => void;
    setSearch: (_query: string) => void;
    setFilter: (_values: Partial<FilterValues>) => void;
    nextPage: () => void;
    prevPage: () => void;
    fetchNow: () => void;
    setSortBy: (_sortBy: string) => void;
    setSortDirection: (_sortDirection: SortDirectionTypes) => void;
};

type UseListInitParams = {
    endpoint: string;
    defaultSearch?: string;
    defaultPage?: number;
    sortActive?: string;
    sortDirection?: SortDirectionTypes;
    defaultFilters?: Partial<FilterValues>;
    setStoreFilters?: (_filters: Partial<FilterValues>) => void;
}

const useListInit = <T>({
    endpoint,
    sortActive = '',
    sortDirection = 'DESC',
    defaultSearch = '',
    defaultPage = 0,
    defaultFilters = {},
    setStoreFilters,
}: UseListInitParams): UseListInitResult<T> => {
    const [query, setQuery] = useState(defaultSearch)

    const [page, setPage] = useState(defaultPage)
    const [filters, setFilters] = useState(defaultFilters)
    const [orderBy, setSortBy] = useState(sortActive)
    const [sort, setSortDirection] = useState<SortDirectionTypes>(sortDirection)

    const [data, isLoading, error, setData, fetchNow] = useFetch<T>(endpoint, {
        search: query,
        page,
        orderBy,
        sort,
        filters,
    })

    const setSearch = (newQuery: string) => {
        setQuery(newQuery)
        setPage(0)
    }

    const setFilter = (filters: Partial<FilterValues>) => {
        const onlyWithValues = Object.fromEntries(
            Object.entries(filters).filter(([, value]) => {
                const typeofValue = typeof value

                if (['string', 'number', 'boolean'].includes(typeofValue)) return value !== ''
                if (typeofValue === 'object') {
                    if (Array.isArray(value)) return value.length > 0
                    return value && Object.values(value).some(i => i !== '')
                }
            }),
        )
        setFilters(onlyWithValues)
        setPage(0)
    }

    const nextPage = () => setPage((prevPage) => prevPage + 1)

    const prevPage = () => setPage((prevPage) => prevPage - 1)

    useEffect(() => {
        if (setStoreFilters && typeof setStoreFilters === 'function') {
            setStoreFilters({
                search: query,
                page,
                orderBy,
                sort,
                ...filters,
            })
        }
    }, [page, query, orderBy, sort, filters])

    return { data, isLoading, error, page, orderBy, sort, query, filters, setFilters, setData, setSearch, setFilter, nextPage, prevPage, fetchNow, setSortBy, setSortDirection }
}

export default useListInit