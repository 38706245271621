import { createContext, useContext, useState } from 'react'
import { AppContextType, AuthUser } from '@/common/types'

export const AppContext = createContext<AppContextType>({
    user: null,
    setUser: () => null,
})

const ApplicationProvider = ({ children }: { children: React.ReactNode }) => {
    const [user, setUser] = useState<AuthUser | null>(null)

    return (
        <AppContext.Provider value={{ user, setUser }}>{children}</AppContext.Provider>
    )
}

export const useAppContext = () => useContext(AppContext)

export default ApplicationProvider
