import { useState } from 'react'
import clsx from 'clsx'
import Grid from '@mui/material/Grid'
import Paper from '@/components/generics/Paper'
import Typography from '@/components/common/Typography'
import Box from '@mui/material/Box'
import { Button } from '@/components/common/Buttons'
import { signIn, changePassword } from '@/services/amplify'
import { useAuth } from '@/hooks/useAuth'
import { useForm, useKeyLock } from '@/hooks'
import useToast from '@/hooks/useToast'
import EyePassword from '@/components/generics/EyePassword'
import { ChangePasswordValues } from './types'
import { TextField } from '@/components/common/Inputs'
import ErrorAuthMessage from '@/pages/Auth/components/Error'
import { useMediaQueryDown } from '@/hooks/useMediaQuery'
import { useStyles } from './styles'
import { getInitialValues, useScheme } from './validations'

export const ChangePassword = () => {
    const { classes } = useStyles()
    const capsLock = useKeyLock('CapsLock')
    const [inputTypeOld, setInputTypeOld] = useState('password')
    const [inputTypeNew, setInputTypeNew] = useState('password')
    const [inputTypeConfirm, setInputTypeConfirm] = useState('password')
    const [toastSuccess] = useToast()
    const { user } = useAuth()
    const [error, setError] = useState<string>('')
    const [pending, setPending] = useState(false)
    const schema = useScheme()
    const isSmallScreen = useMediaQueryDown({ breakpoint: 'sm' })

    const onSubmit = async (values: ChangePasswordValues) => {
        try {
            setError('')
            setPending(true)
            const { success, user: userValid, type } = await signIn(user?.email.toLowerCase()||'', values.oldPassword)
            if (!success) {
                setError(type || '')
            }
            changePassword(userValid, values.oldPassword, values.newPassword)
            toastSuccess('Password Changed')
        } catch (e) {
            setError('Incorrect password')
        } finally {
            setPending(false)
        }
    }

    const { getFieldProps, handleSubmit } = useForm(
        schema,
        getInitialValues(),
        onSubmit,
    )


    return (
        <div className='page-content'>
            <Paper px={isSmallScreen?2:10} py={1}>
                <Box sx={{ backgroundColor: 'white', marginTop: '15px' }}>
                    <Typography className='text-left' variant='h4' >
                        Change Password
                    </Typography>
                    <form action="#" onSubmit={handleSubmit} className={clsx('d-flex', 'align-rigth')}>
                        <div className={classes.mainContent}>
                            <div className={classes.inputWrapper}>
                                <Grid container spacing={2} rowSpacing={4} marginBottom={3} columnSpacing={4}>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.textField}
                                            label='Current Password'
                                            variant='outlined'
                                            placeholder='Required'
                                            InputProps={{
                                                endAdornment: (
                                                    <EyePassword type={inputTypeOld} onSetIcon={setInputTypeOld} />
                                                ),
                                            }}
                                            type={inputTypeOld}
                                            textHelper={capsLock ? 'Caps lock is on' : ''}
                                            {...getFieldProps('oldPassword')}
                                        />
                                        {error && <ErrorAuthMessage error={error} className={classes.errorText} />}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.textField}
                                            label='New Password'
                                            variant='outlined'
                                            placeholder='Required'
                                            InputProps={{
                                                endAdornment: (
                                                    <EyePassword type={inputTypeNew} onSetIcon={setInputTypeNew} />
                                                ),
                                            }}
                                            type={inputTypeNew}
                                            textHelper={capsLock ? 'Caps lock is on' : ''}
                                            {...getFieldProps('newPassword')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            className={classes.textField}
                                            label='Confirm New Password'
                                            variant='outlined'
                                            placeholder='Required'
                                            InputProps={{
                                                endAdornment: (
                                                    <EyePassword type={inputTypeConfirm} onSetIcon={setInputTypeConfirm} />
                                                ),
                                            }}
                                            type={inputTypeConfirm}
                                            textHelper={capsLock ? 'Caps lock is on' : ''}
                                            {...getFieldProps('confirmNewPassword')}
                                        />
                                    </Grid>
                                </Grid>
                                <div className='flex-center'>
                                    <Button
                                        className={clsx('rounded-button')}
                                        variant='contained'
                                        disabled={pending}
                                        pending={pending}
                                        size='large'
                                        color='secondary'
                                        type='submit'
                                    >
                                        Change Password
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </Box>
            </Paper>
        </div>
    )
}