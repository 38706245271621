import { makeStyles } from 'tss-react/mui'
import MUIAvatar from '@mui/material/Avatar'
import Typography from '@/components/common/Typography'

import { AvatarProps } from './types'
import clsx from 'clsx'
import { Box } from '@mui/material'

export function getAvatarValue(value: string) {
    if (!value) return 'UN'
    const [w1, w2] = String(value).split(' ')
    const res = w1.length && !w2 ? w1.substr(0, 2) : [w1.substr(0, 1), w2.substr(0, 1)].join('')
    return res.toUpperCase()
}

const Avatar = ({ src, text, subText, value, size = 50, bgColor = 'primary', colorText = 'label', colorSubText = 'placeholder', variantText = 'body2', variantSubText = 'body2', wrap=false, weightText }: AvatarProps) => {
    const { classes } = useStyles()

    const classNames = clsx(
        size && classes[size],
        bgColor && !src && classes[`bg-${bgColor}`],
    )

    return (
        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <MUIAvatar
                alt="Remy Sharp"
                src={src}
                imgProps={{
                    sx: {
                        objectFit: 'contain',
                    },
                }}
                className={classNames}
            >
                { value &&
                <Typography weight='500' variant='body1' color='light'>
                    {getAvatarValue(value)}
                </Typography>
                }
            </MUIAvatar>
            <Box>
                { text &&
                <Typography weight={weightText} variant={variantText} color={colorText} noWrap={wrap}>
                    {text}
                </Typography>
                }
                {subText &&
                <Typography wordBreak='word' variant={variantSubText} color={colorSubText}>
                    {subText}
                </Typography>
                }
            </Box>
        </Box>
    )
}

const useStyles = makeStyles()((theme) => ({
    label: { maxWidth: '100%', textAlign: 'center' },

    'bg-transparent': { color: 'inherit', backgroundColor: 'transparent' },
    'bg-primary': { color: 'white', backgroundColor: theme.palette.primary.main },

    40: { width: 40, height: 40 },
    50: { width: 50, height: 50 },
    60: { width: 60, height: 60 },
    card: { width: 100, height: 'auto' },
    fullWidth: { width: '100%', height: 'auto' },
}))

export default Avatar