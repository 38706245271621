import ReactCodeInput from 'react-code-input'
import { useStyles } from './styles'
import { useCallback } from 'react'


interface Props {
    length?: number
    name?: string
    onChange: (_value: string) => void
}

const CodeInput = ({ length = 6, name = 'code-input', onChange }: Props) => {
    const { classes } = useStyles()

    const onChangeHandler = useCallback((value: string) => onChange && onChange(value), [onChange, name])

    return (
        <div className={classes.inputNumberWrapper}>
            <ReactCodeInput
                className={classes.codeInput}
                fields={length}
                inputMode='numeric'
                name={name}
                onChange={onChangeHandler}
            />
        </div>
    )
}

export default CodeInput
