import MUIAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
//import styled from '@emotion/styled'

//const Offset = styled('div')(({ theme }) => theme.mixins.toolbar);

interface Props {
    children: React.ReactNode
}

const AppBar = ({ children }: Props) => {
    return (
        <MUIAppBar
            color='inherit'
            position="sticky"
            sx={{ width: '100%', p: 1 }}
            elevation={0}
        >
            <Toolbar>
                {children}
            </Toolbar>
        </MUIAppBar>
    )
}

export default AppBar