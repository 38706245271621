import { useEffect } from 'react'
import clsx from 'clsx'
import InputAdornment from '@mui/material/InputAdornment'
import Grid from '@mui/material/Grid'
import { Box } from '@mui/material'

import Typography from '@/components/common/Typography'
import { TextField } from '@/components/common/Inputs'
import { Button } from '@/components/common/Buttons'
import { useForm } from '@/hooks'

import useCrud from '@/hooks/useCrud'
import { SignUpValues } from './types'
import { SignUpComponentsProps } from '../../types'
import { useStyles } from './styles'
import avatar from '@/assets/icon/ico-login.svg'
import { getInitialValues, useScheme } from './validations'
import { useAuth } from '@/hooks/useAuth'
import { CourierRegistrationSteps, TaxClassificationTypes } from '@/common/enums'
import { API_SIGN_UP } from '@/constants/routes'
import { useAppContext } from '@/contexts/AppContext'
import { AppContextType } from '@/common/types'
import Notifications from '@/components/generics/Notifications'
import { testProp } from '@/constants/testIds'

const Fw9Step = (props: SignUpComponentsProps) => {
    const { classes } = useStyles()
    const schema = useScheme()
    const { user } = useAuth()
    const application = useAppContext() as AppContextType
    const [apiRequest, apiState] = useCrud('POST')

    const onSubmit = async (values: SignUpValues) => {
        try {
            const { businessName, payeeCode, reportingCode, securityNumber, identificationNumber, accountNumbers, taxClassificationTypeId, taxClassificationNumber } = values
            const response = await apiRequest(props.apiEndpointStep || API_SIGN_UP.SET_FW9_AND_TAX_INFORMATION, {
                fw9Information: {
                    businessName,
                    payeeCode,
                    reportingCode,
                    securityNumber: securityNumber.toString(),
                    identificationNumber: identificationNumber.toString(),
                    accountNumbers,
                },
                taxClassification: {
                    taxClassificationTypeId,
                    taxClassificationNumber,
                },
            })
            if (response) {
                application.setUser(response)
                props.nextStep(response.registrationStep as CourierRegistrationSteps)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const { getFieldProps, setFieldValue, handleSubmit } = useForm(
        schema,
        getInitialValues(),
        onSubmit,
    )

    useEffect(() => {
        if (user) {
            setFieldValue('name', user.name)
            setFieldValue('taxClassificationTypeId', TaxClassificationTypes.INDIVIDUAL)
        }
    }, [])

    return (
        <div className={classes.mainContent}>
            <div className={clsx('flex-center', 'flex-column', 'w-100', 'height-100')}>
                <form action="#" onSubmit={handleSubmit} className={clsx('d-flex', 'flex-column', 'align-center')}>
                    <Box mb={2}>
                        <Typography variant="h4" gutter='label' align='center'>FW9 Form</Typography>
                        <Typography variant='body1'>
                            Please fill in the information below to prefill the PDF document
                        </Typography>
                    </Box>
                    <Notifications isFW9 />
                    <div className={classes.inputWrapper}>
                        <Grid container spacing={2}>
                            <Grid item lg={6} xs={12}>
                                <TextField
                                    variant='standard'
                                    placeholder='Name'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={avatar} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...getFieldProps('name')}
                                />
                            </Grid>
                            <Grid item lg={6} xs={12}>
                                <TextField
                                    {...testProp('input-businessName')}
                                    variant='standard'
                                    placeholder='Business Name (Optional)'
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img src={avatar} />
                                            </InputAdornment>
                                        ),
                                    }}
                                    {...getFieldProps('businessName')}
                                />
                            </Grid>
                        </Grid>

                        {/* <Typography variant='body1'>
                            Check appropriate box for federal tax classification of the person whose name is entered on line 1. Check only one of the following seven boxes
                        </Typography>

                        <RadioGroup
                            value={values.taxClassificationTypeId}
                            onChange={(e: SelectChangeEvent) => setFieldValue('taxClassificationTypeId', e.target.value)}
                        >
                            <Grid container rowSpacing={1}>
                                <Grid item xs={12}>
                                    <FormControlLabel
                                        value={TaxClassificationTypes.INDIVIDUAL}
                                        control={<Radio />}
                                        label="Individual/sole proprietor or single-member LLC"
                                    />
                                </Grid>

                                <Grid item lg={4} xs={12}>
                                    <FormControlLabel
                                        {...testProp(TaxClassificationTypes.C_CORPORATION)}
                                        value={TaxClassificationTypes.C_CORPORATION}
                                        control={<Radio />}
                                        label="C Corporation"
                                    />
                                </Grid>
                                <Grid item lg={4} xs={12}>
                                    <FormControlLabel
                                        value={TaxClassificationTypes.S_CORPORATION}
                                        control={<Radio />}
                                        label="S Corporation"
                                    />
                                </Grid>
                                <Grid item lg={4} xs={12}>
                                    <FormControlLabel
                                        value={TaxClassificationTypes.PARTNERSHIP}
                                        control={<Radio />}
                                        label="Parthership"
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <FormControlLabel
                                        value={TaxClassificationTypes.TRUST}
                                        control={<Radio />}
                                        label="Truest/estate"
                                    />
                                </Grid>

                                <Grid item xs={8}>
                                    <FormControlLabel
                                        {...testProp(TaxClassificationTypes.LIMITED_LIABILITY)}
                                        value={TaxClassificationTypes.LIMITED_LIABILITY}
                                        control={<Radio />}
                                        label="Limited liability company. Enter the tax classification"
                                    />
                                    {errors.taxClassificationTypeId &&
                                        <Typography color="error" variant="caption">
                                            {errors.taxClassificationTypeId}
                                        </Typography>
                                    }
                                </Grid>
                                <Grid item xs={4}>
                                    <SelectField
                                        id={'taxClassification'}
                                        value={values.taxClassificationNumber}
                                        placeholder='*'
                                        error={errors.taxClassificationNumber}
                                        disabled={values.taxClassificationTypeId !== TaxClassificationTypes.LIMITED_LIABILITY}
                                        options={CORPORATIONS}
                                        onChange={(evt: SelectChangeEvent) => setFieldValue('taxClassificationNumber', evt.target.value)}
                                    />
                                </Grid>
                            </Grid>
                        </RadioGroup> */}

                        <Grid container spacing={3} alignItems='center'>
                            <Grid item xs={12}>
                                <TextField
                                    {...testProp('input-ssn')}
                                    variant='standard'
                                    type='number'
                                    placeholder='Social Security number (SSN)'
                                    {...getFieldProps('securityNumber')}
                                />
                            </Grid>
                            <Grid item xs={12} container alignItems='center' justifyContent='center' >
                                <Typography>
                                    Or
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant='standard'
                                    type='number'
                                    placeholder='Employer Identification number (EIN)'
                                    {...getFieldProps('identificationNumber')}
                                />
                            </Grid>
                        </Grid>
                    </div>
                    <div>
                        <Button
                            className={clsx('rounded-button', classes.continueButton)}
                            disabled={apiState.loading}
                            variant="contained"
                            color="secondary"
                            pending={apiState.loading}
                            type="submit"
                        >
                            Continue
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Fw9Step
